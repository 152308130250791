import React, { useCallback, useState } from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { InputProps, useLoading, useUnselectAll } from 'react-admin';
import { useForm } from 'react-final-form';
import { FilePickerDialog } from '.';
import { Button } from '../..';
import { styles } from '../../../theme';
import { SCGroupwareData } from '../../../dataProvider';
import { useResource } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    button: {
      ...styles.iconLightGrayButton,
      marginLeft: 148,
      marginBottom: 4,
      fontSize: 14,
      width: 200,
      '& > span > span': {
        padding: 0,
      },
    },
  }),
);

interface Props {
  resourceOwnerId: string;
}

const FilePickerDialogPopupButton: React.FC<InputProps<Props>> = ({
  resourceOwnerId,
}) => {
  const classes = useStyles();
  const resource = useResource('scGroupwares');
  const form = useForm();
  const loading = useLoading();
  const unselectAll = useUnselectAll(resource);
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleHide = useCallback(() => {
    setOpen(false);
    unselectAll(resource);
  }, [setOpen, unselectAll, resource]);

  const handleAddSCGroupware = useCallback(
    (records: any) => {
      const existingFiles = form.getFieldState('file')?.value || [];
      const blobFile = new Blob([JSON.stringify(records)], {
        type: 'application/json',
      });
      const selectedFiles = records.map((file: SCGroupwareData) => ({
        src: URL.createObjectURL(blobFile),
        title: file.name,
        fileInfo: file,
      }));

      form.batch(() => {
        form.change('file', [...existingFiles, ...selectedFiles]);
      });
    },
    [form],
  );

  return (
    <>
      <Button
        className={classes.button}
        label="admin.dialog.filePicker.label"
        onClick={handleClick}
        disabled={loading}
      ></Button>
      <FilePickerDialog
        open={open}
        onClose={handleHide}
        resourceOwnerId={resourceOwnerId}
        handleAddSCGroupware={handleAddSCGroupware}
        loading={loading}
      />
    </>
  );
};

FilePickerDialogPopupButton.displayName = 'FilePickerDialogPopupButton';
export default FilePickerDialogPopupButton;
