/**
 * 文字列を Byte に変換する
 */
export default (fileSize: string) => {
  const bytes = Number(fileSize); // 文字列を数値型に変換
  if (bytes > 1000000000) {
    const gigaBytes = bytes / 1000000000;
    return `${gigaBytes.toFixed(2).replace(/\.?0+$/, '')} GB`;
  }
  if (bytes > 1000000) {
    const megaBytes = bytes / 1000000;
    return `${megaBytes.toFixed(2).replace(/\.?0+$/, '')} MB`;
  }
  if (bytes > 1000) {
    const kiloBytes = bytes / 1000;
    return `${kiloBytes.toFixed(2).replace(/\.?0+$/, '')} kB`;
  }
  return `${bytes} B`;
};
