// for es
// スペイン語
import raMessage from 'ra-language-spanish';
import en from './en';

const { ra } = raMessage;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'No encontrado',
      page_rows_per_page: 'Indica el número de piezas:',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
      search: 'buscar',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: '¿Desea eliminar %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Esperando aprobación',
    usermenu: {
      userNotice: 'Notificación',
      terms: 'CONDICIONES DE USO',
      manual: 'Manual',
      downloads: 'Descargar',
    },
    breadcrumbs: {
      details: 'Detalles',
      retrofitEdit: 'Detalles',
      taskCreate: 'Crear tarea',
      taskEdit: 'Detalles de la tarea',
      geofenceCreate: 'Crear',
      geofenceEdit: 'Editar',
      geofenceAlertList: 'Alerta de geofence',
      geofenceAlertShow: 'Detalles',
      compactionWorkAreaCreate: 'Agregar áreas de trabajo de compactación',
      compactionWorkAreaShow: 'Áreas de trabajo de compactación',
      compactionWorkAreaEdit: 'Editar áreas de trabajo de compactación',
      compactionLayerCreate: 'Crear capas de compactación',
      compactionLayerEdit: 'Editar capas de compactación',
      supportMode: 'Modo de soporte',
      retrofitManage: 'Registro de controlador',
      retrofitManageAdmin: 'Registro de controlador (Admin)',
      retrofitNamePlateFiles: 'Registro de controlador',
      retrofitAlternateRegistCreate: 'Registro de proxy del controlador',
      retrofitAlternateRegistEdit: 'Detalles',
      retrofitManageAdminBulkActionCreate: 'Registro',
      terms: 'CONDICIONES DE USO',
      downloads: 'Descargar',
    },
    pages: {
      siteList: 'Lista de sitios',
      siteRetrofitCreate: 'Agregar una máquina al lugar de trabajo',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Agregar un cubo al lugar de trabajo',
      siteBucketEdit: 'Detalles del cubo',
      siteProjectEdit: 'Detalles del proyecto',
      siteProjectCreate: 'Registro de archivos de proyecto',
      siteExtensionarmCreate:
        'Agregar un brazo de extensión al lugar de trabajo',
      taskCreate: 'Crear tarea',
      taskEdit: 'Detalles de la tarea',
      geofenceAlertList: 'Alerta de geofence',
      geofenceAlertShow: 'Detalles de la alerta de geofence',
      geofenceCreate: 'Crear geovalla',
      geofenceEdit: 'Editar valla geográfica',
      compactionWorkAreaCreate: 'Agregar áreas de trabajo de compactación',
      compactionLayerCreate: 'Crear capas de compactación',
      compactionLayerEdit: 'Editar capas de compactación',
      siteFileEdit: 'Detalles',
      siteFileCreate: 'Agregar un archivo al lugar de trabajo',
      retrofitList: 'Lista de máquinas',
      retrofitEdit: '',
      retrofitRentalCreate: 'Registro de asignación',
      retrofitTransferCreate: 'Registro de transferencia',
      retrofitSendBack: 'Registro de devolución',
      bucketList: 'Lista de cubos',
      bucketEdit: 'Detalles del cubo',
      bucketCreate: 'Registro del cubo',
      bucketRentalCreate: 'Compartir cubo',
      extensionarmList: 'Lista de brazos de extensión',
      extensionarmEdit: 'Detalles del brazo de extensión',
      extensionarmShare: 'Compartir brazo de extensión',
      retrofitBulkActionList: 'Lista de registro masivo',
      retrofitBulkActionCreate: 'Registro masivo',
      retrofitBasicInfoList: 'Lista de controladores',
      retrofitBasicInfoCreate: 'Registro de controlador',
      retrofitBasicInfoEdit: 'Actualización del controlador',
      gnssSettingList: 'Lista de configuraciones Ntrip',
      gnssSettingCreate: 'Crear ajuste Ntrip',
      gnssSettingAdminCreate: 'Crear ajuste Ntrip',
      gnssSettingOwnerCreate: 'Crear ajuste Ntrip',
      gnssSettingEdit: 'Editar configuración Ntrip',
      gnssSettingAdminEdit: 'Editar configuración Ntrip',
      gnssSettingOwnerEdit: 'Editar configuración Ntrip',
      approvalList: 'Lista de máquinas',
      retrofitRentalEdit: 'Detalles de la asignación',
      retrofitTransferEdit: 'Detalles de transferencia',
      asbuiltsCreate: 'Adquirir el resultado de la construcción',
      asbuiltsShow: 'Historial de Asbuild',
      asbuiltsRetrofitCreate: 'Adquirir el resultado de la construcción',
      retrofitConfigList: 'Información de configuración',
      retrofitKitInfoList: 'Información controlador',
      retrofitAccuraciesList: 'Resultado de la verificación de precisión',
      retrofitAccuraciesShow: 'Detalles',
      retrofitCalibaList: 'Información calibración',
      retrofitCalibInfo:
        'Detalles de la información de calibración de la máquina',
      retrofitBasicSettingList: 'Información básica',
      retrofitErrorList: 'Información del error',
      retrofitErrorShow: 'Detalles',
      retrofitTopographicSurveyList: 'Lista inspec topográf',
      retrofitTopographicSurveyShow: 'Detalles de levantamiento topográfico',
      retrofitShareFileList: 'Registro de aplicaciones',
      retrofitCorporationLicenseList: 'Lista de licencias',
      retrofitCorporationLicenseEdit: 'Detalles de la licencia',
      CompactionLayerList: 'Capas de compactación',
      userNoticeList: 'Lista de notificaciones',
      userNoticeCreate: 'Agregar una notificación',
      userNoticeEdit: 'Actualizar la notificación',
      userNoticeDrawer: 'Notificación',
      retrofitAlternateRegists: 'Lista de registros de proxy del controlador',
      firmwareList: 'Lista de firmware',
      firmwareShow: 'Detalles del firmware',
      firmwareCreate: 'Agregar un firmware',
      retrofitManage: 'Texto',
      retrofitNamePlateFiles: 'Foto',
      retrofitManageAdminBulkAction: 'Registro masivo',
      retrofitManageAdminBulkActionCreate: 'Registro masivo',
      retrofitAlternateRegistCreate: 'Registro de proxy del controlador',
      retrofitAlternateRegistEdit:
        'Detalles de registro de proxy del controlador',
      retrofitAlternateRegistCreateText: 'Texto',
      retrofitAlternateRegistCreatePhoto: 'Foto',
      ntripList: 'Info Ntrip',
      ntripEdit: 'Gestión de Ntrip',
    },
    label: {
      login: 'Iniciar sesión',
      selected: 'Seleccionado (%{length} casos)',
      listNoResults: 'Datos no encontrados',
      maintenance: {
        title: 'Actualmente en mantenimiento.',
        message: `Hemos suspendido temporalmente los servicios debido a labores de mantenimiento.
      Pedimos disculpas por las molestias, por favor, espere un momento más.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Suelte o seleccione el(los) archivo(s) (%{fileTypes}) para cargarlo(s).',
        maxFileNum:
          'El número máximo de controladores que se pueden registrar a la vez es de cinco.',
      },
      nosetting: 'Ninguna configuración',
      siteRetrofitList: {
        remoteSupport: 'Soporte remoto',
      },
      retrofitEdit: {
        breadcrumbs: 'Detalles',
        belongsDetails: '',
        ntripSettingInfo: 'Info Configuración Ntrip',
        machineryDetails: 'Máquina',
        retrofitDetails: 'Controlador',
        basicInfo: 'Información básica',
        controller: 'Controlador',
        reciverMain: 'Receptor ppal GNSS',
        reciverSub: 'Receptor secundario GNS',
        retrofitstatus: 'Estado del controlador',
        license: 'Licencia',
        licenseId: 'ID de licencia',
        expiredDate: 'Fecha de vencimiento',
      },
      retrofitRental: {
        rentalDetails: 'Detalles de la asignación',
        rentalInfo: 'Detalles de la asignación',
        lending: 'Asignación',
        return: 'Regreso',
        lendingDestination: 'Empresa asignada',
        lendingCompany: 'Nombre de la empresa',
        lendingEmail: 'Correo electrónico',
        companyName: 'Nombre de la empresa',
      },
      retorfitTransfer: {
        tab: 'Transferencia',
        transferInfo: 'Detalles de transferencia',
        transferButton: 'Transferencia',
        transferEditButton: 'Editar transferencia',
      },
      siteConfigureEdit: {
        onButton: 'ENCENDIDO',
        offButton: 'APAGADO',
      },
      approvals: {
        rentalTab: 'Asignación',
        transferTab: 'Transferencia',
      },
      buckets: {
        bucketTab: 'Cazo',
        extensionarmTab: 'Brazo de extensión',
        extensionarmFile: 'Archivo del brazo de extensión',
        shareTo: 'Compartir con',
      },
      bucketRental: {
        corporation: 'Compartir con',
        bucketType: 'Tipo',
        bk3File: 'Archivo BK3',
      },
      retrofitBasic: {
        basicInfoTab: 'Información básica',
        bulkActionTab: 'Registro masivo',
        ntripTab: 'Gestión de Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Enviar de vuelta',
        initialize: 'Inicialización',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Actualización masiva',
        retrofitDistributor: 'Distribuidor',
        retrofitRegion: 'Región',
        corporationId: 'ID de la empresa',
        corporationName: 'Nombre de la empresa',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID de empresa en uso',
        corporationName: 'Empresa en uso',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Nombre de la empresa',
      },
      terms: {
        title: 'CONDICIONES DE USO',
        message:
          "Veuillez cliquer sur le lien ci-dessous pour consulter les conditions d'utilisation.",
        confirmMessage1:
          'Para usar Smart Construction Pilot, debe aceptar el siguiente documento o documentos.',
        confirmMessage2:
          'Si no está de acuerdo, no podrá usar Smart Construction Pilot.',
        select: 'Veuillez sélectionner votre région.',
      },
      downloads: {
        title: 'Convertidor de archivos de proyecto',
        message:
          'Esta es una aplicación independiente que crea un archivo de proyecto a partir de archivos de diseño.',
        button: 'Descargar',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Numeración automática',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Descargar',
        resultFileDownload: 'Descargar',
      },
      tasks: {
        coordinate: 'Coordenadas (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Tamaño de bloque (%{unit})',
      },
      compactionLayers: {
        thickness: 'Espesor (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Detalles',
        rentalDetail: 'Detalles de la asignación',
      },
      pendingTransfers: {
        breadcrumbs: 'Detalles',
        transferDetail: 'Detalles de transferencia',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Detalles',
        alternateRegistDetail: 'Detalles de registro de proxy del controlador',
      },
      supportMode: {
        retrofit: 'Máquina',
        optional: 'Opcional',
      },
      retrofitList: {
        assignment: 'Asignación',
        license: 'Licencia',
      },
      userNotices: {
        languageDetails: 'Contenido de la notificación',
        language: 'Idioma',
        languageEn: 'English',
        languageJa: 'Japonés',
        languageFr: 'Francés',
        languageDe: 'Alemán',
        languageEs: 'Español',
        Information: 'Notificación',
        Maintenance: 'Mantenimiento',
        emergency: 'Emergencia',
        important: 'Importante',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Registro de controlador',
        corporationName: 'Nombre de la empresa',
        basicInfoSerialNumber: 'Número de serie',
        confirmInput: 'Volver a introducir el número de serie',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Número de serie',
        status: 'Estado',
        imageFile: 'Imagen',
        message1:
          'Veuillez prendre une photo claire et non floue du numéro de série du contrôleur ou du code QR.',
        message2: 'Las imágenes deben ser menores de %{mbyte} MB.',
        errormessage:
          'El número máximo de controladores que se pueden registrar a la vez es de cinco.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Válido',
          disable: 'No válido',
          unLicense: 'No asignado',
        },
        machine: 'Máquina',
        before: 'Antes',
        after: 'Después de',
      },
      geofences: {
        project: 'Proyecto',
        projectSourceLayer: 'Capa',
        rgb: 'Color(RGB)',
        select: 'Geofence de selección',
        coordinates: 'Coordenadas',
        coordinatesLabel: 'Coordenadas (N,E)',
        unitLength: 'Unidad:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Coordenadas (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Radio',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Registro de proxy del controlador',
        corporationName: 'Nombre de la empresa',
        email: 'Correo electrónico',
        basicInfoSerialNumber: 'Número de serie',
        confirmInput: 'Volver a introducir el número de serie',
      },
      ntripList: {
        unusedTotal: 'Total no utilizado',
      },
      calibrationDetail: {
        breadcrumbs: 'Detalles',
        machineInfo: 'Info máquina',
        machineGeometryInfo: 'Info geometría máquina',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Información montaje IMU',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Info montaje GNSS ppal.',
        bodyCenterToGnssSub: 'Info montaje GNSS secund',
        extendedModel: 'Modelo ampliado',
        measuredValues: 'Información calibración',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Error de conversión de datos',
        system_localization_quantity_error: `No se pueden registrar varios archivos que contengan información de localización.
      Por favor, registre sólo un archivo que contenga información de localización.`,
        system_detect_0byte_file_error: `El registro del archivo en el servidor ha fallado debido a un error de comunicación.
      Vuelva a registrar el archivo.`,
        system_batch_error: 'Error del sistema (P001)',
        system_parameter_error: 'Error del sistema (P002)',
        system_parameterfile_notfound: 'Error del sistema (P003)',
        system_exception: 'Error del sistema (P004)',
        system_logfile_error: 'Error del sistema (P005)',
        system_parameterfile_parameter_error: 'Error del sistema (P006)',
        desing_localization_error: `No hay información de localización disponible.
      Por favor, registre uno de los siguientes archivos:

      ・Archivo GC3
      ・Archivo TP3 que contenga puntos de control
      ・Archivo CAL`,
        desing_no_support_dxf_error:
          'El(los) archivo(s) DXF no pudo/pudieron ser leído(s) porque su versión es anterior a AutoCAD2000.',
        desing_tile_error: 'No se pudo crear el archivo de proyecto.',
        desing_outline_error:
          'Falló la creación de la línea de límite exterior.',
        desing_area_length_error:
          'El proceso se ha interrumpido porque el área del proyecto es demasiado grande. Por favor, compruebe si los datos de diseño y la escala de los datos CAD son correctos.',
        desing_layer_name_duplication_error: `No se puede registrar datos de proyecto con nombres de capa duplicados.
      Cambie el nombre de la capa de diseño o regístrelo para que solo haya un archivo con el mismo nombre de capa de diseño.`,
        targetFiles: '[Nombre de archivo]',
        targetLayers: '[Capa]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Ninguno',
        system_batch_error: 'Error del sistema (T001)',
        system_parameter_error: 'Error del sistema (T002)',
        system_parameterfile_notfound: 'Error del sistema (T003)',
        system_exception: 'Error del sistema (T004)',
        system_logfile_error: 'Error del sistema (T005)',
        system_basefile_notfound: 'Error del sistema (T006)',
        system_prjfile_multi: 'Error del sistema (T007)',
        system_basefile_read_error: 'Error del sistema (T008)',
        system_target_layer_notfound: 'Error del sistema (T009)',
        system_target_layer_duplication: 'Ya existe un nombre de capa igual.',
        system_taskarea_error: 'Área de tarea no válida',
        system_taskarea_design_notfound:
          'No hay datos para el archivo de proyecto dentro del área de la tarea (TIN, linework, puntos).',
        system_tile_error: 'No se pudo crear el archivo de proyecto.',
        system_outline_error:
          'Falló la creación de la línea de límite exterior.',
        system_parameterfile_error: 'Error del sistema',
      },
      retrofitError: {
        1000: 'No se han detectado los datos UDP del controlador',
        2000: 'IMU del chasis no detectada',
        2001: 'Error de hardware de IMU del chasis',
        2002: 'Error de algoritmo de IMU del chasis',
        2003: 'Error de datos de IMU del chasis',
        2004: 'Error de inicialización de IMU del chasis',
        2100: 'IMU de la pluma no detectada',
        2101: 'Error de hardware de IMU de la pluma',
        2102: 'Error de algoritmo de IMU de la pluma',
        2103: 'Error de datos de IMU de la pluma',
        2104: 'Error de inicialización de IMU de la pluma',
        2200: 'IMU del brazo no detectada',
        2201: 'Error de hardware de IMU del brazo',
        2202: 'Error de algoritmo de IMU del brazo',
        2203: 'Error de datos de IMU del brazo',
        2204: 'Error de inicialización de IMU del brazo',
        2300: 'IMU del cazo no detectada',
        2301: 'Error de hardware de IMU del cazo',
        2302: 'Error de algoritmo de IMU del cazo',
        2303: 'Error de datos de IMU del cazo',
        2304: 'Error de inicialización de IMU del cazo',
        2400: 'IMU del cazo de inclinación no detectada',
        2401: 'Error de hardware de IMU del cazo de inclinación',
        2402: 'Error de algoritmo de IMU del cazo de inclinación',
        2403: 'Error de datos de IMU del cazo de inclinación',
        2404: 'Error de inicialización de IMU del cazo de inclinación',
        2500: 'IMU de 2.ª pluma no detectada',
        2501: 'Error de hardware de la IMU de 2.ª pluma',
        2502: 'Error de algoritmo de la IMU de 2.ª pluma',
        2503: 'Error de datos de la IMU de 2.ª pluma',
        2504: 'Error de inicialización de la IMU de 2.ª pluma',
        2600: 'Sensor de ángulo de giro no detectado',
        2601: 'Error del sistema del sensor de ángulo de giro',
        2602: 'Error de hardware del sensor de ángulo de giro',
        2700: 'Sensor de desviación no detectado',
        2701: 'Error del sistema del sensor de desviación',
        2702: 'Error de hardware del sensor de desviación',
        3000: 'Sensor de presión del lado delantero no detectado',
        3100: 'Sensor de presión del lado inferior no detectado',
        4000: 'Antena GNSS no detectada',
        5000: 'Información de corrección no detectada',
        5001: 'Señal de receptor de radio no detectada',
      },
    },
    dialog: {
      menu: {
        confirmManualLink: 'Será redirigido a otro sitio web. ¿Es correcto?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Cazo',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message:
            'Se requieren el ID de la empresa y el nombre de la empresa.',
        },
        save: {
          message1:
            'No se puede cambiar el ID/nombre de la empresa porque incluye',
          message2: 'máquina(s) asignada(s) a otra empresa.',
          message3: '',
          message4: 'Veuillez vérifier le(s) numéro(s) de série ci-dessous.',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Controlador',
        },
        undeletable: {
          message: 'El controlador está en uso y no se puede eliminar.',
        },
      },
      Ntrip: {
        save: {
          stop: '¿Desea detener esta configuración Ntrip?',
          delete: '¿Desea eliminar la configuración Ntrip?',
        },
      },
      siteRetrofitList: {
        title: 'Información adicional',
        message: 'El período de construcción ha finalizado.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Configuración común',
          siteSetting: 'Configuración del sitio',
        },
        mapBackgroundColor: 'Color de fondo del mapa',
        message:
          'La función de detección de contacto/notificación de alertas de geovallado podría no funcionar correctamente, dependiendo del entorno y las condiciones en las que se use. No confíe excesivamente en el uso de esta función y asegúrese de que entiende dicha función y las condiciones de uso.',
      },
      geofenceList: {
        confirmDelete: {
          message: '¿Desea eliminar esta valla geográfica?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'La máquina está vinculada a un lugar de trabajo y no se puede asignar. Desconecte la máquina del lugar de trabajo.',
          message2: 'Veuillez déconnecter la machine du chantier.',
        },
        sameOwner: {
          message:
            'La máquina no se puede asignar porque su empresa es la propietaria.',
        },
        unableTransfer: {
          message:
            'La máquina está pendiente de aprobación de transferencia y no se puede registrar la asignación.',
        },
        unableAlternateRegist: {
          message:
            'La máquina está pendiente de aprobación de registro de proxy de controlador y no se puede registrar para asignación.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'La máquina está vinculada a un lugar de trabajo y no se puede transferir. Desconecte la máquina del lugar de trabajo.',
        messageRentaled: 'La máquina está asignada y no se puede transferir.',
        messageLicense:
          'La máquina tiene una licencia y no se puede transferir.',
        pendingApprovals: {
          message1:
            'Se ha recibido la solicitud de transferencia. Por favor, espere la aprobación.',
          message2:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          '¿Desea devolver el controlador y heredar la configuración en el controlador de destino?',
        deleteNtrip: '¿Desea inicializar el controlador devuelto?',
        unableRegion:
          'Este controlador ha sido enviado al extranjero. Si este controlador es el correcto, restablezca la "Región" a nulo.',
        unableCorporation: {
          message1: 'El controlador no se puede enviar de vuelta.',
          message2:
            'Si cree que esto es un error, comuníquese con su distribuidor.',
        },
        retrofitRentaled:
          'El controlador está asignado y no se puede enviar de vuelta.',
        tiedToSite:
          'La máquina está vinculada a un lugar de trabajo y no se puede devolver.',
      },
      retrofitAddToSiteAction: {
        title: 'Información adicional',
        message:
          'Hay una máquina en espera de aprobación, por lo que no se puede vincular al sitio de trabajo.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Información adicional',
        message:
          'La máquina se está transfiriendo y no se puede vincular al lugar de trabajo.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message:
            'La Configuración Ntrip seleccionada se está enviando a otra máquina.',
        },
        unableTransferInfo: {
          message: 'Para transferir, cancele las siguientes configuraciones.',
          reasonSiteId: 'Sitio',
          reasonRentaled: 'Asignación',
          reasonLicense: 'Licencia',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'La empresa asignada está asignando actualmente la máquina a otra empresa. La fecha de inicio de la asignación a otra empresa es el %{date}.',
          message2:
            'Veuillez sélectionner une date de fin postérieure à %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'La máquina está vinculada a un lugar de trabajo de la empresa asignada. ¿Desea finalizar esta asignación?',
        },
        confirmDoubleRental: {
          message:
            '"La empresa asignada está asignando actualmente la máquina a otra empresa. La empresa asignada está asignando actualmente la máquina a otra empresa. Establecer una fecha de finalización también termina la asignación a otra empresa. ¿Desea establecer la fecha de finalización?"',
        },
        unapprovedSave: {
          message: 'Esta asignación no ha sido aprobada y no se puede guardar.',
        },
        unapprovedDelete: {
          title: '',
          message: '¿Desea eliminar esta tarea?',
        },
        approvedDelete: {
          title: '',
          message: 'Esta tarea ha sido aprobada y no puede eliminarse.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message:
            'La máquina está actualmente en asignación y no puede ser transferida.',
        },
        approvedSave: {
          message: 'La máquina tiene una licencia y no se puede transferir.',
        },
        unapprovedDelete: {
          title: '',
          message: '¿Desea eliminar esta transferencia?',
        },
        approvedDelete: {
          title: '',
          message:
            'Esta transferencia ha sido aprobada y no puede ser eliminada.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Brazo de extensión',
        },
      },
      projectFileUpload: {
        message:
          'No se pueden registrar varios archivos de puntos de control (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Una vez actualizado el proyecto, se eliminarán las tareas existentes. ¿Desea actualizar?',
        },
      },
      projectConversionStatus: {
        title: 'Estado de conversión',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Estado de conversión',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message:
            'La tarea seleccionada no puede borrarse porque está en uso.',
        },
        unableToCreate: {
          message: 'No se han registrado datos de diseño en el proyecto.',
        },
        unableToDelete: {
          message1:
            'La tarea no puede borrarse porque el estado del proyecto y el estado de la tarea son los siguientes.',
          message2: 'Proyecto: Convirtiendo',
          message3: 'Tarea: Error',
        },
        unableToUpdate: {
          message1:
            'La tarea no se puede editar porque el estado del proyecto y el estado de la tarea no son los siguientes.',
          message2: 'Proyecto: [Convertido]',
          message3: 'Tarea: [Transferencia][Transferida][Error al transferir]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Tarea',
        },
        unableToDelete: {
          message:
            'La tarea seleccionada no puede borrarse porque está en uso.',
        },
      },
      taskMap: {
        crossingMessage: 'El área de la tarea está cruzada.',
        sameValueMessage: 'Se han introducido las mismas coordenadas.',
        areaZeroMessage: 'No hay área para el área de tareas.',
        noPolygonMessage:
          'La tarea no se puede registrar porque aún no está registrada.',
      },
      compactionMaterials: {
        add: {
          title: 'Agregar un material',
        },
        update: {
          title: 'Actualizar un material',
        },
      },
      weathers: {
        add: {
          title: 'Agregar un clima',
        },
        update: {
          title: 'Actualizar el clima',
        },
      },
      operators: {
        add: {
          title: 'Agregar un operador',
        },
        update: {
          title: 'Actualizar un operador',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Editar áreas de trabajo de compactación',
        },
        undeletable: {
          message:
            'La capa de compactación incluida en el área de construcción está en uso y no se puede eliminar.',
        },
      },
      compactionLayers: {
        undeletable: {
          message:
            'La capa de compactación está en uso y no se puede eliminar.',
        },
        deleteConfirm: {
          messageTargetItem: 'Capa de compactación',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message:
            'Se está enviando otra configuración Ntrip a la máquina seleccionada.',
        },
        confirmDelete: {
          message: '¿Desea eliminar la configuración Ntrip?',
        },
        confirmDeleteWithMachine: {
          message:
            'La máquina está vinculada actualmente. ¿Desea eliminar la configuración de Ntrip?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Notificación',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Configure la(s) licencia(s) en la siguiente máquina.',
        },
        changeDisable: {
          message:
            "Veuillez modifier l'ID de licence des engins de chantier suivants pour qu'il soit valide.",
        },
        changeTemporary: {
          message:
            'Configure la(s) licencia(s) en la siguiente máquina. La licencia temporal actualmente configurada se invalidará.',
        },
        errorAdd: {
          message:
            'No se puede cambiar la licencia de la máquina porque ya se ha establecido la licencia Todo.',
        },
        errorSameType: {
          message:
            'La licencia de la máquina no se puede cambiar porque ya se ha establecido el mismo tipo de licencia.',
        },
        errorUnlimited: {
          message:
            'La máquina ya tiene configurada una licencia ilimitada, no se pueden configurar otras licencias.',
        },
        addAll: {
          message:
            'La siguiente máquina ya tiene licencia(s). ¿Desea configurar Todas las licencias?',
        },
        compare: {
          message: 'Cambie la(s) licencia(s) para la siguiente máquina.',
        },
        warning: {
          message:
            'El %{retrofit} tiene la licencia del %{id}. ¿Desea desconectar la licencia?',
        },
        unableTransfer: {
          message:
            'La máquina se está transfiriendo y no se puede vincular la licencia.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Hay datos que no se pudieron analizar.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Se ha recibido la solicitud de registro. Por favor, espere hasta que sea aprobada.',
          message2:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        corporationIdCheck: {
          message1:
            'El controlador ya ha sido registrado. Por favor, verifique buscando en las máquinas.',
          message2:
            'Si no puede encontrar el controlador objetivo en la lista, contáctenos desde la siguiente URL.',
        },
        earthbrainIdCheck: {
          message1: 'Pedimos disculpas por las molestias',
          message2:
            'pero por favor contáctenos desde la URL a continuación con la siguiente información.',
          message3: '-Número de serie del controlador',
          message4: '-Nombre de la máquina a registrar (opcional)',
          message5: '-ID de empresa a registrar',
        },
        invalidSerialNumber: {
          message1: 'El controlador ya está en uso por otra empresa.',
          message2: 'Si desea cambiarlo por otros motivos,',
          message3:
            "Veuillez nous contacter à l'adresse URL ci-dessous avec les informations suivantes.",
          message4: '-Número de serie del controlador',
          message5: '-Nombre de la máquina a registrar (opcional)',
          message6: '-ID de empresa a registrar',
          message7: '-Motivo del cambio',
        },
        lendingRetrofits: {
          message1: 'El controlador está asignado.',
          message2:
            "Veuillez vous réinscrire après la fin de la mission dans l'entreprise d'affectation.",
          message3:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        siteAttached: {
          message1: 'El controlador está vinculado a un lugar de trabajo.',
          message2:
            'Veuillez vous réinscrire après avoir déconnecté la machine du chantier.',
          message3:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        serialNumberExistError: {
          message1:
            'Este es el número de serie de un controlador que no existe. Asegúrese de que el número de serie del controlador que ha introducido es correcto.',
          message2:
            'Si el problema persiste, contáctenos desde la siguiente URL.',
        },
        ntripShortage: {
          message1: 'No pudimos emitir su ID de usuario y contraseña Ntrip.',
          message2: "Veuillez nous contacter à l'adresse URL ci-dessous.",
        },
        serialnumberCheck: {
          message:
            'Veuillez vérifier les produits portant le(s) numéro(s) de série suivant(s)',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'Se requiere correo electrónico',
        },
        pendingApprovals: {
          message1:
            'Se ha recibido la solicitud de registro. Espere hasta que sea aprobada.',
          message2:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        corporationIdCheck: {
          message1:
            'El controlador ya ha sido registrado. Por favor, verifique buscando en las máquinas.',
          message2:
            'Si no puede encontrar el controlador objetivo en la lista, contáctenos desde la siguiente URL.',
        },
        earthbrainIdCheck: {
          message1: 'Pedimos disculpas por las molestias',
          message2:
            'pero por favor contáctenos desde la URL a continuación con la siguiente información.',
          message3: '-Número de serie del controlador',
          message4: '-Nombre de la máquina a registrar (opcional)',
          message5: '-ID de empresa a registrar',
        },
        invalidSerialNumber: {
          message1: 'El controlador ya está en uso por otra empresa.',
          message2: 'Si desea cambiarlo por otros motivos,',
          message3:
            "Veuillez nous contacter à l'adresse URL ci-dessous avec les informations suivantes.",
          message4: '-Número de serie del controlador',
          message5: '-Nombre de la máquina a registrar (opcional)',
          message6: '-ID de empresa a registrar',
          message7: '-Motivo del cambio',
        },
        lendingRetrofits: {
          message1: 'El controlador está asignado.',
          message2:
            "Veuillez vous réinscrire après la fin de la mission dans l'entreprise d'affectation.",
          message3:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        siteAttached: {
          message1: 'El controlador está vinculado a un lugar de trabajo.',
          message2:
            'Veuillez vous réinscrire après avoir déconnecté la machine du chantier.',
          message3:
            'Si tiene alguna pregunta, comuníquese con nosotros desde la siguiente URL.',
        },
        serialNumberExistError: {
          message1:
            'Este es el número de serie de un controlador que no existe. Asegúrese de que el número de serie del controlador que ha introducido es correcto.',
          message2:
            'Si el problema persiste, contáctenos desde la siguiente URL.',
        },
        ntripShortage: {
          message1: 'No pudimos emitir su ID de usuario y contraseña Ntrip.',
          message2: "Veuillez nous contacter à l'adresse URL ci-dessous.",
        },
        serialnumberCheck: {
          message:
            'Veuillez vérifier les produits portant le(s) numéro(s) de série suivant(s)',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Este controlador ya es propiedad de su empresa.',
          message2: "Veuillez contacter l'entreprise demanderesse.",
        },
        userCorporation: {
          message1:
            'No puede aprobar esta solicitud porque ya está prestando este controlador.',
          message2: "Veuillez contacter l'entreprise demanderesse.",
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Hay una máquina en uso in situ sin licencia.
        Se requiere una licencia para su uso in situ.
        Si usted no ha comprado una licencia, por favor compre una en el Smart Construction Marketplace.`,
        message2: `
        Si está alquilando de otra empresa, por favor solicite una licencia para la máquina de la fuente de alquiler.

        Si tiene alguna pregunta, por favor consulte el siguiente sitio de soporte.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
      filePicker: {
        title: 'Import From Groupware',
        breadcrumbs: 'Home',
        selectedFile: 'Selected Files',
        label: 'Import From Groupware',
      },
    },
    tootip: {
      retrofitShareFileList: 'Registro de aplicaciones',
      retrofitConfigList: 'Información de configuración',
      retrofitKitInfoList: 'Información controlador',
      retrofitAccuraciesList: 'Resultado de la verificación de precisión',
      retrofitCalibaList: 'Información calibración',
      retrofitBasicSettingList: 'Ajustes básicos',
      retrofitErrorList: 'Detalles del error',
      retrofitTopographicSurveyList: 'Inspección topográfica',
      retrofitAsbuilts: 'Resultados de Obra',
    },
    actions: {
      ok: 'OK',
      add: 'Agregar',
      create: 'Registro',
      yes: 'Sí',
      no: 'No',
      confirmation: 'Confirmar',
      save: 'GUARDAR',
      cancel: 'Cancelar',
      required: 'Requerido',
      addMachineToSite: 'Agregar una máquina al lugar de trabajo',
      addBucketToSite: 'Agregar',
      deleteFromSite: 'Desconectar del lugar de trabajo',
      removeLicense: 'Desconectar de la máquina',
      lendMachine: 'Asignación',
      transferMachine: 'Transferencia',
      remoteSupport: 'Soporte remoto',
      lendBucket: 'Compartir',
      approval: 'Aprobación',
      connect: 'Conectar',
      creationInstructions: 'Adquirir el resultado de la construcción',
      csvDownload: 'Descargar',
      retrofitBundleCreate: 'Registro masivo',
      termsAccept: 'Aceptar',
      termsDeny: 'En desacuerdo',
      bulkRegistration: 'Actualización masiva',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Artículos de compactación',
      addLanguage: 'Agregar idioma',
    },
    message: {
      error: 'Se ha producido un error',
      confirm: '¿Está bien?',
      fileUpload: 'Arrastre y suelte archivos aquí',
      success: 'La inscripción ha sido completada',
      approved: 'La aprobación se ha completado',
      deleteConfirmTitle: '',
      deleteConfirmContent: '¿Desea eliminar %{title}?',
      bulkDeleteConfirmContent: '¿Desea eliminar %{title}?',
      disconnectFromSite: '¿Desea desconectarse del lugar de trabajo?',
      saveConfirmContent: 'Confirmar',
      removeLicense: '¿Desea desconectarse de la máquina?',
      task: {
        crossingMessage: 'El área de la tarea está cruzada.',
        sameValueMessage: 'Se han introducido las mismas coordenadas.',
        areaZeroMessage: 'No hay área para el área de tareas.',
        invalidPolygonMessage: 'Se han introducido las mismas coordenadas.',
      },
      geofence: {
        crossingMessage: 'El área de geovalla está intersectada.',
        sameValueMessage: 'Se han introducido las mismas coordenadas.',
        areaZeroMessage: 'No hay área para el área de geovalla.',
      },
      licenseError: 'No tiene licencia para Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'El número de serie no coincide.',
        serialNumberInputError: 'El número de serie no existe.',
        invalidAccess:
          'No está autorizado para ver esta página, vuelva a la página principal.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'El número de serie no coincide.',
        serialNumberInputError: 'El número de serie no existe.',
        retrofitRentaled:
          'No se puede actualizar la información de la empresa mientras el controlador está en asignación.',
        invalidCorporation:
          'Como su empresa no es un distribuidor, no puede realizar el registro por proxy del controlador.',
      },
    },
    validation: {
      startDate: 'Fecha de inicio',
      endDate: 'Fecha de finalización',
      invalidDateFormat: 'El formato de fecha no es válido.',
      invalidDateTimeFormat: 'El formato de fecha y hora no es válido.',
      duplicateProject: 'El nombre del proyecto está duplicado',
      incorrectBeforeDate: '%{target} debe establecerse antes de %{before}.',
      incorrectAfterDate: '%{target} debe establecerse después de %{after}.',
      incorrectPlanStartDate:
        'La fecha de inicio prevista debe ser antes de la fecha de finalización prevista.',
      incorrectPlanEndDate:
        'La fecha de finalización prevista debe ser una fecha posterior a la fecha de inicio prevista.',
      incorrectActualStartDate:
        'La fecha de inicio debe ser antes de la fecha de finalización.',
      incorrectActualEndDate:
        'La fecha de finalización debe ser posterior a la fecha de inicio.',
      withActualStartDate: 'Veuillez également indiquer la date de début.',
      inputAvailableDate:
        'La fecha que se puede ingresar es a partir de %{date}.',
      registrationRequired:
        "Veuillez sélectionner l'entreprise assignée ou l'adresse électronique.",
      bothInput:
        'Sólo puede introducir la empresa asignada o la dirección de correo electrónico.',
      supportMode:
        "Veuillez saisir l'identifiant de l'entreprise ou l'identifiant du chantier.",
      invalidBkFile: 'No es un archivo BK3.',
      invalidPdfFile: 'No es un archivo PDF.',
      duplicatedBucketName: 'El nombre del cubo está duplicado',
      duplicatedTaskName: 'El nombre de la tarea está duplicado.',
      duplicateGeofenceName: 'El nombre de la geofence está duplicado',
      duplicatedCompactionWorkAreaName:
        'Nombre de área de trabajo de compactación duplicada',
      duplicatedCompactionLayerName: 'Nombre de capa de compactación duplicada',
      duplicated: {
        compactionMaterials: {
          message: 'Nombre de material duplicado',
        },
        weathers: {
          message: 'Nombre de clima duplicado',
        },
        operators: {
          message: 'Nombre de operador duplicado',
        },
      },
      containInvalidString:
        'Se incluyen caracteres no válidos. (%{msg} no está permitido)',
      validHalfWidthAlphanumeric:
        'Solo se permiten caracteres alfanuméricos de media anchura.',
      validNumberFormat: 'No es un número.',
      validIntegerFormat: 'No es un entero.',
      validIntegerRangeFormat: 'Se permite la entrada de 0 a 2147483647.',
      validDuplicateLanguage: 'Se ha seleccionado el mismo idioma.',
      validBeforeDate:
        '%{target} debe establecerse en una fecha y hora antes de %{before}.',
      validAfterDate:
        '%{target} debe establecerse en una fecha y hora después de %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Sitio',
      fields: {
        name: 'Sitio',
        corporationName: 'Nombre de la empresa',
        status: 'Estado',
        workPeriodStartDate: 'Fecha de inicio',
        workPeriodEndDate: 'Fecha de finalización',
      },
      status: {
        completed: 'Completado',
        waiting: 'Antes de que comience la construcción',
        working: 'En construcción',
      },
    },
    retrofits: {
      name: 'Máquina',
      fields: {
        information: 'Info máquina',
        rental: 'Asignación',
        sendBack: 'Enviar de vuelta',
        corporationId: 'Empresa en uso',
        siteId: 'Sitio',
        uuid: 'UUID',
        isError: 'Estado',
        isOnline: 'En línea',
        lastOnlineTime: 'Última conexión',
        basicInfoManufacturer: 'Fabricante',
        basicInfoModel: 'Modelo',
        basicInfoProductNumber: 'N.º producto',
        basicInfoSerialNumber: 'N.º de série',
        controllerManufacturer: 'Fabricante',
        controllerModel: 'Modelo',
        controllerFirmwareVersion: 'Ver. firmware',
        gnssReceiverMainManufacturer: 'Fabricante',
        gnssReceiverMainModel: 'Modelo',
        gnssReceiverMainFirmwareVersion: 'Ver. firmware',
        gnssReceiverSubManufacturer: 'Fabricante',
        gnssReceiverSubModel: 'Modelo',
        gnssReceiverSubFirmwareVersion: 'Ver. firmware',
        machineInfoCompanyName: 'Fabricante',
        machineInfoMachineType: 'Tipo máquina',
        machineInfoMachineName: 'Nombre máquina',
        machineInfoMachineId: 'ID máquina',
        rentalStatus: 'Asignación',
        lastUpdated: 'Última actualización',
      },
      isError: {
        true: 'El formato de notificación del estado del controlador es incorrecto',
        false: 'Normal',
      },
      isOnline: {
        true: 'En línea',
        false: 'Fuera de línea',
      },
      machineInfoMachineType: {
        excavator: 'Excavadora',
        roadroller: 'Rodillo compactador',
        dozer: 'Bulldozer',
        rigiddump: 'Volquete rígido',
        wheelloader: 'Cargadora de ruedas',
        grader: 'Niveladora',
        automobile: 'Automóvil',
      },
      licenseMachineType: {
        excavator: 'Excavadora',
        roadroller: 'Rodillo compactador',
        dozer: 'Bulldozer',
        rigiddump: 'Volquete rígido',
        wheelloader: 'Cargadora de ruedas',
        grader: 'Niveladora',
        automobile: 'Automóvil',
        excavator_license: 'Excavadora : %{licenseGrantInfo}',
        roadroller_license: 'Rodillo compactador : %{licenseGrantInfo}',
        dozer_license: 'Bulldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Volquete rígido : %{licenseGrantInfo}',
        wheelloader_license: 'Cargadora de ruedas: %{licenseGrantInfo}',
        grader_license: 'Niveladora : %{licenseGrantInfo}',
        automobile_license: 'Automóvil: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Transferencia',
        sent: 'Transferido',
        failtosend: 'Transferencia fallida',
      },
      rentalStatus: {
        approved: 'Aprobado',
        unapproved: 'No aprobado',
        returned: 'Devuelto',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'Antena GNSS no detectada',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'IMU de la pluma no detectada',
          2: 'IMU de la pluma no detectada',
          3: 'IMU de la pluma no detectada',
        },
        armImuStatus: {
          0: '',
          1: 'IMU del brazo no detectada',
          2: 'IMU del brazo no detectada',
          3: 'IMU del brazo no detectada',
        },
        bucketImuStatus: {
          0: '',
          1: 'IMU del cazo no detectada',
          2: 'IMU del cazo no detectada',
          3: 'IMU del cazo no detectada',
        },
        bodyImuStatus: {
          0: '',
          1: 'IMU del chasis no detectada',
          2: 'IMU del chasis no detectada',
          3: 'IMU del chasis no detectada',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Sensor de presión del lado delantero no detectado',
          2: 'Sensor de presión del lado delantero no detectado',
          3: 'Sensor de presión del lado delantero no detectado',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Sensor de presión del lado inferior no detectado',
          2: 'Sensor de presión del lado inferior no detectado',
          3: 'Sensor de presión del lado inferior no detectado',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'IMU de inclinación no detectada',
          2: 'IMU de inclinación no detectada',
          3: 'IMU de inclinación no detectada',
        },
        secondBoomImuStatus: {
          0: '',
          1: 'IMU de 2.ª pluma no detectada',
          2: 'IMU de 2.ª pluma no detectada',
          3: 'IMU de 2.ª pluma no detectada',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Sensor de desviación no detectado',
          2: 'Sensor de desviación no detectado',
          3: 'Sensor de desviación no detectado',
          4: 'Sensor de desviación no detectado',
          5: 'Sensor de desviación no detectado',
          6: 'Sensor de desviación no detectado',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Sensor de giro no detectado',
          2: 'Sensor de giro no detectado',
          3: 'Sensor de giro no detectado',
          4: 'Sensor de giro no detectado',
          5: 'Sensor de giro no detectado',
          6: 'Sensor de giro no detectado',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Tiltrotador no detectado',
          2: 'Tiltrotador no detectado',
          3: 'Tiltrotador no detectado',
        },
      },
    },
    retrofitRentals: {
      name: 'Asignación',
      fields: {
        id: 'ID',
        retrofitId: 'ID máquina',
        fromCorporationId: 'ID de la empresa asignadora',
        fromCorporationName: 'Empresa asignadora',
        toCorporationId: 'Empresa asignada',
        toCorporationName: 'Empresa en uso',
        retrofitRentalId: 'ID de la asignación',
        isApproved: 'Estado',
        isReturned: 'Devuelto',
        planStartDate: 'Fecha de inicio prevista',
        planEndDate: 'Fecha de finalización prevista',
        actualStartDate: 'Fecha de inicio',
        actualEndDate: 'Fecha de finalización',
        rentalUsers: 'Correo electrónico',
        email: 'Correo electrónico',
        rentalStatus: 'Estado',
      },
      isApproved: {
        true: 'Aprobado',
        false: 'No aprobado',
      },
      rentalStatus: {
        approved: 'Aprobado',
        unapproved: 'No aprobado',
        returned: 'Devuelto',
      },
    },
    pendingRentals: {
      name: 'Esperando aprobación',
      fields: {
        fromCorporationName: 'Empresa asignadora',
        planStartDate: 'Fecha de inicio prevista',
        planEndDate: 'Fecha de finalización prevista',
      },
    },
    pendingTransfers: {
      name: 'Esperando aprobación',
      fields: {
        fromCorporationName: 'Empresa que transfiere',
        planDate: 'Fecha de transferencia prevista',
        lastUpdated: 'Última actualización',
      },
    },
    pendingAlternateRegists: {
      name: 'Esperando aprobación',
      fields: {
        fromCorporationName: 'Propietario actual',
        lastUpdated: 'Última actualización',
      },
    },
    assignments: {
      name: 'Transferencia',
      fields: {
        id: 'ID',
        retrofitId: 'ID máquina',
        fromCorporationId: 'ID de la empresa que transfiere',
        fromCorporationName: 'Empresa que transfiere',
        toCorporationId: 'Empresa transferida',
        toCorporationName: 'Empresa',
        isApproved: 'Estado',
        planDate: 'Fecha de transferencia prevista',
        actualDate: 'Fecha de transferencia',
        transferUsers: 'Correo electrónico',
        email: 'Correo electrónico',
        lastUpdated: 'Última actualización',
      },
      isApproved: {
        true: 'Aprobado',
        false: 'No aprobado',
      },
    },
    buckets: {
      name: 'Cazo',
      fields: {
        companyName: 'Nombre de la empresa',
        siteId: 'Sitio',
        name: 'Nombre',
        corporationId: 'Empresa en uso',
        bucketType: 'Tipo',
        bk3File: 'Archivo BK3',
        'bk3File.title': 'Archivo BK3',
        gltfFile: 'Archivo del cazo',
        uploadedBy: 'Registrado por',
        updatedBy: 'Actualizado por',
        fileStatus: 'Estado',
        lastUpdated: 'Última actualización',
        uploadBk: 'Cargar BK3',
      },
      bucketType: {
        slope: 'Pendiente',
        standard: 'Estándar',
        tilt: 'Inclinación',
        tiltrotator: 'Rotador de inclinación',
      },
      uploadedBy: {
        admin: 'Administrador',
        tablet: 'Tableta',
      },
      fileStatus: {
        converting: 'Conversión',
        completed: 'Completado',
        error: 'Error',
      },
    },
    projects: {
      name: 'Proyecto',
      fields: {
        name: 'Nombre proyecto',
        coordinate: 'Sistema coorden.',
        version: 'Versión',
        status: 'Estado',
        lastUpdated: 'Última actualización',
        regionId: 'Región',
        projectionId: 'Proyección',
        geoidId: 'Geoide',
        datumId: 'Datum',
        file: 'Archivo(s)',
        retrofitId: 'Máquina objetivo',
      },
      status: {
        converted: 'Completado',
        waitforconvert: 'Conversión',
        error: 'Error',
      },
    },
    extensionarms: {
      name: 'Brazo de extensión',
      fields: {
        extensionarmType: 'Tipo',
        name: 'Nombre',
        corporationId: 'Empresa en uso',
        corporationName: 'Empresa en uso',
        siteId: 'Sitio',
        siteName: 'Sitio',
        lastUpdated: 'Última actualización',
        extensionarmFile: 'Archivo del brazo de extensión',
      },
      extensionarmType: {
        basic: 'Brazo de extensión',
        a: 'Tipo A',
        b: 'Tipo B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Compartir con',
        name: 'Nombre',
        extensionarmType: 'Tipo',
        extensionarmFile: {
          title: 'Archivo del brazo de extensión',
        },
      },
    },
    corporations: {
      name: 'Empresa',
      fields: {
        name: 'Empresa en uso',
      },
    },
    retrofitShareFiles: {
      name: 'Registro de aplicaciones',
      fields: {
        name: 'Nombre de archivo',
        fileTime: 'Fecha y hora de recepción',
        url: 'Descargar',
      },
    },
    retrofitConfigs: {
      name: 'Información de configuración',
      fields: {
        timeStamp: 'Fecha y hora de recepción',
        url: 'Descargar',
      },
    },
    retrofitKitInfos: {
      name: 'Información controlador',
      fields: {
        timeStamp: 'Fecha y hora de recepción',
        url: 'Descargar',
      },
    },
    retrofitCalibs: {
      name: 'Información calibración',
      fields: {
        timeStamp: 'Fecha y hora de recepción',
        url: 'Descargar',
        detailInfo: {
          createDateTime: 'Crear fecha y hora',
          updateDateTime: 'Fecha y hora de actualización',
          machineInfo: {
            companyName: 'Fabricante',
            machineType: 'Tipo máquina',
            machineName: 'Nombre máquina',
            machineId: 'ID máquina',
            drumConfiguration: 'Configuración de la máquina',
            guidanceMode: 'Modo de guiado',
          },
          machineGeometryInfo: {
            boomLength: 'Info geometría máquina',
            secondBoomLength: 'Longitud de la 2.ª pluma',
            armLength: 'Longitud del brazo',
            armTopPinToBucketSideLinkPinLength:
              'Distanc pasador sup brzo a pasad artic later cazo',
            boomTopPinToBucketSideLinkPinLength:
              'Distanc pasador sup plma a pasador artic later cazo',
            bucketLink1Length: 'Distanc pasador later cazo a pasad cilind cazo',
            bucketLink2Length: 'Distanc pasador cilind cazo a pasad artic cazo',
            bodyCenterToBoomFootPin: {
              x: 'Pasad pie pluma X resp a centro chasis',
              y: 'Pasad pie pluma Y resp a centro chasis',
              z: 'Pasad pie pluma Z resp a centro chasis',
            },
            drumWidth: 'Anchura de la cuchilla',
            length: 'Longitud entre zapatas',
            shoeInterval: 'Longitud entre zapatas',
            bladeWidthForDozer: 'Anchura de la pala de la excavadora',
            distanceFromBodyToBlade: 'Distancia del cuerpo a la cuchilla',
            frontToMainGnssAntenna:
              'Longitud desde el centro de una línea recta que conecta el extremo delantero conectado a tierra con la antena principal de GNSS X',
            frontToSubGnssAntenna:
              'Longitud desde el centro de una línea recta que conecta el extremo delantero conectado a tierra con la antena GNSS secundaria X',
            centerToMainGnssAntenna:
              'Distancia Y desde el centro al centro de fase eléctrica GNSS principal',
            centerToSubGnssAntenna:
              'Distancia Y desde el centro al centro de fase eléctrica del GNSS secundario',
            groundToMainGnssAntenna:
              'Distancia Z desde el suelo al centro de fase eléctrica del GNSS principal',
            groundToSubGnssAntenna:
              'Distancia Z desde el suelo al centro de fase eléctrica del GNSS secundario',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'Ángulo desviac IMU pluma',
            secondBoomImuOffsetAngle:
              'Ángulo de desviación de la IMU de 2.ª pluma',
            armImuOffsetAngle: 'Ángulo desviac IMU brazo',
            bucketImuOffsetAngle: 'Ángulo desviac IMU cazo',
            bodyImuOffsetRollAngle: 'Ángul balanc desviac IMU chasis',
            bodyImuOffsetPitchAngle: 'Ángulo cabec desviac IMU chasis',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'GNSS ppal. X resp a centro chasis',
                y: 'GNSS ppal. Y resp a centro chasis',
                z: 'GNSS ppal. Z resp a centro chasis',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'GNSS sec. X resp a centro chasis',
                y: 'GNSS sec. Y resp a centro chasis',
                z: 'GNSS sec. Z resp a centro chasis',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Modelo de pluma giratoria',
            twoPieceBoom: 'Modelo de pluma de 2 piezas',
            minimalSwingRadius: 'Modelo de pluma desplazable',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Separación del contrapeso',
            },
            bodyImu1: {
              roll: 'Valor medido del balanceo de la IMU',
              pitch: 'Cabeceo del valor medido por la IMU',
              yaw: 'Valor medido de guiñada de IMU',
            },
            bodyImu2: {
              roll: 'Valor medido del balanceo de la IMU',
              pitch: 'Valor medido de cabeceo de IMU',
              yaw: 'Valor medido de guiñada de IMU',
            },
            frontLeft: {
              x: 'Valor medido de la parte delantera izquierda X',
              y: 'Valor medido de la parte delantera izquierda Y',
              z: 'Valor medido de la parte delantera izquierda Z',
            },
            frontRight: {
              x: 'Valor medido de la parte delantera derecha X',
              y: 'Valor medido de la parte delantera derecha Y',
              z: 'Valor encuestado de la parte delantera derecha Z',
            },
            frontGroundingEnd: {
              x: 'Valor encuestado del extremo frontal derecho X',
              y: 'Valor encuestado del extremo frontal Y',
              z: 'Valor encuestado del perímetro delantero Z',
            },
            rearGroundingEnd: {
              x: 'Valor medido del perímetro trasero X',
              y: 'Valor medido del perímetro trasero Y',
              z: 'Valor medido del extremo trasero Z',
            },
            mainGnssAntenna: {
              x: 'Valor medido de la antena GNSS principal X',
              y: 'Valor medido de la antena GNSS principal Y',
              z: 'Valor medido de la antena GNSS principal Z',
            },
            subGnssAntenna: {
              x: 'Valor medido de la antena GNSS secundaria X',
              y: 'Valor medido de la antena sub GNSS Y',
              z: 'Valor medido de la antena GNSS secundaria Z',
            },
            prismPoleOffset: 'Altura del poste del prisma',
            antennaElectricalCenterHeight:
              'Altura desde el centro eléctrico de la antena',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Resultado de la verificación de precisión',
      fields: {
        checkDateTime: 'Fecha y hora de confirmación',
        surveyPoint: 'Punto medido',
        positionN: 'Valor medido : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Valor de desplazamiento : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Nombre del punto de referencia',
        referencePointN: 'Punto de referencia : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Unidad de longitud',
      },
    },
    retrofitBasicSettings: {
      name: 'Ajustes básicos',
      fields: {
        timeStamp: 'Fecha y hora de recepción',
        url: 'Descargar',
      },
    },
    retrofitErrors: {
      name: 'Información del error',
      fields: {
        notifiedTime: 'Fecha y hora de ocurrencia',
        errorCodes: 'Código de error: Detalles',
      },
    },
    retrofitToposurveys: {
      name: 'Lista inspec topográf',
      fields: {
        surveyDate: 'Fecha y hora de la encuesta',
        projectName: 'Nombre proyecto',
        layerName: 'Capa',
        pointName: 'Nombre de punto',
        surveyPoint: 'Punto medido',
        n: 'Valor medido : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Unidad de longitud',
      },
    },
    asbuilts: {
      name: 'Resultados de Obra',
      fields: {
        execStartDate: 'Inicio de ejecución',
        execEndDate: 'Finalización de la ejecución',
        startPeriod: 'Período de adquisición de',
        endPeriod: 'Período de adquisición a',
        execStatus: 'Estado',
        url: '',
        csvType: 'Formato de datos',
        createUser: 'Crear usuario',
      },
      execStatus: {
        complete: 'Completado',
        inprogress: 'Procesamiento',
        error: 'Error',
        completewithoutdata: 'Completado (Sin datos)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'tiempo establecido',
      },
    },
    retrofitBasicInfos: {
      name: 'Información básica',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distribuidor',
        retrofitRegionId: 'Región',
        corporationId: 'ID de la empresa',
        corporationName: 'Nombre de la empresa',
        basicInfoManufacturer: 'Fabricante',
        basicInfoModel: 'Modelo',
        basicInfoProductNumber: 'N.º producto',
        basicInfoSerialNumber: 'N.º de série',
        lastUpdated: 'Última actualización',
        description: 'Descripción',
        ownerId: 'ID de la empresa',
        ownerName: 'Nombre de la empresa',
      },
      tag: {
        retrofitBasicInfosDetail: 'Información básica',
        sendBack: 'Enviar de vuelta',
      },
      sendBack: {
        fields: {
          site: 'Sitio',
          corporation: 'Empresa en uso',
          ntripSettingInfo: 'ID Ntrip',
          toRetrofit: 'Controlador alternativo',
          notificationEmailAddress: 'Correo electrónico',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Registro masivo',
      fields: {
        uploadFileName: 'Nombre de archivo',
        execStartDate: 'Fecha y hora de inicio de ejecución',
        execEndDate: 'Fecha y hora de finalización de la ejecución',
        execStatus: 'Estado',
        uploadFile: 'Archivo registrado',
        resultFile: 'Resultados de la inscripción',
      },
      execStatus: {
        complete: 'Completado',
        inprogress: 'Procesamiento',
        error: 'Error',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Acción masiva del administrador de la empresa',
      fields: {
        uploadFileName: 'Nombre de archivo',
        execStartDate: 'Fecha y hora de inicio de ejecución',
        execEndDate: 'Fecha y hora de finalización de la ejecución',
        execStatus: 'Estado',
        uploadFile: 'Archivo registrado',
        resultFile: 'Resultados de la inscripción',
      },
      execStatus: {
        complete: 'Completado',
        inprogress: 'Procesamiento',
        error: 'Error',
      },
    },
    tasks: {
      name: 'Tarea',
      fields: {
        name: 'Nombre',
        status: 'Estado',
        description: 'Descripción',
        projectSourceLayerId: 'Capa',
        retrofitIds: 'Máquina objetivo',
        expiredDate: 'Fecha de vencimiento',
        createDate: 'Fecha de vencimiento',
        rectangle: 'Rango compatible',
        lastUpdated: 'Última actualización',
      },
      status: {
        error: 'Error',
        converting: 'Conversión',
        sending: 'Transferencia',
        sent: 'Transferido',
        failtosend: 'Transferencia fallida',
      },
    },
    compactionMaterials: {
      name: 'Materiales',
      fields: {
        name: 'Nombre',
      },
    },
    weathers: {
      name: 'Condiciones meteorológicas',
      fields: {
        name: 'Nombre',
      },
    },
    operators: {
      name: 'Operadores',
      fields: {
        name: 'Nombre',
      },
    },
    compactionWorkAreas: {
      name: 'Áreas de trabajo de compactación',
      fields: {
        name: 'Nombres de áreas de trabajo',
        blockSize: 'Tamaño de bloque',
        description: 'Descripción',
      },
    },
    compactionLayers: {
      name: 'Capas de compactación',
      fields: {
        name: 'Nombre',
        thickness: 'Espesor',
        passCount: 'Número de pasadas',
        compactionMaterialId: 'Material',
        inUse: 'Estado',
        description: 'Descripción',
      },
      inUse: {
        true: 'en uso',
        false: 'No utilizado',
      },
    },
    gnssSettings: {
      name: 'Configuración Ntrip',
      fields: {
        registCorporationName: 'Registrado por',
        ownerCorporationId: 'ID de la empresa',
        ownerCorporationName: 'Nombre de la empresa',
        name: 'Nombre',
        url: 'URL de servidor',
        portnum: 'Puerto',
        userId: 'ID',
        password: 'Contraseña',
        retrofitId: 'Máquina objetivo',
        status: 'Estado',
        updateDate: 'Última actualización',
      },
      status: {
        waitforsend: 'Conversión',
        sending: 'Transferencia',
        sent: 'Transferido',
        failtosend: 'Transferencia fallida',
      },
    },
    supportMode: {
      name: 'Modo de soporte',
      fields: {
        enabled: 'Activar el modo de soporte',
        corporationId: 'ID de la empresa',
        corporationName: 'Nombre de la empresa',
        siteId: 'ID del sitio',
        siteName: 'Sitio',
        latitude: 'Latitud',
        longitude: 'Longitud',
        workPeriodStartDate: 'Período de construcción actual (fecha de inicio)',
        workPeriodEndDate: 'Período de construcción actual (fecha de fin)',
        photoUrl: 'URL de la imagen del sitio',
        description: 'Descripción',
      },
    },
    userNotices: {
      name: 'Notificación',
      noticeType: {
        emergencyimportant: 'Emergencia/Importante',
        emergency: 'Emergencia',
        important: 'Importante',
      },
      fields: {
        noticeType: 'Importancia',
        noticeTypes: 'Importancia',
        noticeCategory: 'Tipo de notificación',
        title: 'Título',
        content: 'Contenido',
        publicationStartDate: 'Fecha y hora de inicio (UTC)',
        publicationEndDate: 'Fecha y hora de finalización(UTC)',
        file: 'Adjuntos',
        language: 'Idioma',
        emergency: 'Emergencia',
        important: 'Importante',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Versión',
        compatibilityLevel: 'Nivel de compatibilidad',
        firmwareFile: 'Archivo de firmware',
        firmwareFileCheckSum: 'Suma de verificación de firmware',
        firstInitializationFile: 'Archivo de inicialización 1',
        firstInitializationFileCheckSum:
          'Suma de verificación del archivo de inicialización 1',
        secondInitializationFile: 'Archivo de inicialización 2',
        secondInitializationFileCheckSum:
          'Suma de verificación del archivo de inicialización 2',
      },
    },
    bucketShares: {
      name: 'Compartir cubo',
      fields: {
        fromBucketId: 'De la identificación del cubo',
        corporationId: 'ID de la empresa',
        name: 'Nombre',
      },
    },
    extensionarmShare: {
      name: 'Compartir brazo de extensión',
      fields: {
        name: 'Nombre',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCIA',
      fields: {
        licenseId: 'ID de licencia',
        licenseType: 'Tipo',
        issuedAt: 'Fecha de emisión',
        expiresAt: 'Fecha de vencimiento',
        retrofitId: 'Máquina',
        comment: 'Descripción',
        licenseStatus: 'Estado',
        licensePermission: 'LICENCIA',
        productType: 'Nombre del producto',
      },
      licenseStatus: {
        unlicense: 'No asignado',
        disable: 'No válido',
        enable: 'Válido',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Nombre de la empresa',
        namePlates: 'Foto',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Unidad de longitud',
      },
    },
    geofences: {
      name: 'Geovallado',
      fields: {
        type: 'Tipo',
        name: 'Nombre',
        dimension: 'Dimensión',
        trigger: 'Desencadenar',
        valid: 'Válido',
        alertType: 'Tipo de alerta',
        startDate: 'Fecha de inicio',
        endDate: 'Fecha de finalización',
        transparency: 'Transparente',
        elevation: 'Elevación',
        height: 'Altura',
        radius: 'Radio',
        lastUpdated: 'Última actualización',
        points: 'Rango compatible',
        thickness: 'Espesor',
      },
      type: {
        line: 'Línea',
        polygon: 'Polígono',
        circle: 'Círculo',
        point: 'Punto',
        wall: 'Pared',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Válido',
        false: 'No válido',
      },
      alertType: {
        attention: 'Atención',
        notice: 'Aviso',
        warning: 'Advertencia',
        caution: 'Precaución',
        danger: 'Peligro',
      },
      transparency: {
        true: 'Transparente',
        false: 'Opaco',
      },
    },
    geofenceAlerts: {
      name: 'Alertas',
      fields: {
        timeStamp: 'Fecha y hora de detección',
        geofenceName: 'Nombre de geofence',
        alertType: 'Tipo de alerta',
        collisionPoint: 'Punto de colisión',
        retrofit: {
          machineInfoMachineName: 'Máquina',
        },
      },
      alertType: {
        attention: 'Atención',
        notice: 'Aviso',
        warning: 'Advertencia',
        caution: 'Precaución',
        danger: 'Peligro',
      },
    },
    ntrips: {
      name: 'Gestión de Ntrip',
      fields: {
        name: 'ID usuario',
        ntripPass: 'Contraseña',
        serial: 'N.º de série',
        customerCorporationId: 'ID de la empresa',
        customerCorporationName: 'Nombre de la empresa',
        ntrip: 'Estado',
        numberOfExchanges: 'Número de intercambios',
        id: 'ID',
        licenceId: 'ID de licencia',
        dateOfRegistration: 'Fecha de registro',
        billingToCustomers: 'Facturación a clientes',
        paymentToKomatsu: 'Pago a Komatsu',
        applicantId: 'ID del solicitante',
        applicant: 'Solicitante',
        requestCorporationId: 'Solicitar el ID de la empresa',
        requestCorporationName: 'Solicitar el nombre de la empresa',
        isUsed: 'Se usa',
      },
      ntrip: {
        publish: 'Pagado',
        stop: 'Detenerse',
        delete: 'Eliminado',
      },
    },
    retrofitAlternateRegists: {
      name: 'Registro de proxy del controlador',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'de ID de la empresa',
        fromCorporationName: 'Registro de proxy del controlador',
        toCorporationId: 'Usuario',
        toCorporationName: 'Nombre de la empresa',
        isApproved: 'Estado',
        createDate: 'Fecha de solicitud',
        actualDate: 'Fecha de aprobación',
        transferUsers: 'Correo electrónico',
        email: 'Correo electrónico',
        lastUpdated: 'Última actualización',
      },
      isApproved: {
        true: 'Aprobado',
        false: 'No aprobado',
      },
    },
    pendingApprovals: {
      name: 'Esperando aprobación',
      fields: {
        approvalType: 'Tipo',
        requestedBy: 'Empresa del solicitante',
        lastUpdated: 'Última actualización',
      },
      approvalType: {
        assignment: 'Asignación',
        transfer: 'Transferencia',
        agent: 'Registro de proxy del controlador',
      },
    },
    retrofitSendbacks: {
      name: 'Enviar de vuelta',
      fields: {
        fromRetrofitId: 'Desde Retrofit',
        toRetrofitId: 'Controlador alternativo',
        notificationEmailAddress: 'Correo electrónico',
        siteId: 'Sitio',
        corporationId: 'ID de la empresa',
        corporationName: 'Empresa en uso',
        ntripId: 'Info Ntrip',
        isComplete: 'Devolución completa',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
    scGroupwares: {
      fields: {
        name: 'Name',
        createdBy: {
          user: {
            profile: {
              name: 'Author',
            },
          },
        },
        updatedAt: 'Last Modified',
        updatedby: {
          user: {
            profile: {
              name: 'Last Modified By',
            },
          },
        },
        files: {
          totalSize: 'Size',
        },
        file: {
          size: 'Size',
        },
      },
      nodeType: {
        bucket: 'bucket',
        directory: 'folder',
        preiviousFolder: 'preiviousFolder',
        file: 'file',
      },
    },
  },
};

export default message;
