import React, { useCallback } from 'react';
import { useUnselectAll } from 'react-admin';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import { colors } from '../../../theme';
import { BulkActionButtonProps } from '../../bases/list/List';
import { Button } from '../..';
import { OkIcon, ReturnIcon } from '../../../assets';
import { SCGroupwareData } from '../../../dataProvider';
import { useSpacerStyles } from '../../../hooks';

const useStyles = makeStyles(() =>
  createStyles({
    cancelButton: {
      background: colors.button.cancel,
      color: colors.text.primaryLight,
      minWidth: 160,
      minHeight: 34,
      '&:hover': {
        backgroundColor: colors.button.cancelHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.cancelDisabled,
      },
    },
    addButton: {
      background: colors.button.save,
      color: colors.text.primaryLight,
      minWidth: 160,
      minHeight: 34,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
      },
    },
  }),
);

interface FilePickerSaveButtonProps {
  onClose: () => void;
  handleAddSCGroupware: (record: any) => void;
  bucketId?: string;
  selectedRecords: SCGroupwareData[];
}

type ListApprovalButtonrops = React.ComponentProps<BulkActionButtonProps>;

const FilePickerSaveButton: React.FC<
  ListApprovalButtonrops & FilePickerSaveButtonProps
> = ({
  onClose,
  handleAddSCGroupware,
  bucketId,
  selectedRecords,
  ...props
}) => {
  const { resource } = props;
  const classes = useStyles();
  const spaceClasses = useSpacerStyles();
  const unselectAll = useUnselectAll(resource);
  const handleAdd = useCallback(async () => {
    if (selectedRecords.length === 0) return;
    const saveRecord = selectedRecords.map(item => ({ ...item, bucketId })); // POST API用にfile情報にbucketIdを追加

    handleAddSCGroupware(saveRecord);
    onClose();
    unselectAll(resource);
  }, [
    resource,
    bucketId,
    selectedRecords,
    onClose,
    unselectAll,
    handleAddSCGroupware,
  ]);

  return (
    <>
      <Button
        className={classes.cancelButton}
        onClick={onClose}
        data-testid="admin.actions.cancel"
      >
        <ReturnIcon />
      </Button>
      <span className={spaceClasses.root} />
      <Button
        className={classes.addButton}
        onClick={handleAdd}
        disabled={selectedRecords.length === 0}
        data-testid="admin.actions.add"
      >
        <OkIcon />
      </Button>
    </>
  );
};

FilePickerSaveButton.displayName = 'FilePickerSaveButton';
export default FilePickerSaveButton;
