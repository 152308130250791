// for cs
// チェコ語
import czechMessages from 'ra-language-czech';
import en from './en';

const { ra } = czechMessages;
const message: typeof en = {
  ra: {
    ...ra,
    input: {
      ...ra.input,
      password: {
        toggle_visible: '',
        toggle_hidden: '',
      },
    },
    navigation: {
      ...ra.navigation,
      next: '',
      prev: '',
      no_results: 'Nebylo nalezeno',
    },
    action: {
      ...ra.action,
      add_filter: '',
      confirm: 'OK',
      move_up: '',
      move_down: '',
      select_all: '',
      select_row: '',
    },
    message: {
      ...ra.message,
      bulk_delete_title: '',
      bulk_delete_content: 'Chcete smazat %{name}?',
    },
  },
  admin: {
    title: {
      normal: 'Smart Construction Pilot',
      split1: 'Smart Construction',
      split2: 'Pilot',
    },
    copyright: 'Copyright © EARTHBRAIN Ltd. All rights reserved.',
    approval: 'Čekání na schválení',
    usermenu: {
      userNotice: 'Oznámení',
      terms: 'PODMÍNKY SERVISU',
      manual: 'Manuál',
      downloads: 'Stáhnout',
    },
    breadcrumbs: {
      details: 'Podrobnosti',
      retrofitEdit: 'Podrobnosti',
      taskCreate: 'Vytvořit úlohu',
      taskEdit: 'Detaily úkolu',
      geofenceCreate: 'Vytvořit',
      geofenceEdit: 'Upravit',
      geofenceAlertList: 'Upozornění na geofence',
      geofenceAlertShow: 'Podrobnosti',
      compactionWorkAreaCreate: 'Přidat pracovní plochy zahušťování',
      compactionWorkAreaShow: 'Kompakční pracovní oblasti',
      compactionWorkAreaEdit: 'Upravit pracovní oblasti kompakce',
      compactionLayerCreate: 'Vytvořit kompakční vrstvy',
      compactionLayerEdit: 'Upravit kompakční vrstvy',
      supportMode: 'Režim podpory',
      retrofitManage: 'Registrace kontroleru',
      retrofitManageAdmin: 'Registrace kontroleru (Admin)',
      retrofitNamePlateFiles: 'Registrace kontroleru',
      retrofitAlternateRegistCreate: 'Registrace proxy kontroleru',
      retrofitAlternateRegistEdit: 'Podrobnosti',
      retrofitManageAdminBulkActionCreate: 'Registrace',
      terms: 'PODMÍNKY SERVISU',
      downloads: 'Stáhnout',
    },
    pages: {
      siteList: 'Seznam stavenišť',
      siteRetrofitCreate: 'Přidat stroj na staveniště',
      siteRetrofitEdit: '',
      siteBucketCreate: 'Přidat kyblík na staveniště',
      siteBucketEdit: 'Detail vědra',
      siteProjectEdit: 'Detaily projektu',
      siteProjectCreate: 'Registrace souborů projektu',
      siteExtensionarmCreate: 'Přidat prodloužení ramene na staveniště',
      taskCreate: 'Vytvořit úlohu',
      taskEdit: 'Detaily úkolu',
      geofenceAlertList: 'Upozornění na geofence',
      geofenceAlertShow: 'Podrobnosti upozornění na geofence',
      geofenceCreate: 'Vytvořit geofenci',
      geofenceEdit: 'Upravit geofenci',
      compactionWorkAreaCreate: 'Přidat pracovní plochy zahušťování',
      compactionLayerCreate: 'Vytvořit kompakční vrstvy',
      compactionLayerEdit: 'Upravit kompakční vrstvy',
      siteFileEdit: 'Podrobnosti',
      siteFileCreate: 'Přidat soubor na staveniště',
      retrofitList: 'Seznam strojů',
      retrofitEdit: '',
      retrofitRentalCreate: 'Registrace úkolu',
      retrofitTransferCreate: 'Registrace převodu',
      retrofitSendBack: 'Poslat zpět registraci',
      bucketList: 'Seznam vědra',
      bucketEdit: 'Detail vědra',
      bucketCreate: 'Registrace vědra',
      bucketRentalCreate: 'Sdílení vědra',
      extensionarmList: 'Seznam prodloužených ramen',
      extensionarmEdit: 'Informace o prodloužení ramene',
      extensionarmShare: 'Sdílení prodlouženého ramene',
      retrofitBulkActionList: 'Seznam hromadné registrace',
      retrofitBulkActionCreate: 'Hromadná registrace',
      retrofitBasicInfoList: 'Seznam kontrolerů',
      retrofitBasicInfoCreate: 'Registrace kontroleru',
      retrofitBasicInfoEdit: 'Aktualizace kontroleru',
      gnssSettingList: 'Seznam nastavení Ntrip',
      gnssSettingCreate: 'Vytvořit Ntrip nastavení',
      gnssSettingAdminCreate: 'Vytvořit Ntrip nastavení',
      gnssSettingOwnerCreate: 'Vytvořit Ntrip nastavení',
      gnssSettingEdit: 'Upravit Ntrip nastavení',
      gnssSettingAdminEdit: 'Upravit Ntrip nastavení',
      gnssSettingOwnerEdit: 'Upravit Ntrip nastavení',
      approvalList: 'Seznam strojů',
      retrofitRentalEdit: 'Podrobnosti úkolu',
      retrofitTransferEdit: 'Podrobnosti převodu',
      asbuiltsCreate: 'Získat výsledek konstrukce',
      asbuiltsShow: 'Historie Asbuild',
      asbuiltsRetrofitCreate: 'Získat výsledek konstrukce',
      retrofitConfigList: 'Konfigurační informace',
      retrofitKitInfoList: 'Info kontroléru',
      retrofitAccuraciesList: 'Výsledek kontroly přesnosti',
      retrofitAccuraciesShow: 'Podrobnosti',
      retrofitCalibaList: 'Info kalibrace',
      retrofitCalibInfo: 'Podrobnosti o kalibraci stroje',
      retrofitBasicSettingList: 'Základní nastavení',
      retrofitErrorList: 'Informace o chybě',
      retrofitErrorShow: 'Podrobnosti',
      retrofitTopographicSurveyList: 'Seznam topograf. měření',
      retrofitTopographicSurveyShow: 'Topografické údaje',
      retrofitShareFileList: 'Protokol aplikace',
      retrofitCorporationLicenseList: 'Seznam licencí',
      retrofitCorporationLicenseEdit: 'Podrobnosti o licenci',
      CompactionLayerList: 'Kompakční vrstvy',
      userNoticeList: 'Seznam oznámení',
      userNoticeCreate: 'Přidat oznámení',
      userNoticeEdit: 'Aktualizovat oznámení',
      userNoticeDrawer: 'Oznámení',
      retrofitAlternateRegists: 'Seznam registrace proxy kontroleru',
      firmwareList: 'Seznam firmwaru',
      firmwareShow: 'Podrobnosti o firmwaru',
      firmwareCreate: 'Přidat firmware',
      retrofitManage: 'Text',
      retrofitNamePlateFiles: 'Fotografie',
      retrofitManageAdminBulkAction: 'Hromadná registrace',
      retrofitManageAdminBulkActionCreate: 'Hromadná registrace',
      retrofitAlternateRegistCreate: 'Registrace proxy kontroleru',
      retrofitAlternateRegistEdit: 'Podrobnosti registrace proxy kontroleru',
      retrofitAlternateRegistCreateText: 'Text',
      retrofitAlternateRegistCreatePhoto: 'Fotografie',
      ntripList: 'Informace o Ntrip',
      ntripEdit: 'Řízení Ntrip',
    },
    label: {
      login: 'Přihlásit se',
      selected: 'Vybrané případy (%{length})',
      listNoResults: 'Data nenalezena',
      maintenance: {
        title: 'V současné době probíhá údržba.',
        message: `Dočasně jsme pozastavili služby kvůli údržbě.
      Omlouváme se za nepříjemnosti, ale prosíme o chvíli trpělivosti.`,
      },
      fileInput: {
        project: 'xml, tp3, tn3, ln3, gc3, dxf, ttm, svd, dsz, cal',
        bucket: 'bk3',
        csv: 'csv',
        pdf: 'pdf',
        namePlate: 'jpg, png',
        plaseholder:
          'Přetáhněte nebo vyberte soubor(y) (%{fileTypes}) k nahrání.',
        maxFileNum:
          'Maximální počet regulátorů, které lze najednou zaregistrovat, je pět.',
      },
      nosetting: 'Žádné nastavení',
      siteRetrofitList: {
        remoteSupport: 'Vzdálená podpora',
      },
      retrofitEdit: {
        breadcrumbs: 'Podrobnosti',
        belongsDetails: '',
        ntripSettingInfo: 'Informace o nastavení Ntrip',
        machineryDetails: 'Stroje',
        retrofitDetails: 'Řídicí jednotka',
        basicInfo: 'Základní info',
        controller: 'Řídicí jednotka',
        reciverMain: 'Hlavní přijímač GNSS',
        reciverSub: 'Sub přijímač GNSS',
        retrofitstatus: 'Stav kontroléru',
        license: 'Licence',
        licenseId: 'ID licence',
        expiredDate: 'Datum expirace',
      },
      retrofitRental: {
        rentalDetails: 'Podrobnosti úkolu',
        rentalInfo: 'Podrobnosti úkolu',
        lending: 'Úkol',
        return: 'Návrat',
        lendingDestination: 'Přidělená společnost',
        lendingCompany: 'Název společnosti',
        lendingEmail: 'E-mail',
        companyName: 'Název společnosti',
      },
      retorfitTransfer: {
        tab: 'Převod',
        transferInfo: 'Podrobnosti převodu',
        transferButton: 'Převod',
        transferEditButton: 'Upravit přenos',
      },
      siteConfigureEdit: {
        onButton: 'ZAPNUTO',
        offButton: 'VYPNUTO',
      },
      approvals: {
        rentalTab: 'Úkol',
        transferTab: 'Převod',
      },
      buckets: {
        bucketTab: 'Lžíce',
        extensionarmTab: 'Prodloužené rameno',
        extensionarmFile: 'Soubor prodlouženého ramene',
        shareTo: 'Sdílet s',
      },
      bucketRental: {
        corporation: 'Sdílet s',
        bucketType: 'Typ',
        bk3File: 'BK3 soubor',
      },
      retrofitBasic: {
        basicInfoTab: 'Základní info',
        bulkActionTab: 'Hromadná registrace',
        ntripTab: 'Řízení Ntrip',
      },
      retrofitSendbacks: {
        retrofitSendbackInfo: 'Poslat zpět',
        initialize: 'Inicializace',
      },
      retrofitBasicInfos: {
        bulkRegistrationTitle: 'Hromadná aktualizace',
        retrofitDistributor: 'Distributor',
        retrofitRegion: 'Oblast',
        corporationId: 'ID společnosti',
        corporationName: 'Název společnosti',
      },
      retrofitBasicInfoEdit: {
        corporationId: 'ID společnosti je používáno',
        corporationName: 'Společnost je používána',
      },
      gnssSettingsEdit: {
        ownerCorporationName: 'Název společnosti',
      },
      terms: {
        title: 'PODMÍNKY SERVISU',
        message:
          'Prosím klikněte na odkaz níže, abyste si mohl/a zobrazit Podmínky použití.',
        confirmMessage1:
          'Chcete-li používat Smart Construction Pilot, musíte souhlasit s následujícím dokumentem nebo dokumenty.',
        confirmMessage2:
          'Pokud nesouhlasíte, nemůžete používat Smart Construction Pilot.',
        select: 'Prosím, vyberte svůj region.',
      },
      downloads: {
        title: 'Konvertor souborů projektu',
        message:
          'Jedná se o samostatnou aplikaci, která vytváří projektový soubor z návrhových souborů.',
        button: 'Stáhnout',
      },
      retrofitBasicInfoCreate: {
        uuid: 'Automatické číslování',
      },
      retrofitBulkActions: {
        uploadFileDownload: 'Stáhnout',
        resultFileDownload: 'Stáhnout',
      },
      tasks: {
        coordinate: 'Souřadnice (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
      },
      compactionWorkAreas: {
        blockSize: 'Velikost bloku (%{unit})',
      },
      compactionLayers: {
        thickness: 'Tloušťka (%{unit})',
      },
      pendingRentals: {
        breadcrumbs: 'Podrobnosti',
        rentalDetail: 'Podrobnosti úkolu',
      },
      pendingTransfers: {
        breadcrumbs: 'Podrobnosti',
        transferDetail: 'Podrobnosti převodu',
      },
      pendingAlternateRegists: {
        breadcrumbs: 'Podrobnosti',
        alternateRegistDetail: 'Podrobnosti registrace proxy kontroleru',
      },
      supportMode: {
        retrofit: 'Stroje',
        optional: 'Volitelné',
      },
      retrofitList: {
        assignment: 'Úkol',
        license: 'Licence',
      },
      userNotices: {
        languageDetails: 'Obsah oznámení',
        language: 'Jazyk',
        languageEn: 'English',
        languageJa: 'Japonský',
        languageFr: 'Francouzština',
        languageDe: 'Německý',
        languageEs: 'Španělský',
        Information: 'Oznámení',
        Maintenance: 'Údržba',
        emergency: 'Mimořádný',
        important: 'Důležité',
      },
      userNoticeDrawer: {
        more: '',
        close: '',
      },
      retrofitsManage: {
        menuTitle: 'Registrace kontroleru',
        corporationName: 'Název společnosti',
        basicInfoSerialNumber: 'Sériové číslo',
        confirmInput: 'Zadejte sériové číslo znovu',
      },
      retrofitNamePlateFiles: {
        serialNumber: 'Sériové číslo',
        status: 'Stav',
        imageFile: 'Obrázek',
        message1:
          'Prosím, pořiďte snímek sériového čísla ovladače nebo QR kódu, který je jasný a neostrý.',
        message2: 'Obrázky musí být menší než %{mbyte} MB.',
        errormessage:
          'Maximální počet regulátorů, které lze najednou zaregistrovat, je pět.',
      },
      retrofitCorporationLicenses: {
        licenseStatusChoices: {
          enable: 'Platný',
          disable: 'Neplatný',
          unLicense: 'Nesděleno',
        },
        machine: 'Stroje',
        before: 'Před',
        after: 'Po',
      },
      geofences: {
        project: 'Projekt',
        projectSourceLayer: 'Vrstva',
        rgb: 'Barva(RGB)',
        select: 'Geofence výběru',
        coordinates: 'Souřadnice',
        coordinatesLabel: 'Souřadnice (N,E)',
        unitLength: 'Jednotka:%{unit}',
      },
      geofenceDialog: {
        coordinate: 'Souřadnice (unit:%{unit})',
        coordinateX: 'X',
        coordinateY: 'Y',
        radius: 'Poloměr',
      },
      retrofitAlternateRegists: {
        menuTitle: 'Registrace proxy kontroleru',
        corporationName: 'Název společnosti',
        email: 'E-mail',
        basicInfoSerialNumber: 'Sériové číslo',
        confirmInput: 'Zadejte sériové číslo znovu',
      },
      ntripList: {
        unusedTotal: 'Celkem nepoužité',
      },
      calibrationDetail: {
        breadcrumbs: 'Podrobnosti',
        machineInfo: 'Info stroje',
        machineGeometryInfo: 'Info geom. stroje',
        bodyCenterToBoomFootPin: '',
        imuMountInfo: 'Info IMJ příp.',
        gnssMountInfo: '',
        bodyCenterToGnssMain: 'Info hlav. připoj GNSS',
        bodyCenterToGnssSub: 'Sub hlav. připoj GNSS',
        extendedModel: 'Rozšířený model',
        measuredValues: 'Info kalibrace',
      },
    },
    errorInfo: {
      projects: {
        system_scapi_error: 'Chyba konverze dat',
        system_localization_quantity_error: `Nelze zaregistrovat více souborů obsahujících lokalizační informace.
      Zaregistrujte pouze jeden soubor obsahující lokalizační informace.`,
        system_detect_0byte_file_error: `Registrace souboru na server se nezdařila z důvodu chyby komunikace.
      Zaregistrujte prosím soubor znovu.`,
        system_batch_error: 'Systémová chyba (P001)',
        system_parameter_error: 'Systémová chyba (P002)',
        system_parameterfile_notfound: 'Systémová chyba (P003)',
        system_exception: 'Systémová chyba (P004)',
        system_logfile_error: 'Systémová chyba (P005)',
        system_parameterfile_parameter_error: 'Systémová chyba (P006)',
        desing_localization_error: `Není k dispozici žádná lokalizační informace.
      Zaregistrujte prosím jeden z následujících souborů:

      ・Soubor GC3
      ・Soubor TP3 obsahující kontrolní body
      ・Soubor CAL`,
        desing_no_support_dxf_error:
          'Soubor(y) DXF nemohl/mohly být přečten(y), protože jeho/jejich verze je starší než AutoCAD2000.',
        desing_tile_error: 'Nepodařilo se vytvořit projektový soubor.',
        desing_outline_error: 'Vytvoření vnější linie hranice se nezdařilo.',
        desing_area_length_error:
          'Proces byl přerušen, protože oblast projektu je příliš velká. Zkontrolujte prosím, zda jsou projektová data a měřítko dat CAD správné.',
        desing_layer_name_duplication_error: `Data projektu s duplicitními názvy vrstev nemohou být zaregistrována.
      Prosím změňte název vrstvy návrhu nebo registrujte tak, aby existoval pouze jeden soubor se stejným názvem vrstvy návrhu.`,
        targetFiles: '[Název souboru]',
        targetLayers: '[Vrstva]',
        errorFiles: '・%{errorFile}',
      },
      tasks: {
        none: 'Žádné',
        system_batch_error: 'Systémová chyba (T001)',
        system_parameter_error: 'Systémová chyba (T002)',
        system_parameterfile_notfound: 'Systémová chyba (T003)',
        system_exception: 'Systémová chyba (T004)',
        system_logfile_error: 'Systémová chyba (T005)',
        system_basefile_notfound: 'Systémová chyba (T006)',
        system_prjfile_multi: 'Systémová chyba (T007)',
        system_basefile_read_error: 'Systémová chyba (T008)',
        system_target_layer_notfound: 'Systémová chyba (T009)',
        system_target_layer_duplication: 'Stejný název vrstvy již existuje.',
        system_taskarea_error: 'Neplatná oblast úkolu',
        system_taskarea_design_notfound:
          'V oblasti úlohy nejsou žádná data pro soubor projektu(TIN, liniová díla, body).',
        system_tile_error: 'Nepodařilo se vytvořit projektový soubor.',
        system_outline_error: 'Vytvoření vnější linie hranice se nezdařilo.',
        system_parameterfile_error: 'Systémová chyba',
      },
      retrofitError: {
        1000: 'Nerozpoznána data UDP od kontroléru',
        2000: 'Nerozpoznáno IMJ tělesa',
        2001: 'Hardwarová chyba IMJ tělesa',
        2002: 'Chyba algoritmu IMJ tělesa',
        2003: 'Chyba dat IMJ tělesa',
        2004: 'Chyba inicializace IMJ tělesa',
        2100: 'Nerozpoznáno IMJ výložníku',
        2101: 'Hardwarová chyba IMJ výložníku',
        2102: 'Chyba algoritmu IMJ výložníku',
        2103: 'Chyba dat IMJ výložníku',
        2104: 'Chyba inicializace IMJ výložníku',
        2200: 'Nerozpoznáno IMJ ramena',
        2201: 'Hardwarová chyba IMJ ramena',
        2202: 'Chyba algoritmu IMJ ramena',
        2203: 'Chyba dat IMJ ramena',
        2204: 'Chyba inicializace IMJ ramena',
        2300: 'Nerozpoznáno IMJ lžíce',
        2301: 'Hardwarová chyba IMJ lžíce',
        2302: 'Chyba algoritmu IMJ lžíce',
        2303: 'Chyba dat IMJ lžíce',
        2304: 'Chyba inicializace IMJ lžíce',
        2400: 'Nerozpoznáno IMJ naklonění lžíce',
        2401: 'Hardwarová chyba IMJ naklonění lžíce',
        2402: 'Chyba algoritmu IMJ naklonění lžíce',
        2403: 'Chyba dat IMJ naklonění lžíce',
        2404: 'Chyba inicializace IMJ naklonění lžíce',
        2500: 'Nerozpoznáno IMJ druhého výložníku',
        2501: 'Hardwarová chyba IMJ druhého výložníku',
        2502: 'Chyba algoritmu IMJ druhého výložníku',
        2503: 'Chyba dat IMJ druhého výložníku',
        2504: 'Chyba inicializace IMJ druhého výložníku',
        2600: 'Nedetekovaný snímač úhlu kývání',
        2601: 'Chyba systému snímače úhlu kývání',
        2602: 'Chyba hardwaru snímače úhlu kývání',
        2700: 'Kompenzace snímač úhlu kývání',
        2701: 'Chyba systému Snímač kompenzace',
        2702: 'Chyba hardwaru Snímač kompenzace',
        3000: 'Nerozpoznán snímač tlaku na horní straně',
        3100: 'Nerozpoznán snímač tlaku na spodní straně',
        4000: 'Nerozpoznána anténa GNSS',
        5000: 'Nerozpoznána informace korekce',
        5001: 'Nerozpoznán signál rádiového přijímače',
      },
    },
    dialog: {
      menu: {
        confirmManualLink:
          'Budete přesměrováni na jinou webovou stránku. Je to v pořádku?',
      },
      bucketEdit: {
        deleteConfirm: {
          messageTargetItem: 'Lžíce',
        },
      },
      retrofitBasicInfoList: {
        saveValidation: {
          message: 'Je nutné uvést ID společnosti a název společnosti.',
        },
        save: {
          message1: 'ID/název společnosti nelze změnit, protože obsahuje',
          message2: 'stroj(e) přiřazen(y) k jiné společnosti.',
          message3: '',
          message4: 'Prosím zkontrolujte následující sériová čísla',
        },
      },
      retrofitBasicInfoEdit: {
        deleteConfirm: {
          messageTargetItem: 'Řídicí jednotka',
        },
        undeletable: {
          message: 'Řadič je používán a nelze ho odstranit.',
        },
      },
      Ntrip: {
        save: {
          stop: 'Chcete zastavit tento Ntrip nastavení?',
          delete: 'Chcete smazat Ntrip nastavení?',
        },
      },
      siteRetrofitList: {
        title: 'Dodatečné informace',
        message: 'Stavební doba skončila.',
      },
      siteConfigureEdit: {
        title: {
          commonSetting: 'Společné nastavení',
          siteSetting: 'Nastavení staveniště',
        },
        mapBackgroundColor: 'Barva pozadí mapy',
        message:
          'Funkce rozpoznání kontaktu geofence/upozornění nemusí fungovat správně v závislosti na prostředí a podmínkách, ve kterých se používá. Nepoužívejte tuto funkci příliš sebejistě a ujistěte se, že rozumíte funkci a podmínkám použití.',
      },
      geofenceList: {
        confirmDelete: {
          message: 'Chcete smazat tuto geofenci?',
        },
      },
      retrofitRentalCreate: {
        unableTieUpJobSite: {
          message1:
            'Stroj je spojen s pracovištěm a nelze jej přiřadit. Odpojte stroj od pracoviště.',
          message2: 'Prosím odpojte stroj z pracoviště.',
        },
        sameOwner: {
          message:
            'Stroj nelze přiřadit, protože vlastníkem je vaše společnost.',
        },
        unableTransfer: {
          message:
            'Stroj čeká na schválení převodu a nelze zaregistrovat přiřazení.',
        },
        unableAlternateRegist: {
          message:
            'Stroj čeká na schválení registrace Controller Proxy a nelze jej zaregistrovat pro přiřazení.',
        },
      },
      retrofitTransferCreate: {
        messageSiteId:
          'Stroj je připojen k pracovišti, které nelze přenést. Odpojte stroj od pracoviště.',
        messageRentaled: 'Stroj je přiřazen a nelze jej přenést.',
        messageLicense: 'Stroj má licenci a nelze ji převést.',
        pendingApprovals: {
          message1:
            'Žádost o převod byla přijata. Vyčkejte prosím na schválení.',
          message2:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
      },
      retrofitSendBacks: {
        submit:
          'Chcete odeslat ovladač zpět a zdědit nastavení na cílovém ovladači?',
        deleteNtrip: 'Chcete inicializovat vrácený ovladač?',
        unableRegion:
          'Tento řadič byl odeslán do zahraničí. Pokud je tento řadič správný, resetujte "Region" na null.',
        unableCorporation: {
          message1: 'Řadič nelze odeslat zpět.',
          message2:
            'Pokud si myslíte, že se jedná o chybu, kontaktujte svého prodejce.',
        },
        retrofitRentaled: 'Řadič je přidělený a nelze ho odeslat zpět.',
        tiedToSite: 'Stroj je připojen k pracovišti a nelze jej odeslat zpět.',
      },
      retrofitAddToSiteAction: {
        title: 'Dodatečné informace',
        message:
          'Na schválení čeká stroj, proto jej nelze propojit s pracovištěm.',
      },
      retrofitAddToSiteActionTransfer: {
        title: 'Dodatečné informace',
        message: 'Stroj je převáděn a nelze jej připojit k pracovišti.',
      },
      retrofitEdit: {
        unableSettingGnessInfo: {
          message: 'Vybrané nastavení Ntrip je odesíláno do jiného stroje.',
        },
        unableTransferInfo: {
          message: 'K přenosu prosím zrušte následující nastavení.',
          reasonSiteId: 'Staveniště',
          reasonRentaled: 'Úkol',
          reasonLicense: 'Licence',
        },
      },
      retrofitRentalEdit: {
        overDoubleRentalStartDate: {
          message1:
            'Přidělená firma v současné době přiděluje stroj jiné společnosti. Datum zahájení přiřazení k jiné společnosti je %{date}.',
          message2: 'Prosím vyberte konečné datum po %{date}.',
        },
        confirmTieUpJobsite: {
          message:
            'Stroj je spojen s pracovištěm u přiřazené společnosti. Chcete toto přiřazení ukončit?',
        },
        confirmDoubleRental: {
          message:
            '"Přidělená firma v současné době přiděluje stroj jiné společnosti. Přidělená firma v současné době přiděluje stroj jiné společnosti. Nastavení konečného data rovněž ukončí přiřazení k jiné společnosti. Chcete nastavit konečné datum?"',
        },
        unapprovedSave: {
          message: 'Tento úkol nebyl schválen a nelze jej uložit.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Chcete smazat tuto úlohu?',
        },
        approvedDelete: {
          title: '',
          message: 'Tento úkol byl schválen a nelze jej odstranit.',
        },
      },
      retrofitTransferEdit: {
        unapprovedSave: {
          title: '',
          message: 'Stroj je v současné době přiřazen a nelze jej přenést.',
        },
        approvedSave: {
          message: 'Stroj má licenci a nelze ji převést.',
        },
        unapprovedDelete: {
          title: '',
          message: 'Chcete smazat tento přenos?',
        },
        approvedDelete: {
          title: '',
          message: 'Tento převod byl schválen a nelze jej odstranit.',
        },
      },
      extensionarmEdit: {
        deleteConfirm: {
          messageTargetItem: 'Prodloužené rameno',
        },
      },
      projectFileUpload: {
        message: 'Nelze registrovat více souborů kontrolních bodů (.gc3/.cal).',
      },
      projectEdit: {
        confirm: {
          message:
            'Při aktualizaci projektu budou smazány existující úkoly. Chcete aktualizovat?',
        },
      },
      projectConversionStatus: {
        title: 'Stav konverze',
        button: 'OK',
      },
      taskConversionStatus: {
        title: 'Stav konverze',
        button: 'OK',
      },
      taskList: {
        undeletable: {
          message: 'Vybranou úlohu nelze smazat, protože se používá.',
        },
        unableToCreate: {
          message: 'V projektu nejsou zaregistrována žádná konstrukční data.',
        },
        unableToDelete: {
          message1:
            'Úlohu nelze odstranit, protože stav projektu a stav úlohy jsou následující.',
          message2: 'Projekt: Konvertování',
          message3: 'Úkol: Chyba',
        },
        unableToUpdate: {
          message1:
            'Úlohu nelze upravit, protože stav projektu a stav úlohy nejsou následující.',
          message2: 'Projekt: [Konvertován]',
          message3: 'Úkol: [Přenášení][Přeneseno][Nepodařilo se přenést]',
        },
      },
      taskEdit: {
        deleteConfirm: {
          messageTargetItem: 'Úkol',
        },
        unableToDelete: {
          message: 'Vybranou úlohu nelze smazat, protože se používá.',
        },
      },
      taskMap: {
        crossingMessage: 'Úloha je v oblasti, která se kříží.',
        sameValueMessage: 'Byly zadány stejné souřadnice.',
        areaZeroMessage: 'Pro oblast úlohy není k dispozici žádná oblast.',
        noPolygonMessage:
          'Úkol nelze zaregistrovat, protože ještě není zaregistrován.',
      },
      compactionMaterials: {
        add: {
          title: 'Přidat materiál',
        },
        update: {
          title: 'Aktualizovat materiál',
        },
      },
      weathers: {
        add: {
          title: 'Přidat počasí',
        },
        update: {
          title: 'Aktualizovat počasí',
        },
      },
      operators: {
        add: {
          title: 'Přidat operátora',
        },
        update: {
          title: 'Aktualizovat operátora',
        },
      },
      compactionWorkAreas: {
        update: {
          message: 'Upravit pracovní oblasti kompakce',
        },
        undeletable: {
          message:
            'Vrstva zhutnění v oblasti stavby je používána a nelze ji odstranit.',
        },
      },
      compactionLayers: {
        undeletable: {
          message: 'Vrstva zhutnění je používána a nelze ji odstranit.',
        },
        deleteConfirm: {
          messageTargetItem: 'Kompakční vrstva',
        },
      },
      gnssSettings: {
        unableSettingGnessInfo: {
          message: 'Na vybraný stroj je odesílána další Ntrip nastavení.',
        },
        confirmDelete: {
          message: 'Chcete smazat Ntrip nastavení?',
        },
        confirmDeleteWithMachine: {
          message:
            'Stroj je v současné době propojen. Chcete odstranit nastavení Ntrip?',
        },
      },
      userNoticeEdit: {
        deleteConfirm: {
          messageTargetItem: 'Oznámení',
        },
      },
      retrofitCorporationLicenses: {
        confirm: {
          message: 'Nastavte licenci(e) na následující stroj.',
        },
        changeDisable: {
          message:
            'Prosím změňte identifikátor licence následující stavební techniky na platný.',
        },
        changeTemporary: {
          message:
            'Nastavte licenci(e) na následující stroj. Aktuálně nastavená dočasná licence bude neplatná.',
        },
        errorAdd: {
          message:
            'Licenci stroje nelze změnit, protože již byla nastavena licence All.',
        },
        errorSameType: {
          message:
            'Licenci stroje nelze změnit, protože stejný typ licence již byl nastaven.',
        },
        errorUnlimited: {
          message:
            'Stroj má již nastavenou Neomezenou licenci, jiné licence nelze nastavit.',
        },
        addAll: {
          message:
            'Na následujícím stroji již byla nastavena licence. Chcete nastavit Všechny licence?',
        },
        compare: {
          message: 'Změňte licenci(e) pro následující stroj.',
        },
        warning: {
          message:
            '%{retrofit} má licenci z čísla %{id}. Chcete odpojit licenci?',
        },
        unableTransfer: {
          message: 'Stroj je převáděn, takže licenci nelze propojit.',
        },
      },
      retrofitNamePlateFiles: {
        error: {
          serial: 'Existují data, která se nepodařilo analyzovat v obraze.',
        },
      },
      retrofitManageTab: {
        pendingApprovals: {
          message1:
            'Požadavek na registraci byl přijat. Vyčkejte prosím na její schválení.',
          message2:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        corporationIdCheck: {
          message1:
            'Řadič byl již zaregistrován. Zkontrolujte to vyhledáním v zařízeních.',
          message2:
            'Pokud nemůžete najít cílový kontrolér v seznamu, kontaktujte nás z níže uvedené URL.',
        },
        earthbrainIdCheck: {
          message1: 'Omlouváme se za nepříjemnosti',
          message2:
            'ale prosím, kontaktujte nás z níže uvedené URL s následujícími informacemi.',
          message3: '-Sériové číslo kontroleru',
          message4: '-Jméno stroje, který má být registrován (volitelné)',
          message5: '-ID společnosti k registraci',
        },
        invalidSerialNumber: {
          message1: 'Řadič je již používán jinou společností.',
          message2: 'Pokud byste ho chtěli změnit z jiných důvodů,',
          message3:
            'Prosím kontaktujte nás na uvedené URL s následujícími informacemi.',
          message4: '-Sériové číslo kontroleru',
          message5: '-Jméno stroje, který má být registrován (volitelné)',
          message6: '-ID společnosti k registraci',
          message7: '-Důvod změny',
        },
        lendingRetrofits: {
          message1: 'Řadič je přidělen.',
          message2:
            'Prosím, po skončení přidělení v přidělující společnosti zažádejte znovu.',
          message3:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        siteAttached: {
          message1: 'Řadič je propojen s pracovištěm.',
          message2: 'Po odpojení stroje z pracoviště znovu požádejte.',
          message3:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        serialNumberExistError: {
          message1:
            'Toto je sériové číslo řídicí jednotky, která neexistuje. Zkontrolujte, zda je zadané sériové číslo řadiče správné.',
          message2:
            'Pokud problém přetrvává, kontaktujte nás z níže uvedené URL.',
        },
        ntripShortage: {
          message1:
            'Nemohli jsme vám vystavit váš Ntrip uživatelský ID a heslo.',
          message2: 'Prosím kontaktujte nás na uvedené URL.',
        },
        serialnumberCheck: {
          message:
            'Prosím zkontrolujte produkty s následujícími sériovými čísly',
        },
      },
      retrofitAlternateRegists: {
        deleteConfirm: {
          messageTargetItem: 'retrofitAlternateRegists',
        },
        saveValidation: {
          message: 'E-mail je povinný',
        },
        pendingApprovals: {
          message1:
            'Byla obdržena žádost o registraci. Počkejte, dokud není schválena.',
          message2:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        corporationIdCheck: {
          message1:
            'Řadič byl již zaregistrován. Zkontrolujte to vyhledáním v zařízeních.',
          message2:
            'Pokud nemůžete najít cílový kontrolér v seznamu, kontaktujte nás z níže uvedené URL.',
        },
        earthbrainIdCheck: {
          message1: 'Omlouváme se za nepříjemnosti',
          message2:
            'ale prosím, kontaktujte nás z níže uvedené URL s následujícími informacemi.',
          message3: '-Sériové číslo kontroleru',
          message4: '-Jméno stroje, který má být registrován (volitelné)',
          message5: '-ID společnosti k registraci',
        },
        invalidSerialNumber: {
          message1: 'Řadič je již používán jinou společností.',
          message2: 'Pokud byste ho chtěli změnit z jiných důvodů,',
          message3:
            'Prosím kontaktujte nás na uvedené URL s následujícími informacemi.',
          message4: '-Sériové číslo kontroleru',
          message5: '-Jméno stroje, který má být registrován (volitelné)',
          message6: '-ID společnosti k registraci',
          message7: '-Důvod změny',
        },
        lendingRetrofits: {
          message1: 'Řadič je přidělen.',
          message2:
            'Prosím, po skončení přidělení v přidělující společnosti zažádejte znovu.',
          message3:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        siteAttached: {
          message1: 'Řadič je propojen s pracovištěm.',
          message2: 'Po odpojení stroje z pracoviště znovu požádejte.',
          message3:
            'Pokud máte nějaké otázky, kontaktujte nás z níže uvedené URL.',
        },
        serialNumberExistError: {
          message1:
            'Toto je sériové číslo řídicí jednotky, která neexistuje. Zkontrolujte, zda je zadané sériové číslo řadiče správné.',
          message2:
            'Pokud problém přetrvává, kontaktujte nás z níže uvedené URL.',
        },
        ntripShortage: {
          message1:
            'Nemohli jsme vám vystavit váš Ntrip uživatelský ID a heslo.',
          message2: 'Prosím kontaktujte nás na uvedené URL.',
        },
        serialnumberCheck: {
          message:
            'Prosím zkontrolujte produkty s následujícími sériovými čísly',
        },
      },
      pendingApprovals: {
        ownerCorporation: {
          message1: 'Tento řadič je již ve vlastnictví vaší společnosti.',
          message2: 'Prosím kontaktujte žadatelskou společnost.',
        },
        userCorporation: {
          message1:
            'Tuto žádost nemůžete schválit, protože tento správce již máte zapůjčen.',
          message2: 'Prosím kontaktujte žadatelskou společnost.',
        },
      },
      licensePurchaseNotice: {
        button: 'OK',
        message1: `Na místě se používá stroj bez licence.
        Pro použití na místě je vyžadována licence.
        Pokud nemáte zakoupenou licenci, zakupte si ji na trhu Smart Construction Marketplace.`,
        message2: `
        Pokud si stroj pronajímáte od jiné společnosti, vyžádejte si licenci pro stroj od zdroje pronájmu.

        Máte-li jakékoli dotazy, podívejte se na následující stránky podpory.`,
      },
      siteBuriedStructureEdit: {
        title: 'Buried Structure',
      },
      filePicker: {
        title: 'Import From Groupware',
        breadcrumbs: 'Home',
        selectedFile: 'Selected Files',
        label: 'Import From Groupware',
      },
    },
    tootip: {
      retrofitShareFileList: 'Protokol aplikace',
      retrofitConfigList: 'Konfigurační informace',
      retrofitKitInfoList: 'Info kontroléru',
      retrofitAccuraciesList: 'Výsledek kontroly přesnosti',
      retrofitCalibaList: 'Info kalibrace',
      retrofitBasicSettingList: 'Základní nastavení',
      retrofitErrorList: 'Podrobnosti chyby',
      retrofitTopographicSurveyList: 'Topografické měření',
      retrofitAsbuilts: 'Výsledky stavby',
    },
    actions: {
      ok: 'OK',
      add: 'Přidat',
      create: 'Registrace',
      yes: 'Ano',
      no: 'Ne',
      confirmation: 'Potvrdit',
      save: 'ULOŽIT',
      cancel: 'Zrušit',
      required: 'Požadováno',
      addMachineToSite: 'Přidat stroj na staveniště',
      addBucketToSite: 'Přidat',
      deleteFromSite: 'Odpojit se z pracoviště',
      removeLicense: 'Odpojit se od stroje',
      lendMachine: 'Úkol',
      transferMachine: 'Převod',
      remoteSupport: 'Vzdálená podpora',
      lendBucket: 'Sdílet',
      approval: 'Schválení',
      connect: 'Připojit',
      creationInstructions: 'Získat výsledek konstrukce',
      csvDownload: 'Stáhnout',
      retrofitBundleCreate: 'Hromadná registrace',
      termsAccept: 'Souhlasit',
      termsDeny: 'Nesouhlasit',
      bulkRegistration: 'Hromadná aktualizace',
      bulkRegistrationOK: 'OK',
      compactionItems: 'Kompakční položky',
      addLanguage: 'Přidat jazyk',
    },
    message: {
      error: 'Došlo k chybě',
      confirm: 'Je to v pořádku?',
      fileUpload: 'Přetáhněte a upusťte soubory sem',
      success: 'Registrace byla dokončena',
      approved: 'Schválení bylo dokončeno',
      deleteConfirmTitle: '',
      deleteConfirmContent: 'Chcete smazat %{title}?',
      bulkDeleteConfirmContent: 'Chcete smazat %{title}?',
      disconnectFromSite: 'Chcete se odpojit od pracoviště?',
      saveConfirmContent: 'Potvrdit',
      removeLicense: 'Chcete se odpojit od stroje?',
      task: {
        crossingMessage: 'Úloha je v oblasti, která se kříží.',
        sameValueMessage: 'Byly zadány stejné souřadnice.',
        areaZeroMessage: 'Pro oblast úlohy není k dispozici žádná oblast.',
        invalidPolygonMessage: 'Byly zadány stejné souřadnice.',
      },
      geofence: {
        crossingMessage: 'Oblast geofondu je protnuta.',
        sameValueMessage: 'Byly zadány stejné souřadnice.',
        areaZeroMessage:
          'Neexistuje žádná oblast pro oblast zeměpisného ohraničení.',
      },
      licenseError: 'Nemáte licenci pro Smart Construction Pilot(Web App).',
      retrofitsManage: {
        serialNumberMatchError: 'Sériové číslo nesouhlasí.',
        serialNumberInputError: 'Sériové číslo neexistuje',
        invalidAccess:
          'Nejste oprávněni zobrazit tuto stránku, vraťte se na horní stránku.',
      },
      retrofitsAlternateRegistText: {
        serialNumberMatchError: 'Sériové číslo nesouhlasí.',
        serialNumberInputError: 'Sériové číslo neexistuje',
        retrofitRentaled:
          'Informace o společnosti nelze aktualizovat, pokud je kontroler přiřazen k úkolu.',
        invalidCorporation:
          'Vzhledem k tomu, že vaše společnost není distributorem, nemůžete provádět proxy registraci regulátoru.',
      },
    },
    validation: {
      startDate: 'Datum zahájení',
      endDate: 'Datum ukončení',
      invalidDateFormat: 'Formát data je neplatný.',
      invalidDateTimeFormat: 'Formát datumu a času je neplatný.',
      duplicateProject: 'Název projektu je duplikován',
      incorrectBeforeDate: '%{target} musí být nastaveno před %{before}.',
      incorrectAfterDate: '%{target} musí být nastaveno po %{after}.',
      incorrectPlanStartDate:
        'Plánované datum zahájení musí být před plánovaným datem ukončení.',
      incorrectPlanEndDate:
        'Plánované datum ukončení musí být datum po plánovaném datu zahájení.',
      incorrectActualStartDate:
        'Datum zahájení musí být datem před koncovým datem.',
      incorrectActualEndDate:
        'Datum ukončení musí být nastaveno na datum po datu zahájení.',
      withActualStartDate: 'Prosím zadejte také datum zahájení.',
      inputAvailableDate: 'Datum, které lze zadat, je od %{date}.',
      registrationRequired:
        'Prosím, vyberte přiřazenou společnost nebo e-mailovou adresu.',
      bothInput:
        'Můžete zadat pouze přiřazenou společnost nebo e-mailovou adresu.',
      supportMode: 'Prosím zadejte buď ID společnosti nebo ID staveniště.',
      invalidBkFile: 'Není to soubor BK3.',
      invalidPdfFile: 'Není to soubor PDF.',
      duplicatedBucketName: 'Název vědra je duplikován',
      duplicatedTaskName: 'Název úkolu se opakuje.',
      duplicateGeofenceName: 'Název geofence je duplicitní',
      duplicatedCompactionWorkAreaName:
        'Duplikované jméno pracovní oblasti kompakce',
      duplicatedCompactionLayerName: 'Duplikované jméno kompakční vrstvy',
      duplicated: {
        compactionMaterials: {
          message: 'Duplikované jméno materiálu',
        },
        weathers: {
          message: 'Duplikované jméno počasí',
        },
        operators: {
          message: 'Duplikované jméno operátora',
        },
      },
      containInvalidString:
        'Obsahují nesprávné znaky. (Použití znaku %{msg} není povoleno)',
      validHalfWidthAlphanumeric:
        'Jsou povoleny pouze polovysoké alfanumerické znaky.',
      validNumberFormat: 'Není to číslo.',
      validIntegerFormat: 'Není to celé číslo',
      validIntegerRangeFormat: 'Vstup je povolen od 0 do 2147483647.',
      validDuplicateLanguage: 'Je zvolen stejný jazyk.',
      validBeforeDate:
        '%{target} musí být nastaveno na datum a čas před %{before}.',
      validAfterDate:
        '%{target} musí být nastaveno na datum a čas po %{after}.',
    },
  },
  resources: {
    sites: {
      name: 'Staveniště',
      fields: {
        name: 'Staveniště',
        corporationName: 'Název společnosti',
        status: 'Stav',
        workPeriodStartDate: 'Datum zahájení',
        workPeriodEndDate: 'Datum ukončení',
      },
      status: {
        completed: 'Dokončeno',
        waiting: 'Před začátkem výstavby',
        working: 'Ve výstavbě',
      },
    },
    retrofits: {
      name: 'Stroje',
      fields: {
        information: 'Info stroje',
        rental: 'Úkol',
        sendBack: 'Poslat zpět',
        corporationId: 'Společnost je používána',
        siteId: 'Staveniště',
        uuid: 'UUID',
        isError: 'Stav',
        isOnline: 'Online',
        lastOnlineTime: 'Poslední připojení',
        basicInfoManufacturer: 'Výrobce',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Č. produktu',
        basicInfoSerialNumber: 'Výrobní číslo',
        controllerManufacturer: 'Výrobce',
        controllerModel: 'Model',
        controllerFirmwareVersion: 'Verze firmwaru',
        gnssReceiverMainManufacturer: 'Výrobce',
        gnssReceiverMainModel: 'Model',
        gnssReceiverMainFirmwareVersion: 'Verze firmwaru',
        gnssReceiverSubManufacturer: 'Výrobce',
        gnssReceiverSubModel: 'Model',
        gnssReceiverSubFirmwareVersion: 'Verze firmwaru',
        machineInfoCompanyName: 'Výrobce',
        machineInfoMachineType: 'Typ stroje',
        machineInfoMachineName: 'Název stroje',
        machineInfoMachineId: 'ID stroje',
        rentalStatus: 'Úkol',
        lastUpdated: 'Poslední aktualizace',
      },
      isError: {
        true: 'Nesprávný formát oznámení o stavu ovladače',
        false: 'Normální',
      },
      isOnline: {
        true: 'Online',
        false: 'Offline',
      },
      machineInfoMachineType: {
        excavator: 'Bagr',
        roadroller: 'Silniční válec',
        dozer: 'Buldozer',
        rigiddump: 'Nenakloněný hromadník',
        wheelloader: 'Nakladač na kola',
        grader: 'Hrbolice',
        automobile: 'Automobil',
      },
      licenseMachineType: {
        excavator: 'Bagr',
        roadroller: 'Silniční válec',
        dozer: 'Buldozer',
        rigiddump: 'Nenakloněný hromadník',
        wheelloader: 'Nakladač na kola',
        grader: 'Hrbolice',
        automobile: 'Automobil',
        excavator_license: 'Bagr : %{licenseGrantInfo}',
        roadroller_license: 'Silniční válec : %{licenseGrantInfo}',
        dozer_license: 'Buldozer : %{licenseGrantInfo}',
        rigiddump_license: 'Nenakloněný hromadník : %{licenseGrantInfo}',
        wheelloader_license: 'Nakladač na kola: %{licenseGrantInfo}',
        grader_license: 'Hrbolice : %{licenseGrantInfo}',
        automobile_license: 'Automobil: %{licenseGrantInfo}',
      },
      gnssSettingStatus: {
        sending: 'Přenesení',
        sent: 'Přeneseno',
        failtosend: 'Převod se nezdařil',
      },
      rentalStatus: {
        approved: 'Schváleno',
        unapproved: 'Nesouhlaseno',
        returned: 'Vráceno',
      },
      controllerStatusCode: {
        version: {
          1: '',
          2: '',
        },
        ahrsEstimateStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: '',
          5: '',
          6: '',
        },
        positionStatus: {
          0: '',
          1: '',
          2: '',
          3: '',
          4: 'Nerozpoznána anténa GNSS',
        },
        baseStationConnectionStatus: {
          0: '',
          1: '',
          2: '',
        },
        boomImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ výložníku',
          2: 'Nerozpoznáno IMJ výložníku',
          3: 'Nerozpoznáno IMJ výložníku',
        },
        armImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ ramena',
          2: 'Nerozpoznáno IMJ ramena',
          3: 'Nerozpoznáno IMJ ramena',
        },
        bucketImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ lžíce',
          2: 'Nerozpoznáno IMJ lžíce',
          3: 'Nerozpoznáno IMJ lžíce',
        },
        bodyImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ tělesa',
          2: 'Nerozpoznáno IMJ tělesa',
          3: 'Nerozpoznáno IMJ tělesa',
        },
        boomHeadPressureSensorStatus: {
          0: '',
          1: 'Nerozpoznán snímač tlaku na horní straně',
          2: 'Nerozpoznán snímač tlaku na horní straně',
          3: 'Nerozpoznán snímač tlaku na horní straně',
        },
        boomBottomPressureSensorStatus: {
          0: '',
          1: 'Nerozpoznán snímač tlaku na spodní straně',
          2: 'Nerozpoznán snímač tlaku na spodní straně',
          3: 'Nerozpoznán snímač tlaku na spodní straně',
        },
        tiltBucketImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ naklonění lžíce',
          2: 'Nerozpoznáno IMJ naklonění lžíce',
          3: 'Nerozpoznáno IMJ naklonění lžíce',
        },
        secondBoomImuStatus: {
          0: '',
          1: 'Nerozpoznáno IMJ 2. výložníku',
          2: 'Nerozpoznáno IMJ 2. výložníku',
          3: 'Nerozpoznáno IMJ 2. výložníku',
        },
        rotarySensorStatus: {
          0: '',
          1: 'Nedetekovaný snímač kompenzace',
          2: 'Nedetekovaný snímač kompenzace',
          3: 'Nedetekovaný snímač kompenzace',
          4: 'Nedetekovaný snímač kompenzace',
          5: 'Nedetekovaný snímač kompenzace',
          6: 'Nedetekovaný snímač kompenzace',
        },
        boomOffsetSensorStatus: {
          0: '',
          1: 'Nedetekovaný snímače kývání',
          2: 'Nedetekovaný snímače kývání',
          3: 'Nedetekovaný snímače kývání',
          4: 'Nedetekovaný snímače kývání',
          5: 'Nedetekovaný snímače kývání',
          6: 'Nedetekovaný snímače kývání',
        },
        tiltrotatorStatus: {
          0: '',
          1: 'Nedetekovaný tiltrotator',
          2: 'Nedetekovaný tiltrotator',
          3: 'Nedetekovaný tiltrotator',
        },
      },
    },
    retrofitRentals: {
      name: 'Úkol',
      fields: {
        id: 'ID',
        retrofitId: 'ID stroje',
        fromCorporationId: 'ID přidělující společnosti',
        fromCorporationName: 'Přidělující společnost',
        toCorporationId: 'Přidělená společnost',
        toCorporationName: 'Společnost je používána',
        retrofitRentalId: 'ID úkolu',
        isApproved: 'Stav',
        isReturned: 'Vráceno',
        planStartDate: 'Plánované datum zahájení',
        planEndDate: 'Plánované datum ukončení',
        actualStartDate: 'Datum zahájení',
        actualEndDate: 'Datum ukončení',
        rentalUsers: 'E-mail',
        email: 'E-mail',
        rentalStatus: 'Stav',
      },
      isApproved: {
        true: 'Schváleno',
        false: 'Nesouhlaseno',
      },
      rentalStatus: {
        approved: 'Schváleno',
        unapproved: 'Nesouhlaseno',
        returned: 'Vráceno',
      },
    },
    pendingRentals: {
      name: 'Čekání na schválení',
      fields: {
        fromCorporationName: 'Přidělující společnost',
        planStartDate: 'Plánované datum zahájení',
        planEndDate: 'Plánované datum ukončení',
      },
    },
    pendingTransfers: {
      name: 'Čekání na schválení',
      fields: {
        fromCorporationName: 'Přenášející společnost',
        planDate: 'Plánované datum převodu',
        lastUpdated: 'Poslední aktualizace',
      },
    },
    pendingAlternateRegists: {
      name: 'Čekání na schválení',
      fields: {
        fromCorporationName: 'Aktuální vlastník',
        lastUpdated: 'Poslední aktualizace',
      },
    },
    assignments: {
      name: 'Převod',
      fields: {
        id: 'ID',
        retrofitId: 'ID stroje',
        fromCorporationId: 'ID přenášející společnosti',
        fromCorporationName: 'Přenášející společnost',
        toCorporationId: 'Přenesená společnost',
        toCorporationName: 'Společnost',
        isApproved: 'Stav',
        planDate: 'Plánované datum převodu',
        actualDate: 'Datum převodu',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Poslední aktualizace',
      },
      isApproved: {
        true: 'Schváleno',
        false: 'Nesouhlaseno',
      },
    },
    buckets: {
      name: 'Lžíce',
      fields: {
        companyName: 'Název společnosti',
        siteId: 'Staveniště',
        name: 'Název',
        corporationId: 'Společnost je používána',
        bucketType: 'Typ',
        bk3File: 'BK3 soubor',
        'bk3File.title': 'BK3 soubor',
        gltfFile: 'Soubor lžíce',
        uploadedBy: 'Zaregistrováno',
        updatedBy: 'Aktualizováno pomocí',
        fileStatus: 'Stav',
        lastUpdated: 'Poslední aktualizace',
        uploadBk: 'Nahrát BK3',
      },
      bucketType: {
        slope: 'Sklon',
        standard: 'Standardní',
        tilt: 'Naklonění',
        tiltrotator: 'Naklápěcí rotátor',
      },
      uploadedBy: {
        admin: 'Správce',
        tablet: 'Tablet',
      },
      fileStatus: {
        converting: 'Konverze',
        completed: 'Dokončeno',
        error: 'Chyba',
      },
    },
    projects: {
      name: 'Projekt',
      fields: {
        name: 'Název projektu',
        coordinate: 'Souřad. systém',
        version: 'Verze',
        status: 'Stav',
        lastUpdated: 'Poslední aktualizace',
        regionId: 'Oblast',
        projectionId: 'Projekce',
        geoidId: 'Geoid',
        datumId: 'Datum',
        file: 'Soubor(y)',
        retrofitId: 'Cílový stroj',
      },
      status: {
        converted: 'Dokončeno',
        waitforconvert: 'Konverze',
        error: 'Chyba',
      },
    },
    extensionarms: {
      name: 'Prodloužené rameno',
      fields: {
        extensionarmType: 'Typ',
        name: 'Název',
        corporationId: 'Společnost je používána',
        corporationName: 'Společnost je používána',
        siteId: 'Staveniště',
        siteName: 'Staveniště',
        lastUpdated: 'Poslední aktualizace',
        extensionarmFile: 'Soubor prodlouženého ramene',
      },
      extensionarmType: {
        basic: 'Prodloužené rameno',
        a: 'Typ A',
        b: 'Typ B',
      },
    },
    extensionarmShares: {
      fields: {
        corporationId: 'Sdílet s',
        name: 'Název',
        extensionarmType: 'Typ',
        extensionarmFile: {
          title: 'Soubor prodlouženého ramene',
        },
      },
    },
    corporations: {
      name: 'Společnost',
      fields: {
        name: 'Společnost je používána',
      },
    },
    retrofitShareFiles: {
      name: 'Protokol aplikace',
      fields: {
        name: 'Název souboru',
        fileTime: 'Datum a čas přijetí',
        url: 'Stáhnout',
      },
    },
    retrofitConfigs: {
      name: 'Konfigurační informace',
      fields: {
        timeStamp: 'Datum a čas přijetí',
        url: 'Stáhnout',
      },
    },
    retrofitKitInfos: {
      name: 'Info kontroléru',
      fields: {
        timeStamp: 'Datum a čas přijetí',
        url: 'Stáhnout',
      },
    },
    retrofitCalibs: {
      name: 'Info kalibrace',
      fields: {
        timeStamp: 'Datum a čas přijetí',
        url: 'Stáhnout',
        detailInfo: {
          createDateTime: 'Datum a čas vytvoření',
          updateDateTime: 'Datum a čas aktualizace',
          machineInfo: {
            companyName: 'Výrobce',
            machineType: 'Typ stroje',
            machineName: 'Název stroje',
            machineId: 'ID stroje',
            drumConfiguration: 'Konfigurace stroje',
            guidanceMode: 'Režim navádění',
          },
          machineGeometryInfo: {
            boomLength: 'Délka výložníku',
            secondBoomLength: 'Délka druhého výložníku',
            armLength: 'Délka ramena',
            armTopPinToBucketSideLinkPinLength: 'Vz.hor.č.r. a sp.č.str.lž',
            boomTopPinToBucketSideLinkPinLength: 'Vz.hor.č.v. a sp.č.str.lž',
            bucketLink1Length: 'Vz.sp.č.str.lž a č.v.lž.',
            bucketLink2Length: 'Vz.č.v.lž. a č.sp.lž',
            bodyCenterToBoomFootPin: {
              x: 'Dol.č.výl.X k stř.těl.',
              y: 'Dol.č.výl.Y k stř.těl.',
              z: 'Dol.č.výl.Z k stř.těl.',
            },
            drumWidth: 'Šířka čepele',
            length: 'Délka mezi botami',
            shoeInterval: 'Délka intervalu mezi botami',
            bladeWidthForDozer: 'Šířka čepele pro buldozer',
            distanceFromBodyToBlade: 'Vzdálenost mezi tělesem a lopatou',
            frontToMainGnssAntenna:
              'Délka od středu přímky spojující přední uzemněný konec čela s hlavní anténou gnss X',
            frontToSubGnssAntenna:
              'Délka od středu přímky spojující přední uzemněnou přední část s vedlejší anténou X',
            centerToMainGnssAntenna:
              'Y Vzdálenost od středu k hlavnímu elektrickému fázovému středu GNSS',
            centerToSubGnssAntenna:
              'Vzdálenost Y od středu ke středu elektrické fáze sub GNSS',
            groundToMainGnssAntenna:
              'Z vzdálenost od země ke středu elektrické fáze hlavního GNSS',
            groundToSubGnssAntenna:
              'Z vzdálenost od země k elektrickému fázovému středu sub GNSS',
          },
          imuMountInfo: {
            boomImuOffsetAngle: 'IMJ kor.úhlu výl.',
            secondBoomImuOffsetAngle: 'Úhel kompenzace IMJ druhého výložníku',
            armImuOffsetAngle: 'IMJ kor.úhlu ram.',
            bucketImuOffsetAngle: 'IMJ kor.úhlu lž.',
            bodyImuOffsetRollAngle: 'IMJ kor.úhlu vál.',
            bodyImuOffsetPitchAngle: 'IMJ kor.úhlu nakl.',
          },
          gnssMountInfo: {
            main: {
              bodyCenterToGnss: {
                x: 'Hl. GNSS X ke stř.těl.',
                y: 'Hl. GNSS Y ke stř.těl.',
                z: 'Hl. GNSS Z ke stř.těl.',
              },
            },
            sub: {
              bodyCenterToGnss: {
                x: 'Sub GNSS X ke stř.těl.',
                y: 'Sub GNSS Y ke stř.těl.',
                z: 'Sub GNSS Z ke stř.těl.',
              },
            },
          },
          extendedModel: {
            swingBoom: 'Model výkyvný výložník',
            twoPieceBoom: 'Model dvoudílný výložník',
            minimalSwingRadius: 'Model odsazený výložník',
          },
          measuredValues: {
            otherLength: {
              machineHeight: 'Rezerva protizávaží',
            },
            bodyImu1: {
              roll: 'Převrácení naměřené hodnoty IMU',
              pitch: 'Výška naměřené hodnoty IMU',
              yaw: 'Odklon naměřené hodnoty IMU',
            },
            bodyImu2: {
              roll: 'Převrácení naměřené hodnoty IMU',
              pitch: 'Hodnota měření náklonu IMU',
              yaw: 'Hodnota měření odklonu IMU',
            },
            frontLeft: {
              x: 'Zjištěná hodnota přední levé části X',
              y: 'Zjištěná hodnota přední levé části Y',
              z: 'Zkoumaná hodnota přední levé strany Z',
            },
            frontRight: {
              x: 'Zjištěná hodnota přední pravé strany X',
              y: 'Zjištěná hodnota přední pravé části Y',
              z: 'Zjištěná hodnota přední pravé strany Z',
            },
            frontGroundingEnd: {
              x: 'Zjištěná hodnota předního konce žlábku X',
              y: 'Zjištěná hodnota předního konce obruby Y',
              z: 'Zjištěná hodnota přední části náspu Z',
            },
            rearGroundingEnd: {
              x: 'Zjištěná hodnota zadní části náspu X',
              y: 'Zjištěná hodnota zadní části náspu Y',
              z: 'Zjištěná hodnota zadního odtoku Z',
            },
            mainGnssAntenna: {
              x: 'Zjištěná hodnota hlavní antény GNSS X',
              y: 'Zjištěná hodnota hlavní antény GNSS Y',
              z: 'Zjištěná hodnota hlavní antény GNSS Z',
            },
            subGnssAntenna: {
              x: 'Zjištěná hodnota vedlejší antény GNSS X',
              y: 'Zjištěná hodnota dílčí antény GNSS Y',
              z: 'Zjištěná hodnota dílčí antény GNSS Z',
            },
            prismPoleOffset: 'Výška pólu hranolu',
            antennaElectricalCenterHeight:
              'Výška od elektrického středu antény',
          },
        },
      },
    },
    retrofitAccuracies: {
      name: 'Výsledek kontroly přesnosti',
      fields: {
        checkDateTime: 'Datum a čas potvrzení',
        surveyPoint: 'Měřený bod',
        positionN: 'Naměřená hodnota : N',
        positionE: 'E',
        positionZ: 'Z',
        machineOffsetN: 'Hodnota posunu : N',
        machineOffsetE: 'E',
        machineOffsetZ: 'Z',
        referencePointName: 'Název referenčního bodu',
        referencePointN: 'Referenční bod : N',
        referencePointE: 'E',
        referencePointZ: 'Z',
        unitLength: 'Jednotka délky',
      },
    },
    retrofitBasicSettings: {
      name: 'Základní nastavení',
      fields: {
        timeStamp: 'Datum a čas přijetí',
        url: 'Stáhnout',
      },
    },
    retrofitErrors: {
      name: 'Informace o chybě',
      fields: {
        notifiedTime: 'Datum a čas výskytu',
        errorCodes: 'Chybový kód: Podrobnosti',
      },
    },
    retrofitToposurveys: {
      name: 'Seznam topograf. měření',
      fields: {
        surveyDate: 'Datum a čas průzkumu',
        projectName: 'Název projektu',
        layerName: 'Vrstva',
        pointName: 'Název bodu',
        surveyPoint: 'Měřený bod',
        n: 'Naměřená hodnota : N',
        e: 'E',
        z: 'Z',
        unitLength: 'Jednotka délky',
      },
    },
    asbuilts: {
      name: 'Výsledky stavby',
      fields: {
        execStartDate: 'Zahájení provádění',
        execEndDate: 'Konec provádění',
        startPeriod: 'Období akvizice od',
        endPeriod: 'Období akvizice do',
        execStatus: 'Stav',
        url: '',
        csvType: 'Formát dat',
        createUser: 'Vytvořit uživatele',
      },
      execStatus: {
        complete: 'Dokončeno',
        inprogress: 'Zpracování',
        error: 'Chyba',
        completewithoutdata: 'Dokončeno (Žádná data)',
      },
    },
    asbuiltTime: {
      fields: {
        id: 'nastavený čas',
      },
    },
    retrofitBasicInfos: {
      name: 'Základní info',
      fields: {
        uuid: 'UUID',
        retrofitDistributorId: 'Distributor',
        retrofitRegionId: 'Oblast',
        corporationId: 'ID společnosti',
        corporationName: 'Název společnosti',
        basicInfoManufacturer: 'Výrobce',
        basicInfoModel: 'Model',
        basicInfoProductNumber: 'Č. produktu',
        basicInfoSerialNumber: 'Výrobní číslo',
        lastUpdated: 'Poslední aktualizace',
        description: 'Popis',
        ownerId: 'ID společnosti',
        ownerName: 'Název společnosti',
      },
      tag: {
        retrofitBasicInfosDetail: 'Základní info',
        sendBack: 'Poslat zpět',
      },
      sendBack: {
        fields: {
          site: 'Staveniště',
          corporation: 'Společnost je používána',
          ntripSettingInfo: 'Ntrip ID',
          toRetrofit: 'Alternativní řadič',
          notificationEmailAddress: 'E-mail',
        },
      },
    },
    retrofitBulkActions: {
      name: 'Hromadná registrace',
      fields: {
        uploadFileName: 'Název souboru',
        execStartDate: 'Zahájení provádění datum a čas',
        execEndDate: 'Konec provádění datum a čas',
        execStatus: 'Stav',
        uploadFile: 'Registrovaný soubor',
        resultFile: 'Výsledky registrace',
      },
      execStatus: {
        complete: 'Dokončeno',
        inprogress: 'Zpracování',
        error: 'Chyba',
      },
    },
    retrofitAdminCorporationBulkActions: {
      name: 'Hromadná akce správce společnosti',
      fields: {
        uploadFileName: 'Název souboru',
        execStartDate: 'Zahájení provádění datum a čas',
        execEndDate: 'Konec provádění datum a čas',
        execStatus: 'Stav',
        uploadFile: 'Registrovaný soubor',
        resultFile: 'Výsledky registrace',
      },
      execStatus: {
        complete: 'Dokončeno',
        inprogress: 'Zpracování',
        error: 'Chyba',
      },
    },
    tasks: {
      name: 'Úkol',
      fields: {
        name: 'Název',
        status: 'Stav',
        description: 'Popis',
        projectSourceLayerId: 'Vrstva',
        retrofitIds: 'Cílový stroj',
        expiredDate: 'Datum expirace',
        createDate: 'Datum expirace',
        rectangle: 'Podporovaný rozsah',
        lastUpdated: 'Poslední aktualizace',
      },
      status: {
        error: 'Chyba',
        converting: 'Konverze',
        sending: 'Přenesení',
        sent: 'Přeneseno',
        failtosend: 'Převod se nezdařil',
      },
    },
    compactionMaterials: {
      name: 'Materiály',
      fields: {
        name: 'Název',
      },
    },
    weathers: {
      name: 'Počasí',
      fields: {
        name: 'Název',
      },
    },
    operators: {
      name: 'Operátoři',
      fields: {
        name: 'Název',
      },
    },
    compactionWorkAreas: {
      name: 'Kompakční pracovní oblasti',
      fields: {
        name: 'Názvy pracovních oblastí',
        blockSize: 'Velikost bloku',
        description: 'Popis',
      },
    },
    compactionLayers: {
      name: 'Kompakční vrstvy',
      fields: {
        name: 'Název',
        thickness: 'Tloušťka',
        passCount: 'Počet průchodů',
        compactionMaterialId: 'Materiál',
        inUse: 'Stav',
        description: 'Popis',
      },
      inUse: {
        true: 'využívá se',
        false: 'Nepoužitý',
      },
    },
    gnssSettings: {
      name: 'Nastavení Ntrip',
      fields: {
        registCorporationName: 'Zaregistrováno',
        ownerCorporationId: 'ID společnosti',
        ownerCorporationName: 'Název společnosti',
        name: 'Název',
        url: 'URL serveru',
        portnum: 'Port',
        userId: 'ID',
        password: 'Heslo',
        retrofitId: 'Cílový stroj',
        status: 'Stav',
        updateDate: 'Poslední aktualizace',
      },
      status: {
        waitforsend: 'Konverze',
        sending: 'Přenesení',
        sent: 'Přeneseno',
        failtosend: 'Převod se nezdařil',
      },
    },
    supportMode: {
      name: 'Režim podpory',
      fields: {
        enabled: 'Aktivovat podpůrný režim',
        corporationId: 'ID společnosti',
        corporationName: 'Název společnosti',
        siteId: 'ID staveniště',
        siteName: 'Staveniště',
        latitude: 'Zeměpisná šířka',
        longitude: 'Zeměpisná délka',
        workPeriodStartDate: 'Skutečné stavební období (datum zahájení)',
        workPeriodEndDate: 'Skutečné stavební období (datum ukončení)',
        photoUrl: 'URL obrázku webu',
        description: 'Popis',
      },
    },
    userNotices: {
      name: 'Oznámení',
      noticeType: {
        emergencyimportant: 'Mimořádný/Důležité',
        emergency: 'Mimořádný',
        important: 'Důležité',
      },
      fields: {
        noticeType: 'Důležitost',
        noticeTypes: 'Důležitost',
        noticeCategory: 'Typ oznámení',
        title: 'Titul',
        content: 'Obsah',
        publicationStartDate: 'Datum a čas zahájení (UTC)',
        publicationEndDate: 'Datum a čas ukončení(UTC)',
        file: 'Přílohy',
        language: 'Jazyk',
        emergency: 'Mimořádný',
        important: 'Důležité',
      },
    },
    firmwares: {
      name: 'Firmware',
      fields: {
        version: 'Verze',
        compatibilityLevel: 'Úroveň kompatibility',
        firmwareFile: 'Soubor firmwaru',
        firmwareFileCheckSum: 'Kontrolní součet firmwaru',
        firstInitializationFile: 'Inicializační soubor 1',
        firstInitializationFileCheckSum:
          'Kontrolní součet inicializačního souboru 1',
        secondInitializationFile: 'Inicializační soubor 2',
        secondInitializationFileCheckSum:
          'Kontrolní součet inicializačního souboru 2',
      },
    },
    bucketShares: {
      name: 'Sdílení vědra',
      fields: {
        fromBucketId: 'Z identifikátoru kýblu',
        corporationId: 'ID společnosti',
        name: 'Název',
      },
    },
    extensionarmShare: {
      name: 'Sdílení prodlouženého ramene',
      fields: {
        name: 'Název',
      },
    },
    retrofitCorporationLicenses: {
      name: 'LICENCE',
      fields: {
        licenseId: 'ID licence',
        licenseType: 'Typ',
        issuedAt: 'Datum vydání',
        expiresAt: 'Datum expirace',
        retrofitId: 'Stroje',
        comment: 'Popis',
        licenseStatus: 'Stav',
        licensePermission: 'LICENCE',
        productType: 'Název produktu',
      },
      licenseStatus: {
        unlicense: 'Nesděleno',
        disable: 'Neplatný',
        enable: 'Platný',
      },
    },
    retrofitNamePlateFiles: {
      fields: {
        corporationName: 'Název společnosti',
        namePlates: 'Fotografie',
      },
    },
    siteConfigures: {
      fields: {
        unitLength: 'Jednotka délky',
      },
    },
    geofences: {
      name: 'Geofence',
      fields: {
        type: 'Typ',
        name: 'Název',
        dimension: 'Rozměr',
        trigger: 'Spouštěč',
        valid: 'Platný',
        alertType: 'Typ výstrahy',
        startDate: 'Datum zahájení',
        endDate: 'Datum ukončení',
        transparency: 'Průhledný',
        elevation: 'Elevace',
        height: 'Výška',
        radius: 'Poloměr',
        lastUpdated: 'Poslední aktualizace',
        points: 'Podporovaný rozsah',
        thickness: 'Tloušťka',
      },
      type: {
        line: 'Linie',
        polygon: 'Polygon',
        circle: 'Prstenec',
        point: 'Bod',
        wall: 'Stěna',
      },
      dimension: {
        twodimension: '2D',
        threedimension: '3D',
      },
      valid: {
        true: 'Platný',
        false: 'Neplatný',
      },
      alertType: {
        attention: 'Pozor',
        notice: 'Upozornění',
        warning: 'Upozornění',
        caution: 'Pozor',
        danger: 'Nebezpečí',
      },
      transparency: {
        true: 'Průhledný',
        false: 'Neprůhledný',
      },
    },
    geofenceAlerts: {
      name: 'Upozornění',
      fields: {
        timeStamp: 'Datum a čas detekce',
        geofenceName: 'Název geofence',
        alertType: 'Typ výstrahy',
        collisionPoint: 'Bod srážky',
        retrofit: {
          machineInfoMachineName: 'Stroje',
        },
      },
      alertType: {
        attention: 'Pozor',
        notice: 'Upozornění',
        warning: 'Upozornění',
        caution: 'Pozor',
        danger: 'Nebezpečí',
      },
    },
    ntrips: {
      name: 'Řízení Ntrip',
      fields: {
        name: 'ID uživatele',
        ntripPass: 'Heslo',
        serial: 'Výrobní číslo',
        customerCorporationId: 'ID společnosti',
        customerCorporationName: 'Název společnosti',
        ntrip: 'Stav',
        numberOfExchanges: 'Počet výměn',
        id: 'ID',
        licenceId: 'ID licence',
        dateOfRegistration: 'Datum registrace',
        billingToCustomers: 'Fakturace zákazníků',
        paymentToKomatsu: 'Platba Komatsu',
        applicantId: 'ID žadatele',
        applicant: 'Žadatel',
        requestCorporationId: 'Požádejte o identifikátor společnosti',
        requestCorporationName: 'Požádejte o název společnosti',
        isUsed: 'Je používán',
      },
      ntrip: {
        publish: 'Vyplaceno',
        stop: 'Zastavit',
        delete: 'Smazáno',
      },
    },
    retrofitAlternateRegists: {
      name: 'Registrace proxy kontroleru',
      fields: {
        id: 'ID',
        retrofitId: 'retrofitId',
        fromCorporationId: 'z ID společnosti',
        fromCorporationName: 'Registrace proxy kontroleru',
        toCorporationId: 'Uživatele',
        toCorporationName: 'Název společnosti',
        isApproved: 'Stav',
        createDate: 'Datum žádosti',
        actualDate: 'Datum schválení',
        transferUsers: 'E-mail',
        email: 'E-mail',
        lastUpdated: 'Poslední aktualizace',
      },
      isApproved: {
        true: 'Schváleno',
        false: 'Nesouhlaseno',
      },
    },
    pendingApprovals: {
      name: 'Čekání na schválení',
      fields: {
        approvalType: 'Typ',
        requestedBy: 'Žadatelova společnost',
        lastUpdated: 'Poslední aktualizace',
      },
      approvalType: {
        assignment: 'Úkol',
        transfer: 'Převod',
        agent: 'Registrace proxy kontroleru',
      },
    },
    retrofitSendbacks: {
      name: 'Poslat zpět',
      fields: {
        fromRetrofitId: 'Z Retrofitu',
        toRetrofitId: 'Alternativní řadič',
        notificationEmailAddress: 'E-mail',
        siteId: 'Staveniště',
        corporationId: 'ID společnosti',
        corporationName: 'Společnost je používána',
        ntripId: 'Informace o Ntrip',
        isComplete: 'Poslat zpět úspěšně',
      },
    },
    siteBuriedStructures: {
      fields: {
        epsgCode: 'Epsg',
      },
    },
    scGroupwares: {
      fields: {
        name: 'Name',
        createdBy: {
          user: {
            profile: {
              name: 'Author',
            },
          },
        },
        updatedAt: 'Last Modified',
        updatedby: {
          user: {
            profile: {
              name: 'Last Modified By',
            },
          },
        },
        files: {
          totalSize: 'Size',
        },
        file: {
          size: 'Size',
        },
      },
      nodeType: {
        bucket: 'bucket',
        directory: 'folder',
        preiviousFolder: 'preiviousFolder',
        file: 'file',
      },
    },
  },
};

export default message;
