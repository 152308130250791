import accounts from './accounts';
import asbuilts from './asbuilts';
import asbuiltTimes from './asbuiltTimes';
import assignments from './assignments';
import buckets from './buckets';
import bucketShares from './bucketShares';
import compactionLayers from './compactionLayers';
import compactionMaterials from './compactionMaterials';
import compactionWorkAreas from './compactionWorkAreas';
import corporations from './corporations';
import customers from './customers';
import datums from './datums';
import extensionarms from './extensionarms';
import firmwares from './firmwares';
import geofenceAlerts from './geofenceAlerts';
import geofences from './geofences';
import geoids from './geoids';
import gnssSettings from './gnssSettings';
import landlogapps from './landlogapps';
import licenseAgreements from './licenseAgreements';
import licenses from './licenses';
import ntrips from './ntrips';
import ntripsUnusedTotal from './ntripsUnusedTotal';
import operators from './operator';
import panelstatus from './panelstatus';
import pointavailability from './pointavailability';
import projections from './projections';
import projects from './projects';
import regions from './regions';
import retrofitAccuracies from './retrofitAccuracies';
import retrofitAdminCorporationBulkActions from './retrofitAdminCorporationBulkActions';
import retrofitAlternateRegists from './retrofitAlternateRegists';
import retrofitBasicSettings from './retrofitBasicSettings';
import retrofitBulkActions from './retrofitBulkActions';
import retrofitByTasks from './retrofitByTasks';
import retrofitCalibs from './retrofitCalibs';
import retrofitConfigs from './retrofitConfigs';
import retrofitDistributors from './retrofitDistributors';
import retrofitRegions from './retrofitRegions';
import retrofitErrors from './retrofitErrors';
import retrofitKitInfos from './retrofitKitInfos';
import retrofitManageCorporations from './retrofitManageCorporations';
import retrofitOwnerRegistCorporations from './retrofitOwnerRegistCorporations';
import retrofitOwnerChangeCorporations from './retrofitOwnerChangeCorporations';
import retrofitRentals from './retrofitRentals';
import retrofits from './retrofits';
import retrofitShareFiles from './retrofitShareFiles';
import retrofitSendbacks from './retrofitSendbacks';
import retrofitToposurveys from './retrofitToposurveys';
import retrofitCorporationLicenses from './retrofitCorporationLicenses';
import scGroupwares from './scGroupwares';
import scGroupwareBuckets from './scGroupwareBuckets';
import scGroupwareFiles from './scGroupwareFiles';
import scGroupwareFolders from './scGroupwareFolders';
import siteBuriedStructures from './siteBuriedStructures';
import siteConfigures from './siteConfigures';
import sites from './sites';
import startremote from './startremote';
import tasks from './tasks';
import termsConditionDocuments from './termsConditionDocuments';
import termsConditions from './termsConditions';
import userConfigures from './userConfigures';
import userNotices from './userNotices';
import userNoticeStatus from './userNoticeStatus';
import userPermissions from './userPermissions';
import users from './users';
import weathers from './weathers';

export default {
  accounts,
  asbuilts,
  asbuiltTimes,
  assignments,
  buckets,
  bucketShares,
  compactionLayers,
  compactionMaterials,
  compactionWorkAreas,
  corporations,
  datums,
  extensionarms,
  firmwares,
  geofenceAlerts,
  geofences,
  geoids,
  gnssSettings,
  landlogapps,
  licenseAgreements,
  licenses,
  ntrips,
  ntripsUnusedTotal,
  operators,
  panelstatus,
  pointavailability,
  projections,
  projects,
  regions,
  retrofitAccuracies,
  retrofitAdminCorporationBulkActions,
  retrofitAlternateRegists,
  retrofitBasicSettings,
  retrofitBulkActions,
  retrofitByTasks,
  retrofitCalibs,
  retrofitConfigs,
  retrofitDistributors,
  retrofitRegions,
  retrofitErrors,
  retrofitKitInfos,
  retrofitManageCorporations,
  retrofitOwnerRegistCorporations,
  retrofitOwnerChangeCorporations,
  retrofitRentals,
  retrofits,
  retrofitShareFiles,
  retrofitSendbacks,
  retrofitToposurveys,
  retrofitCorporationLicenses,
  scGroupwares,
  scGroupwareBuckets,
  scGroupwareFiles,
  scGroupwareFolders,
  siteBuriedStructures,
  siteConfigures,
  sites,
  startremote,
  tasks,
  termsConditionDocuments,
  termsConditions,
  userConfigures,
  userNotices,
  userNoticeStatus,
  userPermissions,
  users,
  weathers,
  customers,
};
