import { SCGroupwareFolderData } from '../../types';

const scGroupwareFolders: SCGroupwareFolderData[] = [
  {
    id: '2ol9gklb0naeng3hp33e',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/AsBuilt/',
    name: 'AsBuilt',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: '7s7sr9l8vgg4roo66sjr',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Design/',
    name: 'Design',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: '50kra2gd506enickn6pj',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Localization/',
    name: 'Localization',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: '0ff4tm9skcqtug71jrph',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/MachineTransmission/',
    name: 'MachineTransmission',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: 'hlhu8ek07ikfhu8h11ia',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Ortho image/',
    name: 'Ortho image',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: 'lbrgioq9itg3g52ch8q6',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Output/',
    name: 'Output',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: '0rack05f8hc9r6mtp19o',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Preconstruction/',
    name: 'Preconstruction',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: 'i2qdceuk3fb14dp8cmhr',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Shared/',
    name: 'Shared',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: '3ee94ffftc9lquah7ti4',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Survey/',
    name: 'Survey',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: 'e9c4u1ol13e1s6ukssgn',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Untitled folder/',
    name: 'Untitled folder',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
  {
    id: 'rl9p46mehcb6mvurbpap',
    parentNodeId: null,
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: true,
    key: '/Vector overlay/',
    name: 'Vector overlay',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
  },
];

export default scGroupwareFolders;
