import { SCGroupwareFileData } from '../../types';

const scGroupwareFiles: SCGroupwareFileData[] = [
  {
    id: 'kj2nvq3olln5v9fhbqp3',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/MIHAMA2nd_20161111.tp3',
    name: 'MIHAMA2nd_20161111.tp3',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '4433520',
      checksum: 'uYEYE2f1/WLMujUQ3Fu7xw==',
    },
  },
  {
    id: 'vhjb6pp1vn1k14rgb1hm',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/MIHAMA2nd_20161111.xml',
    name: 'MIHAMA2nd_20161111.xml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '36910',
      checksum: 'BJdKwJ6UuSrT1qccYtS24w==',
    },
  },
  {
    id: 'qh68bro3ivng50jkenka',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/README.md',
    name: 'README.md',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '408',
      checksum: 'sdEUO4osURtaXXPZ30uoIQ==',
    },
  },
  {
    id: 'ps8h58sot33fnaul62f9',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/alignment.xml',
    name: 'alignment.xml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '44223',
      checksum: 'cueKW5br4B8Ynzq+5+dnYA==',
    },
  },
  {
    id: '9lts71cf62232r1j2q2a',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/building.dxf',
    name: 'building.dxf',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
      },
    },
    file: {
      size: '3640418',
      checksum: 'ZTqEIODm5YcphfzXCGkOvQ==',
    },
  },
  {
    id: 'b1hii741qnqdb2m535r3',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/mihama.geojson',
    name: 'mihama.geojson',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '941',
      checksum: '3knHBdi2qAH3DKUQqX8F2g==',
    },
  },
  {
    id: '5fmka6bh85ka74p5m9kq',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/mihama_asbuilt.txt',
    name: 'mihama_asbuilt.txt',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '49553',
      checksum: 'RXuz1wRnUaeBKA23KucVRg==',
    },
  },
  {
    id: 'n9635dhsual82rnradqr',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/pnpm-lock.yaml',
    name: 'pnpm-lock.yaml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '721989',
      checksum: '4zo4ZBBkjO34T51+MuEuGQ==',
    },
  },
  {
    id: '96tbd03pe69v6vou88q6',
    parentNodeId: 'e9c4u1ol13e1s6ukssgn',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    isDirectory: false,
    key: '/Untitled folder/pnpm-workspace.yaml',
    name: 'pnpm-workspace.yaml',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
      },
    },
    file: {
      size: '27',
      checksum: 'i/74GgJ3gzt0iKie/lNJxw==',
    },
  },
];

export default scGroupwareFiles;
