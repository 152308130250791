import React, { useMemo } from 'react';
import { useLocation } from 'react-router';
import {
  Breadcrumbs,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useTranslate } from 'react-admin';
import { SCGroupwareData } from '../../../dataProvider';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      fontSize: 16,
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  }),
);

export interface FilePickerBreadcrumbProps {
  breadcrumbs?: SCGroupwareData[];
  customPaths?: string[];
  onClickBreadcumbs: (value: SCGroupwareData, index: number) => void;
}

const FilePickerBreadcrumb: React.FC<FilePickerBreadcrumbProps> = ({
  breadcrumbs,
  customPaths,
  onClickBreadcumbs,
}) => {
  const translate = useTranslate();
  const classes = useStyles();
  // パンくずリストに表示するパスと名前のリストを作成して保存
  const { pathname } = useLocation();

  const pathmap = useMemo(() => {
    if (!breadcrumbs || !pathname) return [];
    if (customPaths && customPaths.length !== breadcrumbs.length)
      throw new Error('Invalid Params customPaths');
    const paths = pathname.split('/').filter(x => x);
    const pathName = breadcrumbs.map(it => it.name);
    const map = pathName.map((value, index) => {
      const path = customPaths
        ? customPaths[index]
        : `/${paths.slice(0, index + 1).join('/')}`;
      return { path, ...breadcrumbs[index] };
    });
    // 空文字列を指定されている場合、パンくずリストに出さない
    return map.filter(({ name }) => name);
  }, [breadcrumbs, customPaths, pathname]);

  return (
    <Breadcrumbs aria-label="breadcrumb">
      {pathmap.map((path, index) => (
        <Typography
          className={classes.content}
          color="textPrimary"
          key={index}
          onClick={() => onClickBreadcumbs(path, index)}
        >
          {translate(path.name, { _: path.name })}
        </Typography>
      ))}
    </Breadcrumbs>
  );
};

export default FilePickerBreadcrumb;
