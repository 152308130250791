import React from 'react';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Checkbox from '@material-ui/core/Checkbox';
import { createStyles, makeStyles } from '@material-ui/core';
import { DatagridRow } from '../../bases';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      fontSize: 16,
      cursor: 'pointer',
    },
  }),
);

const registrableFileType = [
  'xml',
  'tp3',
  'tn3',
  'ln3',
  'gc3',
  'dxf',
  'ttm',
  'svd',
  'dsz',
  'cal',
  'svl',
];

interface Props {
  loading: boolean;
  onClickList: (record: any) => void;
  onClickCheckbox: (record: any, selected?: boolean) => void;
}

type FilePickerDatagridRowProps = React.ComponentProps<typeof DatagridRow>;

const FilePickerDatagridRow: React.FC<FilePickerDatagridRowProps & Props> = ({
  record,
  resource,
  id,
  onToggleItem,
  children,
  selected,
  basePath,
  classes = {},
  loading,
  onClickList,
  onClickCheckbox,
}) => {
  const styleClass = useStyles();
  return (
    <TableRow
      key={id}
      style={{
        opacity:
          record &&
          (record.nodeType === 'bucket' ||
            record.nodeType === 'directory' ||
            (record.nodeType === 'file' &&
              registrableFileType.includes(
                record.name
                  .slice(record.name.lastIndexOf('.') + 1)
                  .toLowerCase(),
              )))
            ? 1
            : 0.5,
      }}
    >
      {record &&
      record.nodeType === 'file' &&
      registrableFileType.includes(
        record.name.slice(record.name.lastIndexOf('.') + 1).toLowerCase(),
      ) ? (
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            className={`select-item ${classes.checkbox}`}
            checked={selected}
            onClick={(e: any) => {
              onClickCheckbox(record, selected);
              // eslint-disable-next-line no-unused-expressions
              onToggleItem && onToggleItem(id, e);
            }}
          />
        </TableCell>
      ) : (
        <td />
      )}
      {React.Children.map(children, field => {
        if (React.isValidElement(field)) {
          return (
            <TableCell
              key={id}
              onClick={() => {
                if (loading) return;
                onClickList(record);
              }}
              className={styleClass.content}
            >
              {React.cloneElement(field, {
                record,
                basePath,
                resource,
              })}
            </TableCell>
          );
        }
        if (typeof field === 'string') {
          return field;
        }
        return null;
      })}
    </TableRow>
  );
};

FilePickerDatagridRow.displayName = 'FilePickerDatagridRow';

export default FilePickerDatagridRow;
