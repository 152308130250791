import { fetchUtils } from 'react-admin';
import { config } from '../utils';
import { getOption } from '../dataProvider';

export default async (siteId: string): Promise<{ resourceOwnerId: string }> => {
  const { fileStorageUri, useFake } = config.api.landlog;
  if (useFake) {
    return { resourceOwnerId: '44268432-8882-429e-bea5-f5c519087267' };
  }
  const uri = `${fileStorageUri}/v2/resource_owners?type=Site&group_id=${siteId}`;
  const { json } = await fetchUtils.fetchJson(uri, await getOption());
  const resourceOwnerId = json.resource_owners[0]?.id;
  return { resourceOwnerId };
};
