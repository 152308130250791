import { fetchUtils } from 'react-admin';
import { encode } from 'querystring';
import { config } from '../../utils';
import { LandLogListFileStorageProps, SCGroupwareNodes, getOption } from '.';

interface ListResponse {
  nodes: SCGroupwareNodes[];
  total: number;
}

export default async (
  props: LandLogListFileStorageProps,
  bucketId: string,
): Promise<ListResponse> => {
  const params = encode({ ...props });
  const uri = `${config.api.landlog.fileStorageUri}/v2/file_storage/buckets/${bucketId}/nodes?${params}`;
  const { json } = await fetchUtils.fetchJson(`${uri}`, await getOption());
  return json;
};
