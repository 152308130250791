import simpleRestProvider from 'ra-data-simple-rest';
import { CreateParams, DeleteManyParams, Identifier } from 'react-admin';
import { config } from '../../utils';
import { getHttpClient } from '.';

export default (embed?: string[], attachAccessToken?: boolean) => {
  const httpClient = getHttpClient(embed, attachAccessToken);
  const apiUrl = config.api.admin.apiUri;
  return {
    ...simpleRestProvider(apiUrl, httpClient),
    // Simple RestのCreateはID以外のレスポンスをサーバーから取得しないので上書きする
    create: (resource: string, params: CreateParams<any>) =>
      httpClient(`${apiUrl}/${resource}`, {
        method: 'POST',
        body: JSON.stringify(params.data),
      }).then(({ json }) => ({
        data: json,
      })),
    // Simple RestのDeleteManyはサーバがIDを返す想定だが、現在のAPIは返さないのでエラーになる。
    // 以下の関数で上書きして対応。
    deleteMany: (resource: string, params: DeleteManyParams) =>
      Promise.all(
        params.ids.map((id: Identifier) =>
          httpClient(`${apiUrl}/${resource}/${id}`, {
            method: 'DELETE',
          }),
        ),
      ).then(() => ({ data: params.ids })),
  };
};
