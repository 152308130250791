/**
 * 文字列を 日付型 に変換する
 */
export default (time: string) => {
  // Convert the large epoch time to milliseconds by dividing by 1,000,000
  const milliseconds = Number(time) / 1000000;
  const date = new Date(milliseconds);

  // Convert to ISO format
  const convertedTime = date.toISOString();
  return convertedTime;
};
