import { fetchUtils } from 'react-admin';
import { stringify } from 'querystring';
import { getPermissions } from '../../auth';

const urlWithEmbed = (url: string, embed: string[]) => {
  const param = stringify({ embed: JSON.stringify(embed) });
  const sep = url.includes('?') ? '&' : '?';
  return url + sep + param;
};
export default (embed?: string[], attachAccessToken?: boolean) =>
  async (url: any, options?: fetchUtils.Options) => {
    const {
      token: { idToken, accessToken },
      userInfo: { name },
    } = await getPermissions();
    const headers = attachAccessToken
      ? new Headers({
          Accept: 'application/json',
          Authorization: idToken,
          From: encodeURI(name),
          'X-Access-Token': accessToken,
        })
      : new Headers({
          Accept: 'application/json',
          Authorization: idToken,
          From: encodeURI(name),
        });
    return fetchUtils.fetchJson(embed ? urlWithEmbed(url, embed) : url, {
      ...options,
      headers,
    });
  };
