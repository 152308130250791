import {
  AdminApiConverter,
  ApiFilter,
  File,
  getRestDataProvider,
  ProjectSourceFile,
} from '..';
import { getDataProvider, SiteData, FileField, ProjectsData } from '../..';

const fileType: { [key: string]: File['blobFileType'] } = {
  xml: 'ProjectLandXML',
  dxf: 'ProjectDXF',
  gc3: 'ProjectGC3',
  tp3: 'ProjectTP3',
  tn3: 'ProjectTN3',
  ttm: 'ProjectTTM',
  svd: 'ProjectSVD',
  dsz: 'ProjectDSZ',
  svl: 'ProjectSVL',
  cal: 'ProjectCAL',
  ln3: 'ProjectLN3',
};

const createProjectFile = async ({
  files,
  corporationId,
  siteId,
}: {
  files: FileField[];
  corporationId: string;
  siteId: string;
}): Promise<FileField[]> =>
  Promise.all(
    files.map(async file => {
      const { rawFile, title, fileInfo, projectSourceFileId } = file;
      // 既に登録済みファイルの場合
      if (projectSourceFileId) return file;
      // SC Groupwareの場合
      if (!rawFile && fileInfo) {
        const extention = title.slice(title.lastIndexOf('.') + 1).toLowerCase();
        const blobFileType = fileType[extention];
        const isAttchAccessToken = true;
        const dataProvider = getRestDataProvider(undefined, isAttchAccessToken);
        const fileData: File = {
          blobFileType,
          name: title,
        };
        const fileResult = await dataProvider.create('files', {
          data: fileData,
        });
        const { id: blobFileId, url } = fileResult.data as File;
        if (!blobFileId || !url) throw Error('Invalid file data');

        await dataProvider.create('scGroupwareFiles', {
          data: {
            blobFileId,
            bucketId: fileInfo.bucketId,
            nodeId: fileInfo.id,
          },
        });

        // プロジェクト設計ファイルの追加
        const psfData: ProjectSourceFile = {
          corporationId,
          siteId,
          blobFileId,
        };
        const psfResult = await dataProvider.create('projectSourceFiles', {
          data: psfData,
        });
        const { id: newId } = psfResult.data as ProjectSourceFile;
        if (!newId) throw Error('Invalid ProjectSourceFileId');
        return { ...file, id: blobFileId, projectSourceFileId: newId };
      }
      // ローカル環境からアップロードしたファイルの場合
      if (!rawFile) throw new Error(`RawFile dosen't supplied for ${title}`);
      const { name } = rawFile;
      const extention = name.slice(name.lastIndexOf('.') + 1).toLowerCase();
      const blobFileType = fileType[extention];

      if (!blobFileType) throw new Error(`Unkown file extention: ${name}`);
      // ファイルリソースの追加
      const dataProvider = getRestDataProvider();
      const fileData: File = {
        blobFileType,
        name: title,
      };
      const fileResult = await dataProvider.create('files', { data: fileData });
      const { id: blobFileId, url } = fileResult.data as File;
      if (!blobFileId || !url) throw Error('Invalid file data');

      // ファイルのアップロード
      await fetch(url, {
        method: 'PUT',
        body: rawFile,
        headers: { 'Content-Type': 'application/octet-stream' },
      });

      // プロジェクト設計ファイルの追加
      const psfData: ProjectSourceFile = {
        corporationId,
        siteId,
        blobFileId,
      };
      const psfResult = await dataProvider.create('projectSourceFiles', {
        data: psfData,
      });
      const { id: newId } = psfResult.data as ProjectSourceFile;
      if (!newId) throw Error('Invalid ProjectSourceFileId');
      return { ...file, id: blobFileId, projectSourceFileId: newId };
    }),
  );

const converter: AdminApiConverter<'projects', 'projects'> = {
  resource: 'projects',
  defaultSort: { field: 'lastUpdated', order: 'DESC' },
  filterToApi: filter => {
    const apiFilter: ApiFilter<'projects'> = {};
    if ('q' in filter) apiFilter.q = filter.q;
    if ('id' in filter) apiFilter.id = filter.id;
    if ('siteId' in filter) apiFilter.siteId = filter.siteId;
    if ('name' in filter) apiFilter.name = filter.name;
    if ('coordinate' in filter) apiFilter.coordinateType = filter.coordinate;
    if ('regionId' in filter) apiFilter.regionId = filter.regionId;
    if ('projectionId' in filter) apiFilter.projectionId = filter.projectionId;
    if ('datumId' in filter) apiFilter.datumId = filter.datumId;
    if ('geoidId' in filter) apiFilter.geoidId = filter.geoidId;
    if ('retrofitId' in filter) apiFilter.retrofitIds = filter.retrofitId;
    if ('version' in filter) apiFilter.version = filter.version;
    if ('latestProjectVersionId' in filter)
      apiFilter.latestProjectVersionId = filter.latestProjectVersionId;
    if ('status' in filter) apiFilter.projectDataStatus = filter.status;
    if ('lastUpdated' in filter) apiFilter.updateDate = filter.lastUpdated;
    if ('errorInfo' in filter) apiFilter.errorInfo = filter.errorInfo;
    if ('unitLength' in filter) apiFilter.unitLength = filter.unitLength;
    return apiFilter;
  },
  sortFieldToApi: field => {
    if (field === 'coordinate') return 'coordinateType';
    if (field === 'retrofitId') return 'id';
    if (field === 'status') return 'projectDataStatus';
    if (field === 'lastUpdated') return 'updateDate';
    if (field === 'file') return 'id';
    return field;
  },
  recordsFromApi: async records => {
    if (!records.length) return [];

    // 対象のProjectSourceFileを全件取得
    const psfIds = records.map(record => record.projectSourceFileIds).flat();
    const dataProvider = getRestDataProvider(['blobFile']);
    const psfResult = await dataProvider.getMany('projectSourceFiles', {
      ids: psfIds,
    });
    const allPsf = psfResult.data as ProjectSourceFile[];
    return records.map(
      (record): ProjectsData => ({
        id: record.id || 0,
        siteId: record.siteId || '',
        name: record.name || '',
        coordinate: record.coordinateType || '',
        regionId: record.regionId || 0,
        projectionId: record.projectionId || 0,
        datumId: record.datumId || 0,
        geoidId: record.geoidId || 0,
        retrofitId: record.retrofitIds || [],
        version: record.version || 0,
        latestProjectVersionId: record.latestProjectVersionId || 0,
        status: record.projectDataStatus || 'Error',
        lastUpdated: record.updateDate || '',
        file: record.projectSourceFileIds.map((psfId): FileField => {
          const target = allPsf.find(psf => psf.id === psfId);
          if (!target || !target.blobFile) {
            throw new Error(`Not exists ProjectSourceFileId: ${psfId}`);
          }
          const { id, name, url } = target.blobFile;
          if (!id) {
            throw new Error(`Invalid File data ProjectSourceFileId: ${psfId}`);
          }
          return {
            id,
            src: url || '',
            title: name || '',
            projectSourceFileId: psfId,
          };
        }),
        projectSourceLayers: record.projectSourceLayers || [],
        projectSourceLineWorks: record.projectSourceLineWorks || [],
        projectLocation: record.projectLocation,
        errorInfo: record.errorInfo || [],
        resultInfo: {
          errorFileNames: record.resultInfo?.errorFileNames || [],
          errorLayerNames: record.resultInfo?.errorLayerNames || [],
        },
        projectZipFileId: record.projectZipFileId,
        projectZipFile: {
          id: record.projectZipFileId || '',
          src: record.projectZipFile?.url || '',
          title: record.projectZipFile?.name || '',
        },
        unitLength: record.unitLength,
      }),
    );
  },
  prepareParam: async record => {
    // 企業IDを取得
    const dataProvider = getDataProvider();
    const { data: siteData } = await dataProvider.getOne('sites', {
      id: record.siteId,
    });
    const { corporationId } = siteData as SiteData;
    const files = await createProjectFile({
      files: record.file,
      corporationId,
      siteId: record.siteId,
    });

    return { ...record, corporationId, file: files };
  },
  recordToApi: record => ({
    corporationId: record.corporationId as string,
    siteId: record.siteId,
    name: record.name,
    coordinateType: record.coordinate,
    regionId: record.regionId,
    projectionId: record.projectionId,
    datumId: record.datumId,
    geoidId: record.geoidId,
    retrofitIds: record.retrofitId,
    projectSourceFileIds: record.file.map(
      f => f.projectSourceFileId,
    ) as number[],
    unitLength: record.unitLength,
  }),
  embed: ['ProjectZipFile'],
};

export default converter;
