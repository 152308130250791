import { SCGroupwareNodes, SCGroupwareData } from '..';
import { convertEpochTime, convertFileSize } from '../../utils';

export default (fileStorage: SCGroupwareNodes): SCGroupwareData => ({
  id: fileStorage.id,
  parentNodeId: fileStorage.parent_node_id ?? '',
  nodeType: fileStorage.node_type,
  createdAt: convertEpochTime(fileStorage.created_at),
  updatedAt: convertEpochTime(fileStorage.updated_at),
  key: fileStorage.key,
  name: fileStorage.name,
  isDirectory: fileStorage.is_directory,
  createdBy: {
    user: fileStorage.created_by.user
      ? {
          id: fileStorage.created_by.user.id,
          profile: fileStorage.created_by.user.profile
            ? {
                name: fileStorage.created_by.user.profile.name,
              }
            : { name: '' },
        }
      : { id: '', profile: { name: '' } },
    client: fileStorage.created_by.client
      ? {
          clientId: fileStorage.created_by.client.client_id,
          isDevice: fileStorage.created_by.client.is_device,
        }
      : { clientId: '', isDevice: false }, // clientがnullの状態で渡ってくることがあるか確認する
  },
  updatedBy: {
    user: fileStorage.updated_by.user
      ? {
          id: fileStorage.updated_by.user.id,
          profile: fileStorage.updated_by.user.profile
            ? {
                name: fileStorage.updated_by.user.profile.name,
              }
            : { name: '' },
        }
      : { id: '', profile: { name: '' } },
    client: fileStorage.updated_by.user
      ? {
          clientId: fileStorage.updated_by.client.client_id,
          isDevice: fileStorage.updated_by.client.is_device,
        }
      : { clientId: '', isDevice: false }, // clientがnullの状態で渡ってくることがあるか確認する
  },
  file: fileStorage.file
    ? {
        size: convertFileSize(fileStorage.file.size),
        checksum: fileStorage.file.checksum,
      }
    : {
        size: '-',
        checksum: '',
      },
});
