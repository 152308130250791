import React, { FC, useCallback, useState } from 'react';
import { Dialog, makeStyles, createStyles } from '@material-ui/core';
import { TextField, useTranslate, useUnselectAll } from 'react-admin';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { colors } from '../../../theme';
import {
  FilePickerBreadcrumb,
  FilePickerDatagridRow,
  FilePickerSaveButton,
} from '.';
import {
  CustomList,
  Datagrid,
  IconField,
  DatagridBody,
  DateField,
  Typography,
  Filter,
  SiteSearchInput,
} from '../..';
import { useResource } from '../../../hooks';
import { SCGroupwareData } from '../../../dataProvider';
import {
  buttonDisabledOpacity,
  buttonWidth,
  buttonHeight,
} from '../../../theme/define';

const useStyles = makeStyles(() =>
  createStyles({
    dialog: {
      marginLeft: 60,
      '& > div > div': {
        minWidth: 960,
        height: 580,
      },
    },
    header: {
      padding: '16px 24px 8px 24px',
      '& > h2 > p': {
        fontSize: 20,
      },
    },
    root: {
      padding: '0px 24px 0px 24px',
    },
    table: {
      '& > div:nth-child(2)': {
        overflow: 'auto',
        height: 290,
      },
      '& > div:nth-child(3)': {
        marginTop: 8,
        marginBottom: 4,
        paddingTop: 8,
        paddingRight: 12,
      },
      '& .MuiTable-root': {
        '& .MuiTableHead-root': {
          '& .MuiTableRow-root': {
            '& .MuiTableCell-root': {
              paddingTop: '6px',
              paddingBottom: '6px',
              boxSizing: 'content-box',
            },
            // チェックボックス
            '& .MuiTableCell-root:nth-child(1)': {
              paddingRight: '16px',
              paddingLeft: '15px',
            },
            // アイコン
            '& .MuiTableCell-root:nth-child(2)': {
              paddingRight: '15px',
              paddingLeft: '15px',
            },
            // 名称
            '& .MuiTableCell-root:nth-child(3)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 300,
            },
            // 作成者
            '& .MuiTableCell-root:nth-child(4)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 120,
            },
            // 最新更新日時
            '& .MuiTableCell-root:nth-child(5)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 140,
            },
            // 最終更新者
            '& .MuiTableCell-root:nth-child(6)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 120,
            },
            // サイズ
            '& .MuiTableCell-root:nth-child(7)': {
              paddingRight: '15px',
              paddingLeft: '15px',
              width: 90,
            },
          },
        },
      },
    },
    footer: {
      paddingLeft: 24,
      paddingBottom: 8,
      height: 56,
      overflow: 'auto',
      scrollbarWidth: 'none',
    },
    button: {
      background: colors.button.save,
      minWidth: buttonWidth,
      minHeight: buttonHeight,
      '&:hover': {
        backgroundColor: colors.button.saveHover,
      },
      '&.Mui-disabled': {
        backgroundColor: colors.button.saveDisabled,
        '& svg': {
          opacity: buttonDisabledOpacity,
        },
      },
      '& span': {
        padding: 0,
      },
    },
    checkBox: {
      marginTop: 14,
      marginRight: 14,
    },
    indent: {
      width: 38,
    },
  }),
);

interface Filter<T> {
  [key: string]: T;
}

const ListFilter: React.FC<any> = props => {
  if (props.isSearchClear) {
    props.setFilters({ q: '' }, { q: '' }); // SearchInput の入力をクリア
    props.setIsSearchClear(false); // SearchInput クリアフラグを解除
  }
  return (
    <Filter {...props}>
      <SiteSearchInput source="q" alwaysOn />
    </Filter>
  );
};

interface Props {
  open: boolean;
  onClose: () => void;
  loading: boolean;
  resourceOwnerId: string;
  handleAddSCGroupware: (record: any) => void;
}

const FilePickerDialog: FC<Props> = ({
  open,
  onClose,
  loading,
  resourceOwnerId,
  handleAddSCGroupware,
}) => {
  const resource = useResource('scGroupwares');
  const classes = useStyles();
  const translate = useTranslate();
  const unselectAll = useUnselectAll(resource);

  const [path, setPath] = useState<SCGroupwareData[]>([]);
  const breadcrumbs: SCGroupwareData[] = [
    {
      id: '1',
      name: translate('admin.dialog.filePicker.breadcrumbs'),
      nodeType: 'bucket',
      createdBy: {
        user: {
          id: '',
          profile: {
            name: '',
          },
        },
        client: {
          clientId: '',
          isDevice: false,
        },
      },
      updatedBy: {
        user: {
          id: '',
          profile: {
            name: '',
          },
        },
        client: {
          clientId: '',
          isDevice: false,
        },
      },
      createdAt: '',
      updatedAt: '',
    },
    ...path,
  ];
  const defaultFilter = { resourceOwnerId };
  const [filter, setFilter] =
    useState<Filter<string | number | boolean | undefined | null>>(
      defaultFilter,
    );
  const [isSearchClear, setIsSearchClear] = useState(false);
  const [bucketId, setBucketId] = useState<string>();
  const [selectedRecords, setSelectedRecords] = useState<SCGroupwareData[]>([]);
  const [currentDirectory, setCurrentDirectory] = useState<'bucket' | 'node'>(
    'bucket',
  );

  const onClickPreviousFolder = () => {
    const returnPath = breadcrumbs[breadcrumbs.length - 2];
    // 戻り先が 初期画面
    if (returnPath.id === '1') {
      setBucketId('');
      setFilter({ resourceOwnerId, bucketId: null, parentNodeId: null });
      setCurrentDirectory('bucket');
      setSelectedRecords([]);
      path.pop();
    }
    // 戻り先が Bucket配下
    if (returnPath.resourceOwnerId) {
      setFilter({ bucketId: returnPath.id, parentNodeId: null });
      setCurrentDirectory('bucket');
      setSelectedRecords([]);
      path.pop();
    }
    // 戻り先が Folder配下
    else if (returnPath.nodeType === 'directory') {
      setFilter({
        bucketId: returnPath.bucketId,
        parentNodeId: returnPath.id,
      });
      setCurrentDirectory('node');
      setSelectedRecords([]);
      path.pop();
    }
  };

  // チェックボックス クリック時
  const onClickCheckbox = useCallback(
    (record: SCGroupwareData, selected?: boolean) => {
      if (!selected) {
        // チェックボックスが選択された場合、レコードを追加
        setSelectedRecords(prevSelected => [...prevSelected, record]);
      } else {
        // チェックボックスが解除された場合、レコードを削除
        setSelectedRecords(prevSelected =>
          prevSelected.filter(item => item.id !== record.id),
        );
      }
    },
    [setSelectedRecords],
  );

  // レコード クリック時
  const onClickList = (record: SCGroupwareData) => {
    if (!record) return;
    // previous folder clicked
    if (record.nodeType === 'previousFolder') {
      onClickPreviousFolder();
      setSelectedRecords([]);
      setIsSearchClear(true);
    }
    // bucket clicked
    if (
      record.resourceOwnerId &&
      record.id !== breadcrumbs[breadcrumbs.length - 1].id
    ) {
      setBucketId(record.id);
      setFilter({ bucketId: record.id, parentNodeId: null });
      setCurrentDirectory('node');
      setPath([...path, record]);
      setSelectedRecords([]);
      setIsSearchClear(true);
    }
    // folder clicked
    else if (
      record.nodeType === 'directory' &&
      record.id !== breadcrumbs[breadcrumbs.length - 1].id
    ) {
      setFilter({ bucketId, parentNodeId: record.id });
      setCurrentDirectory('node');
      setPath([...path, record]);
      setSelectedRecords([]);
      setIsSearchClear(true);
    }
    // file clicked
    else {
      console.log('File Clicked', record);
      setIsSearchClear(false);
    }
  };

  // ダイアログを閉じる・レコード登録時に使用
  const handleClose = useCallback(() => {
    onClose();
    setFilter({ resourceOwnerId });
    setCurrentDirectory('bucket');
    setPath([]);
    setSelectedRecords([]);
  }, [onClose, resourceOwnerId]);

  const onClickBreadcumbs = useCallback(
    (value: SCGroupwareData, index: number) => {
      // 戻り先が 初期画面
      if (value.id === '1') {
        setFilter({ resourceOwnerId, bucketId: null, parentNodeId: null });
        setCurrentDirectory('bucket');
        setPath([]);
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
      // 戻り先が Bucket配下
      if (index !== path.length && value.resourceOwnerId) {
        setFilter({ bucketId: value.id, parentNodeId: null });
        setCurrentDirectory('node');
        path.splice(index, path.length - index);
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
      // 戻り先が Folder配下
      else if (index !== path.length && value.nodeType === 'directory') {
        setFilter({
          bucketId,
          parentNodeId: value.id,
        });
        setCurrentDirectory('node');
        path.splice(index, path.length - index);
        unselectAll(resource);
        setSelectedRecords([]);
        setIsSearchClear(true);
      }
    },
    [resource, path, unselectAll, resourceOwnerId, bucketId],
  );

  return (
    <Dialog
      className={classes.dialog}
      open={open}
      onClose={handleClose}
      data-testid="file_picker_dialog"
    >
      {open ? (
        <>
          <MuiDialogTitle className={classes.header}>
            <Typography>
              {translate('admin.dialog.filePicker.title')}
            </Typography>
            <FilePickerBreadcrumb
              breadcrumbs={breadcrumbs}
              onClickBreadcumbs={onClickBreadcumbs}
            />
          </MuiDialogTitle>
          <MuiDialogContent className={classes.root}>
            <CustomList
              resource={resource}
              className={classes.table}
              bulkActionButtons={
                <FilePickerSaveButton
                  onClose={handleClose}
                  handleAddSCGroupware={handleAddSCGroupware}
                  bucketId={bucketId}
                  selectedRecords={selectedRecords}
                />
              }
              filter={filter}
              filters={
                <ListFilter
                  isSearchClear={isSearchClear}
                  setIsSearchClear={setIsSearchClear}
                />
              }
            >
              <Datagrid
                body={
                  <DatagridBody
                    row={
                      <FilePickerDatagridRow
                        loading={loading}
                        onClickList={onClickList}
                        onClickCheckbox={onClickCheckbox}
                      />
                    }
                  />
                }
              >
                <IconField
                  resource={resource}
                  source="nodeType"
                  label=""
                  sortable={false}
                  onTooltip={({ res, translate: translateTooltip }) => {
                    return translateTooltip(res);
                  }}
                />
                <TextField resource={resource} source="name" />
                <TextField
                  resource={resource}
                  sortable={false}
                  source="createdBy.user.profile.name"
                  label="resources.scGroupwares.fields.createdBy.user.profile.name"
                />
                <DateField resource={resource} source="updatedAt" showTime />
                <TextField
                  resource={resource}
                  sortable={false}
                  source="updatedBy.user.profile.name"
                  label="resources.scGroupwares.fields.updatedby.user.profile.name"
                />
                {currentDirectory === 'bucket' ? (
                  <TextField
                    resource={resource}
                    sortable={false}
                    source="files.totalSize"
                    label="resources.scGroupwares.fields.files.totalSize"
                  />
                ) : (
                  <TextField
                    resource={resource}
                    source="file.size"
                    label="resources.scGroupwares.fields.file.size"
                  />
                )}
              </Datagrid>
            </CustomList>
          </MuiDialogContent>
          <Typography className={classes.footer}>
            {`${translate('admin.dialog.filePicker.selectedFile')}: `}
            {selectedRecords.map(
              (selectedRecord: SCGroupwareData, index: number) => {
                if (index + 1 !== selectedRecords.length) {
                  return `${selectedRecord.name}, `;
                }
                return selectedRecord.name;
              },
            )}
          </Typography>
        </>
      ) : (
        false
      )}
    </Dialog>
  );
};

FilePickerDialog.displayName = 'FilePickerDialog';
export default FilePickerDialog;
