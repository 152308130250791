import { ReactComponent as AddIcon } from './ico_common_add.svg';
import { ReactComponent as ReturnIcon } from './ico_common_return.svg';
import { ReactComponent as SaveIcon } from './ico_common_save.svg';
import { ReactComponent as DeleteIcon } from './ico_common_delete.svg';
import { ReactComponent as OkIcon } from './ico_common_ok.svg';
import { ReactComponent as ShareIcon } from './ico_share.svg';
import { ReactComponent as BucketIcon } from './bucket.svg';
import { ReactComponent as ErrorIcon } from './ico_error.svg';
import { ReactComponent as NormalIcon } from './ico_normal.svg';
import { ReactComponent as RockIcon } from './ico_rock.svg';
import { ReactComponent as MachineIcon } from './machine.svg';
import { ReactComponent as SiteIcon } from './site.svg';
import { ReactComponent as GnssSettingIcon } from './ico_orrection.svg';
import { ReactComponent as RetrofitManageIcon } from './ico_retrofit registration.svg';
import { ReactComponent as RetrofitAlternateRegistIcon } from './ico_retrofit alternateregist.svg';
import { ReactComponent as LicenceIcon } from './ico_license.svg';
import { ReactComponent as CompletedIcon } from './ico_completed.svg';
import { ReactComponent as ConvertingIcon } from './status_converting.svg';
import { ReactComponent as WorkingIcon } from './status_working.svg';
import { ReactComponent as SiteCompletedIcon } from './site_status_completed.svg';
import { ReactComponent as SiteWaitingIcon } from './site_status_waiting.svg';
import { ReactComponent as SiteWorkingIcon } from './site_status_working.svg';
import { ReactComponent as InUseIcon } from './status_inuse.svg';
import { ReactComponent as UnUseIcon } from './status_unuse.svg';
import { ReactComponent as OnlineIcon } from './status_online.svg';
import { ReactComponent as OfflineIcon } from './status_offline.svg';
import { ReactComponent as ProcessingIcon } from './status_processing.svg';
import { ReactComponent as IconWarning } from './ico_warning.svg';
import { ReactComponent as MachineGuidanceIcon } from './ico_machine_guidance.svg';
import { ReactComponent as MachineGuidanceActivateIcon } from './ico_machine_guidance_activate.svg';
import { ReactComponent as PayloadMeterIcon } from './ico_payload_meter.svg';
import { ReactComponent as PayloadMeterActivateIcon } from './ico_payload_meter_activate.svg';
import { ReactComponent as AsbuiltIcon } from './ico_asbuilt.svg';
import { ReactComponent as AsbuiltActivateIcon } from './ico_asbuilt_activate.svg';
import { ReactComponent as LicenseAllIcon } from './ico_license_all.svg';
import { ReactComponent as LicenseAllActivateIcon } from './ico_license_all_activate.svg';
import { ReactComponent as SwitchCardIcon } from './switchcard.svg';
import { ReactComponent as SwitchListIcon } from './switchlist.svg';
import { ReactComponent as SlopeIcon } from './buckettype_slope.svg';
import { ReactComponent as StandardIcon } from './buckettype_standard.svg';
import { ReactComponent as TiltIcon } from './buckettype_tilt.svg';
import { ReactComponent as TiltrotatorIcon } from './buckettype_tiltrotator.svg';
import { ReactComponent as ExtensionarmIcon } from './extensionarmtype_basic.svg';
import { ReactComponent as ExtensionarmTypeA } from './extensionarmtype_a.svg';
import { ReactComponent as ExtensionarmTypeB } from './extensionarmtype_b.svg';
import { ReactComponent as ExcavationIcon } from './machinetype_excavation.svg';
import { ReactComponent as RoadrollerIcon } from './machinetype_roadroller.svg';
import { ReactComponent as DozerIcon } from './machinetype_dozer.svg';
import { ReactComponent as RigiddumpIcon } from './machinetype_rigiddump.svg';
import { ReactComponent as WheelloaderIcon } from './machinetype_wheelloader.svg';
import { ReactComponent as GraderIcon } from './machinetype_grader.svg';
import { ReactComponent as AutomobileIcon } from './machinetype_automobile.svg';
import { ReactComponent as ExcavationLicenseIcon } from './machinetype_excavation_license.svg';
import { ReactComponent as ExcavationLicenseActivateIcon } from './machinetype_excavation_license_activate.svg';
import { ReactComponent as RoadrollerLicenseIcon } from './machinetype_roadroller_license.svg';
import { ReactComponent as RoadrollerLicenseActivateIcon } from './machinetype_roadroller_license_activate.svg';
import { ReactComponent as DozerLicenseIcon } from './machinetype_dozer_license.svg';
import { ReactComponent as DozerLicenseActivateIcon } from './machinetype_dozer_license_activate.svg';
import { ReactComponent as RigiddumpLicenseIcon } from './machinetype_rigiddump_license.svg';
import { ReactComponent as RigiddumpLicenseActivateIcon } from './machinetype_rigiddump_license_activate.svg';
import { ReactComponent as WheelloaderLicenseIcon } from './machinetype_wheelloader_license.svg';
import { ReactComponent as WheelloaderLicenseActivateIcon } from './machinetype_wheelloader_license_activate.svg';
import { ReactComponent as GraderLicenseIcon } from './machinetype_grader_license.svg';
import { ReactComponent as GraderLicenseActivateIcon } from './machinetype_grader_license_activate.svg';
import { ReactComponent as AutomobileLicenseIcon } from './machinetype_automobile_license.svg';
import { ReactComponent as AutomobileLicenseActivateIcon } from './machinetype_automobile_license_activate.svg';
import { ReactComponent as SiteCompactionMasterIcon } from './ico_site_compaction_master.svg';
import { ReactComponent as SiteConfigureIcon } from './ico_site_configure.svg';
import { ReactComponent as AssignmentIcon } from './ico_assignment.svg';
import { ReactComponent as AssignmentApprovedIcon } from './assignment_approved.svg';
import { ReactComponent as AssignmentUnapprovedIcon } from './assignment_unapproved.svg';
import { ReactComponent as TransferIcon } from './ico_transfer.svg';
import { ReactComponent as TransferEditIcon } from './ico_transfer_edit.svg';
import { ReactComponent as TransferApprovedIcon } from './transfer_approved.svg';
import { ReactComponent as TransferUnapprovedIcon } from './transfer_unapproved.svg';
import { ReactComponent as RemoteConnectionIcon } from './ico_remote_connection.svg';
import { ReactComponent as AdminIcon } from './admin.svg';
import { ReactComponent as TabletIcon } from './tablet.svg';
import { ReactComponent as RetrofitIcon } from './ico_retrofit.svg';
import { ReactComponent as PdfFileIcon } from './ico_pdffile.svg';
import { ReactComponent as ArrayInputDeleteIcon } from './ico_arrayinput_delete.svg';
import { ReactComponent as RetrofitSsidImage } from './retrofit_image_ssid.svg';
import { ReactComponent as ArrowIcon } from './ico_arrow.svg';
import { ReactComponent as DownloadIcon } from './ico_download.svg';
import { ReactComponent as DisabledIcon } from './ico_disabled.svg';
import { ReactComponent as BasicSettingIcon } from './ico_basicsetting.svg';
import { ReactComponent as CuttingEdgeIcon } from './ico_cutting_edge.svg';
import { ReactComponent as CalibrationIcon } from './ico_calibration.svg';
import { ReactComponent as ErrorRetrofitIcon } from './ico_error_retrofit.svg';
import { ReactComponent as TopographicSurveyIcon } from './ico_topographic_survey.svg';
import { ReactComponent as ConfigurationIcon } from './ico_configuration.svg';
import { ReactComponent as TabletConfigurationIcon } from './ico_tablet_configuration.svg';
import { ReactComponent as TaskConvertingIcon } from './ico_task_converting.svg';
import { ReactComponent as TaskFailtoConvertIcon } from './ico_task_failtoconvert.svg';
import { ReactComponent as TaskFailtoTransferIcon } from './ico_task_failedtotransfer.svg';
import { ReactComponent as TaskTransferringIcon } from './ico_task_transferring.svg';
import { ReactComponent as TaskTransferredIcon } from './ico_task_transferred.svg';
import { ReactComponent as GnssFailtoConvertIcon } from './ico_gnss_failtoconvert.svg';
import { ReactComponent as GnssTransferringIcon } from './ico_gnss_transferring.svg';
import { ReactComponent as GnssTransferredIcon } from './ico_gnss_transferred.svg';
import { ReactComponent as GnssAdmin } from './ico_admin.svg';
import { ReactComponent as GnssOwner } from './ico_owner.svg';
import { ReactComponent as CompactionWorkAreasIcon } from './ico_compaction_work_areas.svg';
import { ReactComponent as TasksIcon } from './ico_tasks.svg';
import { ReactComponent as MapCoordinate } from './ico_coordinate.svg';
import { ReactComponent as MapCursor } from './ico_cursor.svg';
import { ReactComponent as MapDelete } from './ico_delete.svg';
import { ReactComponent as MapLineOff } from './ico_lineoff.svg';
import { ReactComponent as MapLineOn } from './ico_lineon.svg';
import { ReactComponent as MapMinus } from './ico_minus.svg';
import { ReactComponent as MapPen } from './ico_pen.svg';
import { ReactComponent as MapPlus } from './ico_plus.svg';
import { ReactComponent as MapHome } from './ico_home.svg';
import { ReactComponent as MapDialogDelete } from './ico_dialog_delete.svg';
import { ReactComponent as ExclamationMark } from './exclamation_mark.svg';
import { ReactComponent as Emergency } from './emergency.svg';
import { ReactComponent as Important } from './important.svg';
import { ReactComponent as File } from './ico_file.svg';
import { ReactComponent as ArrowUp } from './ico_arrow-up.svg';
import { ReactComponent as ArrowDown } from './ico_arrow-down.svg';
import { ReactComponent as MailClose } from './ico_mail-close.svg';
import { ReactComponent as Download } from './ico_mail-download.svg';
import { ReactComponent as MailOpen } from './ico_mail-open.svg';
import { ReactComponent as EmergencySmall } from './emergency_small.svg';
import { ReactComponent as ImportantSmall } from './important_small.svg';
import { ReactComponent as UnLicenseIcon } from './ico_notgranted.svg';
import { ReactComponent as InvalidLicenseIcon } from './ico_granted_invalid.svg';
import { ReactComponent as ValidLicenseIcon } from './ico_granted.svg';
import { ReactComponent as Dimension2dIcon } from './ico_2d.svg';
import { ReactComponent as Dimension3dIcon } from './ico_3d.svg';
import { ReactComponent as GeoCircleIcon } from './ico_geo_circle.svg';
import { ReactComponent as GeoLineIcon } from './ico_geo_line.svg';
import { ReactComponent as GeoPointIcon } from './ico_geo_point.svg';
import { ReactComponent as GeoPolygonIcon } from './ico_geo_polygon.svg';
import { ReactComponent as GeoWallIcon } from './ico_geo_wall.svg';
import { ReactComponent as AlertAttentionIcon } from './ico_alert_attention.svg';
import { ReactComponent as AlertCautionIcon } from './ico_alert_caution.svg';
import { ReactComponent as AlertNoticeIcon } from './ico_alert_notice.svg';
import { ReactComponent as AlertWarningIcon } from './ico_alert_warning.svg';
import { ReactComponent as AlertDangerIcon } from './ico_alert_danger.svg';
import { ReactComponent as MapGeoCircle } from './ico_geo_button_circle.svg';
import { ReactComponent as MapGeoLine } from './ico_geo_button_line.svg';
import { ReactComponent as MapGeoPoint } from './ico_geo_button_point.svg';
import { ReactComponent as MapGeoPolygon } from './ico_geo_button_polygon.svg';
import { ReactComponent as MapGeoWall } from './ico_geo_button_wall.svg';
import { ReactComponent as MapGeoAttention } from './ico_geo_button_alert_attention.svg';
import { ReactComponent as MapGeoCaution } from './ico_geo_button_alert_caution.svg';
import { ReactComponent as MapGeoDanger } from './ico_geo_button_alert_danger.svg';
import { ReactComponent as MapGeoNotice } from './ico_geo_button_alert_notice.svg';
import { ReactComponent as MapGeoWarning } from './ico_geo_button_alert_warning.svg';
import { ReactComponent as MapGeoColor } from './ico_geo_button_color.svg';
import { ReactComponent as MapGeo2d } from './ico_geo_button_2d_btn.svg';
import { ReactComponent as MapGeo3d } from './ico_geo_button_3d_btn.svg';
import { ReactComponent as MapGeoTransparentOff } from './ico_geo_button_transparent_off.svg';
import { ReactComponent as MapGeoTransparentOn } from './ico_geo_button_transparent_on.svg';
import { ReactComponent as MapGeoInvalid } from './ico_geo_button_invalid.svg';
import { ReactComponent as MapGeoValid } from './ico_geo_button_valid.svg';
import { ReactComponent as GeofenceAlertListIcon } from './ico_geofence_alert_list.svg';
import { ReactComponent as SiteBuriedStructureIdSettingIcon } from './ico_site_buried_structure_id_setting.svg';
import { ReactComponent as FileStorageBucketIcon } from './ico_filestorage_bucket.svg';
import { ReactComponent as FileStorageFolderIcon } from './ico_filestorage_folder.svg';
import { ReactComponent as FileStorageOpenedFolderIcon } from './ico_filestorage_openedfolder.svg';
import { ReactComponent as FileStorageFileIcon } from './ico_filestorage_file.svg';

AddIcon.displayName = 'AddIcon';
ReturnIcon.displayName = 'ReturnIcon';
SaveIcon.displayName = 'SaveIcon';
DeleteIcon.displayName = 'DeleteIcon';
OkIcon.displayName = 'OkIcon';
ShareIcon.displayName = 'ShareIcon';
BucketIcon.displayName = 'BucketIcon';
ErrorIcon.displayName = 'ErrorIcon';
NormalIcon.displayName = 'NormalIcon';
RockIcon.displayName = 'RockIcon';
MachineIcon.displayName = 'MachineIcon';
SiteIcon.displayName = 'SiteIcon';
GnssSettingIcon.displayName = 'GnssSettingIcon';
RetrofitManageIcon.displayName = 'RetrofitManageIcon';
RetrofitAlternateRegistIcon.displayName = 'RetrofitAlternateRegistIcon';
LicenceIcon.displayName = 'LicenceIcon';
CompletedIcon.displayName = 'CompletedIcon';
ConvertingIcon.displayName = 'ConvertingIcon';
WorkingIcon.displayName = 'WorkingIcon';
SiteCompletedIcon.displayName = 'SiteCompletedIcon';
SiteWaitingIcon.displayName = 'SiteWaitingIcon';
SiteWorkingIcon.displayName = 'SiteWorkingIcon';
IconWarning.displayName = 'IconWarning';
MachineGuidanceIcon.displayName = 'MachineGuidanceIcon';
MachineGuidanceActivateIcon.displayName = 'MachineGuidanceActivateIcon';
PayloadMeterIcon.displayName = 'PayloadMeterIcon';
PayloadMeterActivateIcon.displayName = 'PayloadMeterActivateIcon';
AsbuiltIcon.displayName = 'AsbuiltIcon';
AsbuiltActivateIcon.displayName = 'AsbuiltActivateIcon';
LicenseAllIcon.displayName = 'LicenseAllIcon';
LicenseAllActivateIcon.displayName = 'LicenseAllActivateIcon';
SwitchCardIcon.displayName = 'SwitchCardIcon';
SwitchListIcon.displayName = 'SwitchListIcon';
SlopeIcon.displayName = 'SlopeIcon';
StandardIcon.displayName = 'StandardIcon';
TiltIcon.displayName = 'TiltIcon';
TiltrotatorIcon.displayName = 'TiltrotatorIcon';
ExtensionarmIcon.displayName = 'ExtensionarmIcon';
ExtensionarmTypeA.displayName = 'ExtensionarmTypeA';
ExtensionarmTypeB.displayName = 'ExtensionarmTypeB';
ExcavationIcon.displayName = 'ExcavationIcon';
RoadrollerIcon.displayName = 'RoadrollerIcon';
DozerIcon.displayName = 'DozerIcon';
RigiddumpIcon.displayName = 'RigiddumpIcon';
WheelloaderIcon.displayName = 'WheelloaderIcon';
GraderIcon.displayName = 'GraderIcon';
AutomobileIcon.displayName = 'AutomobileIcon';
ExcavationLicenseIcon.displayName = 'ExcavationLicenseIcon';
ExcavationLicenseActivateIcon.displayName = 'ExcavationLicenseActivateIcon';
RoadrollerLicenseIcon.displayName = 'RoadrollerLicenseIcon';
RoadrollerLicenseActivateIcon.displayName = 'RoadrollerLicenseActivateIcon';
DozerLicenseIcon.displayName = 'DozerLicenseIcon';
DozerLicenseActivateIcon.displayName = 'DozerLicenseActivateIcon';
RigiddumpLicenseIcon.displayName = 'RigiddumpLicenseIcon';
RigiddumpLicenseActivateIcon.displayName = 'RigiddumpLicenseActivateIcon';
WheelloaderLicenseIcon.displayName = 'WheelloaderLicenseIcon';
WheelloaderLicenseActivateIcon.displayName = 'WheelloaderLicenseActivateIcon';
GraderLicenseIcon.displayName = 'GraderLicenseIcon';
GraderLicenseActivateIcon.displayName = 'GraderLicenseActivateIcon';
AutomobileLicenseIcon.displayName = 'AutomobileLicenseIcon';
AutomobileLicenseActivateIcon.displayName = 'AutomobileLicenseActivateIcon';
SiteCompactionMasterIcon.displayName = 'SiteCompactionMasterIcon';
SiteConfigureIcon.displayName = 'SiteConfigureIcon';
AssignmentIcon.displayName = 'AssignmentIcon';
AssignmentApprovedIcon.displayName = 'AssignmentApprovedIcon';
AssignmentUnapprovedIcon.displayName = 'AssignmentUnapprovedIcon';
TransferIcon.displayName = 'TransferIcon';
TransferEditIcon.displayName = 'TransferEditIcon';
TransferApprovedIcon.displayName = 'TransferApprovedIcon';
TransferUnapprovedIcon.displayName = 'TransferUnapprovedIcon';
RemoteConnectionIcon.displayName = 'RemoteConnectionIcon';
AdminIcon.displayName = 'AdminIcon';
TabletIcon.displayName = 'TabletIcon';
RetrofitIcon.displayName = 'RetrofitIcon';
PdfFileIcon.displayName = 'PdfFileIcon';
ArrayInputDeleteIcon.displayName = 'ArrayInputDelete';
RetrofitSsidImage.displayName = 'RetrofitSsidImage';
ArrowIcon.displayName = 'ArrowIcon';
DownloadIcon.displayName = 'DownloadIcon';
DisabledIcon.displayName = 'DisabledIcon';
BasicSettingIcon.displayName = 'BasicSettingIcon';
CuttingEdgeIcon.displayName = 'CuttingEdgeIcon';
CalibrationIcon.displayName = 'CalibrationIcon';
ErrorRetrofitIcon.displayName = 'ErrorRetrofitIcon';
TopographicSurveyIcon.displayName = 'TopographicSurveyIcon';
ConfigurationIcon.displayName = 'ConfigurationIcon';
TabletConfigurationIcon.displayName = 'TabletConfigurationIcon';
TaskConvertingIcon.displayName = 'TaskConvertingIcon';
TaskFailtoConvertIcon.displayName = 'TaskFailtoConvertIcon';
TaskFailtoTransferIcon.displayName = 'TaskFailtoTransferIcon';
TaskTransferringIcon.displayName = 'TaskTransferringIcon';
TaskTransferredIcon.displayName = 'TaskTransferredIcon';
GnssFailtoConvertIcon.displayName = 'GnssFailtoConvertIcon';
GnssTransferringIcon.displayName = 'GnssTransferringIcon';
GnssTransferredIcon.displayName = 'GnssTransferredIcon';
GnssAdmin.displayName = 'GnssAdmin';
GnssOwner.displayName = 'GnssOwner';
CompactionWorkAreasIcon.displayName = 'CompactionWorkAreasIcon';
TasksIcon.displayName = 'TasksIcon';
MapCoordinate.displayName = 'MapCoordinate';
MapCursor.displayName = 'MapCursor';
MapDelete.displayName = 'MapDelete';
MapLineOff.displayName = 'MapLineOff';
MapLineOn.displayName = 'MapLineOn';
MapMinus.displayName = 'MapMinus';
MapPen.displayName = 'MapPen';
MapPlus.displayName = 'MapPlus';
MapHome.displayName = 'MapHome';
MapDialogDelete.displayName = 'MapDialogDelete';
Emergency.displayName = 'Emergency';
Important.displayName = 'Important';
ExclamationMark.displayName = 'ExclamationMark';
EmergencySmall.displayName = 'EmergencySmall';
ImportantSmall.displayName = 'ImportantSmall';
File.displayName = 'File';
ArrowUp.displayName = 'ArrowUp';
ArrowDown.displayName = 'ArrowDown';
MailClose.displayName = 'MailClose';
MailOpen.displayName = 'MailOpen';
Download.displayName = 'Download';
InUseIcon.displayName = 'InUseIcon';
UnUseIcon.displayName = 'UnUseIcon';
OnlineIcon.displayName = 'OnlineIcon';
OfflineIcon.displayName = 'OfflineIcon';
ProcessingIcon.displayName = 'ProcessingIcon';
UnLicenseIcon.displayName = 'UnLicenseIcon';
InvalidLicenseIcon.displayName = 'InvalidLicenseIcon';
ValidLicenseIcon.displayName = 'ValidLicenseIcon';
Dimension2dIcon.displayName = 'Dimension2dIcon';
Dimension3dIcon.displayName = 'Dimension3dIcon';
GeoCircleIcon.displayName = 'GeoCircleIcon';
GeoLineIcon.displayName = 'GeoLineIcon';
GeoPointIcon.displayName = 'GeoPointIcon';
GeoPolygonIcon.displayName = 'GeoPolygonIcon';
GeoWallIcon.displayName = 'GeoWallIcon';
AlertAttentionIcon.displayName = 'AlertAttentionIcon';
AlertCautionIcon.displayName = 'AlertCautionIcon';
AlertNoticeIcon.displayName = 'AlertNoticeIcon';
AlertWarningIcon.displayName = 'AlertWarningIcon';
AlertDangerIcon.displayName = 'AlertDangerIcon';
MapGeoCircle.displayName = 'MapGeoCircle';
MapGeoLine.displayName = 'MapGeoLine';
MapGeoPoint.displayName = 'MapGeoPoint';
MapGeoPolygon.displayName = 'MapGeoPolygon';
MapGeoWall.displayName = 'MapGeoWall';
MapGeoAttention.displayName = 'MapGeoAttention';
MapGeoCaution.displayName = 'MapGeoCaution';
MapGeoDanger.displayName = 'MapGeoDanger';
MapGeoNotice.displayName = 'MapGeoNotice';
MapGeoWarning.displayName = 'MapGeoWarning';
MapGeoColor.displayName = 'MapGeoColor';
MapGeo2d.displayName = 'MapGeo2d';
MapGeo3d.displayName = 'MapGeo3d';
MapGeoTransparentOff.displayName = 'MapGeoTransparentOff';
MapGeoTransparentOn.displayName = 'MapGeoTransparentOn';
MapGeoInvalid.displayName = 'MapGeoInvalid';
MapGeoValid.displayName = 'MapGeoValid';
GeofenceAlertListIcon.displayName = 'GeofenceAlertListIcon';
SiteBuriedStructureIdSettingIcon.displayName =
  'SiteBuriedStructureIdSettingIcon';
FileStorageBucketIcon.displayName = 'FileStorageBucketIcon';
FileStorageFolderIcon.displayName = 'FileStorageFolderIcon';
FileStorageOpenedFolderIcon.displayName = 'FileStorageOpenedFolderIcon';
FileStorageFileIcon.displayName = 'FileStorageFileIcon';

export {
  AddIcon,
  ReturnIcon,
  SaveIcon,
  DeleteIcon,
  OkIcon,
  ShareIcon,
  ErrorIcon,
  NormalIcon,
  RockIcon,
  SiteIcon,
  GnssSettingIcon,
  RetrofitManageIcon,
  RetrofitAlternateRegistIcon,
  LicenceIcon,
  MachineIcon,
  BucketIcon,
  CompletedIcon,
  ConvertingIcon,
  WorkingIcon,
  SiteCompletedIcon,
  SiteWaitingIcon,
  SiteWorkingIcon,
  IconWarning,
  MachineGuidanceIcon,
  MachineGuidanceActivateIcon,
  PayloadMeterIcon,
  PayloadMeterActivateIcon,
  AsbuiltIcon,
  AsbuiltActivateIcon,
  LicenseAllIcon,
  LicenseAllActivateIcon,
  SwitchCardIcon,
  SwitchListIcon,
  SlopeIcon,
  StandardIcon,
  TiltIcon,
  TiltrotatorIcon,
  ExtensionarmIcon,
  ExtensionarmTypeA,
  ExtensionarmTypeB,
  ExcavationIcon,
  RoadrollerIcon,
  DozerIcon,
  RigiddumpIcon,
  WheelloaderIcon,
  GraderIcon,
  AutomobileIcon,
  ExcavationLicenseIcon,
  ExcavationLicenseActivateIcon,
  RoadrollerLicenseIcon,
  RoadrollerLicenseActivateIcon,
  DozerLicenseIcon,
  DozerLicenseActivateIcon,
  RigiddumpLicenseIcon,
  RigiddumpLicenseActivateIcon,
  WheelloaderLicenseIcon,
  WheelloaderLicenseActivateIcon,
  GraderLicenseIcon,
  GraderLicenseActivateIcon,
  AutomobileLicenseIcon,
  AutomobileLicenseActivateIcon,
  SiteCompactionMasterIcon,
  SiteConfigureIcon,
  AssignmentIcon,
  AssignmentApprovedIcon,
  AssignmentUnapprovedIcon,
  TransferIcon,
  TransferEditIcon,
  TransferApprovedIcon,
  TransferUnapprovedIcon,
  RemoteConnectionIcon,
  AdminIcon,
  TabletIcon,
  RetrofitIcon,
  PdfFileIcon,
  ArrayInputDeleteIcon,
  RetrofitSsidImage,
  ArrowIcon,
  DownloadIcon,
  DisabledIcon,
  BasicSettingIcon,
  CuttingEdgeIcon,
  CalibrationIcon,
  ErrorRetrofitIcon,
  TopographicSurveyIcon,
  ConfigurationIcon,
  TabletConfigurationIcon,
  TaskConvertingIcon,
  TaskFailtoConvertIcon,
  TaskFailtoTransferIcon,
  TaskTransferringIcon,
  TaskTransferredIcon,
  GnssFailtoConvertIcon,
  GnssTransferringIcon,
  GnssTransferredIcon,
  GnssAdmin,
  GnssOwner,
  CompactionWorkAreasIcon,
  TasksIcon,
  InUseIcon,
  UnUseIcon,
  MapCoordinate,
  MapCursor,
  MapDelete,
  MapLineOff,
  MapLineOn,
  MapMinus,
  MapPen,
  MapPlus,
  MapHome,
  MapDialogDelete,
  ExclamationMark,
  Emergency,
  Important,
  EmergencySmall,
  ImportantSmall,
  File,
  ArrowUp,
  ArrowDown,
  MailClose,
  MailOpen,
  Download,
  OnlineIcon,
  OfflineIcon,
  ProcessingIcon,
  UnLicenseIcon,
  InvalidLicenseIcon,
  ValidLicenseIcon,
  Dimension2dIcon,
  Dimension3dIcon,
  GeoCircleIcon,
  GeoLineIcon,
  GeoPointIcon,
  GeoPolygonIcon,
  GeoWallIcon,
  AlertAttentionIcon,
  AlertCautionIcon,
  AlertNoticeIcon,
  AlertWarningIcon,
  AlertDangerIcon,
  MapGeoCircle,
  MapGeoLine,
  MapGeoPoint,
  MapGeoPolygon,
  MapGeoWall,
  MapGeoAttention,
  MapGeoCaution,
  MapGeoDanger,
  MapGeoNotice,
  MapGeoWarning,
  MapGeoColor,
  MapGeo2d,
  MapGeo3d,
  MapGeoTransparentOff,
  MapGeoTransparentOn,
  MapGeoInvalid,
  MapGeoValid,
  GeofenceAlertListIcon,
  SiteBuriedStructureIdSettingIcon,
  FileStorageBucketIcon,
  FileStorageOpenedFolderIcon,
  FileStorageFolderIcon,
  FileStorageFileIcon,
};
