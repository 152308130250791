import { SCGroupwareBucketData } from '../../types';

const scGroupwareBuckets: SCGroupwareBucketData[] = [
  {
    id: 'i7fhvgbr87p30l2sg4hs',
    name: 'SC_APP',
    resourceOwnerId: '44268432-8882-429e-bea5-f5c519087267',
    createdAt: '2024-10-09T16:00:00Z',
    updatedAt: '2024-10-09T16:18:00Z',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
        displayName: 'SC-DashboardSTG',
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: 'dd1742e8-0ca7-428d-9d59-9322851bb945',
        isDevice: false,
        displayName: 'SC-DashboardSTG',
      },
    },
    files: {
      totalCount: '13',
      totalSize: '42490343',
    },
  },
  {
    id: 'fpnvgr4u2uk8738tsfsc',
    name: 'ファイル管理',
    resourceOwnerId: '44268432-8882-429e-bea5-f5c519087267',
    createdAt: '2024-10-09T16:01:00Z',
    updatedAt: '2024-10-09T16:39:00Z',
    createdBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
        displayName: 'Smart Construction Groupware',
      },
    },
    updatedBy: {
      user: {
        id: '208a32fb-e97f-4cd9-bc15-fdd6ffc2da1d',
        profile: {
          name: 'EN Mai Nhan',
        },
      },
      client: {
        clientId: '9376c23f-0753-42e8-95e1-342cd8ed6239',
        isDevice: false,
        displayName: 'Smart Construction Groupware',
      },
    },
    files: {
      totalCount: '2',
      totalSize: '37990383',
    },
  },
];

export default scGroupwareBuckets;
